import { useAuth0 } from "contexts/auth0-context";
import users from "ducks/users";
import { usersApi } from "ducks/usersApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useCurrentUser() {
  const { organization } = useParams();
  const currentUser = usersApi.useGetCurrentUserQuery(organization);
  return currentUser;
}

export function useDebug() {
  const currentUser = useCurrentUser();
  return currentUser?.data?.auth0User?.app_metadata?.debug === true
    ? true
    : false;
}
