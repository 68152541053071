import React from "react";
import { Button, InlineLoading } from "@un/react";

import styles from "./styles.module.scss";
import { Trans } from "react-i18next";
import classnames from "classnames";

export default function CrudButton({ loading, status, wrapper, ...other }) {
  const wrapperClasses = classnames(styles.crudButton, {
    [`${styles.wrapper}`]: wrapper,
  });

  const buttonProps = {
    children: other.children,
    kind: other.kind,
    large: other.large,
    type: "submit",
  };
  if (wrapper === "inline") {
    return (
      <Button
        {...buttonProps}
        children={loading ? <Trans>Saving...</Trans> : other.children}
      />
    );
  }

  return (
    <div className={styles.wrapperClasses}>
      <Button {...buttonProps} />{" "}
      {(loading || status) && (
        <InlineLoading
          description={
            loading ? <Trans>Saving...</Trans> : <Trans>Saved</Trans>
          }
          success={status}
        />
      )}
    </div>
  );
}
