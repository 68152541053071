import React from "react";
import styles from "./settingsSidebar.module.scss";
import { SidebarHeader, InlineLoading } from "@un/react";

import ButtonRouter from "components/ButtonRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

import { Trans } from "react-i18next";

import { SidebarBackButtonWithLink } from "components/SidebarBackButton";

export function SettingsHeader({
  children,
  actions,
  search,
  components,
  isDesktopOrLaptop,
  customButtons,
  singleQuery,
  settingsOverview,
  organizationId,
  backLink,
  BackLink,
  duckName,
  name,
  namePluralUpperLetter,
  allQuery,
  loading,
}) {
  return (
    <SidebarHeader noPadding>
      <div className={styles.actions}>
        <h3>
          {components.BackLink ? (
            <components.BackLink
              SidebarBackButtonWithLink={SidebarBackButtonWithLink}
              backLink={backLink}
            />
          ) : (
            backLink && <SidebarBackButtonWithLink to={backLink} />
          )}
          <Trans>{namePluralUpperLetter || name}</Trans>
        </h3>
        {allQuery?.isLoading && <InlineLoading />}

        <components.SidebarHeaderButton
          customButtons={customButtons}
          organizationId={organizationId}
          duckName={duckName}
          name={name}
        />
      </div>
      <components.SidebarSearch className={styles.search} search={search} />
    </SidebarHeader>
  );
}
