import React, { useEffect, useState } from "react";
import Empty from "../Empty";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styles from "./logout.module.scss";
import { useDispatch } from "react-redux";
//import { logout } from "data-handler/ducks/auth";
//import seedOpening from "images/seed-opening.svg";
//import seedClosed from "images/seed-closed.svg";
import auth from "ducks/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/pro-light-svg-icons";
import { useAuth0 } from "contexts/auth0-context";

export default function Logout() {
  const [message, setMessage] = useState();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const history = useHistory();
  /*setTimeout(() => {
    setMessage(true);
  }, 500);
  setTimeout(() => {
    history.push("");
  }, 1500);
  dispatch(auth.actions.logout());
*/

  useEffect(() => {
    logout({ returnTo: window.location.origin });
  }, []);

  return (
    <Empty
      className={styles.logout}
      title={message ? "Logout successfull" : "Logging out"}
      kind="large"
      icon={<FontAwesomeIcon icon={faSmile} />}
    >
      <NavLink to="login">Click here</NavLink> to login again.
    </Empty>
  );
}
