import React, { useEffect } from "react";
import { Button, Item } from "@un/react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import TrayOverview from "components/Calendar/TrayOverview";
import useQs from "helpers/useQs";
import { calendarFuturePoints, compareMedications } from "@wirewire/helpers";
import { format, fromUnixTime } from "date-fns";
import MedicationName, { MedicationNameNew } from "components/MedicationName";
import styles from "./fillDetail.module.scss";
import { de } from "date-fns/locale";
import {
  activeTrays,
  generateLedLight,
} from "@wirewire/helpers/build/calendars/activeTrays";
import { devicesApi } from "ducks/devices";
import { useActiveUserDevice } from "helpers/useUsers";
import useDayTimeList from "helpers/useDayTimeList";

export default function FillDetail({
  events,
  selectedPatientAsArray,
  resultsByMedication,
  setFillStatus,
  fillStatus,
}) {
  const params = useParams();

  const queryArguments = useQs();
  const from = new Date(queryArguments.from);
  const to = new Date(queryArguments.to);

  const currentMedicationEntry = selectedPatientAsArray
    ? selectedPatientAsArray.find((e) =>
        compareMedications(e, params.medication)
      )
    : undefined;

  const medicationsList = resultsByMedication.find(
    (e) => e.id === params.medication
  );
  const history = useHistory();
  const location = useLocation();

  const [updateLedLightByDeviceId] =
    devicesApi.useUpdateLedLightByDeviceIdMutation();

  const dayTimeList = useDayTimeList();

  //const calendarFutureEvents = useCurrentCalendarFutureEvents();
  //const events = calendarFutureEvents({ from, to, bake: false });

  const eventsWithCase = activeTrays({
    dayTimeList,
    events,
    from,
    to,
    medication: medicationsList,
  });

  const alarms = generateLedLight({ events: eventsWithCase });

  let alarmsValues = [];
  for (let i = 0; i < 29; i++) {
    const value = alarms.find((e) => e[0] === i);
    alarmsValues.push([i, value ? value[1] : 0]);
  }

  const activeUserDevice = useActiveUserDevice();

  useEffect(() => {
    updateLedLightByDeviceId({
      id: activeUserDevice.data?.[0].id,
      data: {
        led: alarmsValues,
        timeout: 20,
      },
    });
  }, [params.medication]);

  return (
    <SettingsContentWrapper
      hideHeaderRight
      overviewUrl={`/${params.organization}/${params.entry}/fill/fillTray/${location.search}`}
      title={
        params.medication ? (
          <MedicationName entry={medicationsList?.entries?.[0]} />
        ) : (
          <Trans>Overview</Trans>
        )
      }
    >
      <TrayOverview
        fill
        events={events}
        date={from}
        currentMedication={currentMedicationEntry}
      />
      {medicationsList && (
        <div>
          {medicationsList.entries.map((e) => (
            <Item
              kind="horizontal"
              subContent={
                <>
                  {e.amount} <Trans>{`${e.unit}-PLURAL`}</Trans>{" "}
                  {e.emptyStomach && <Trans>(empty stomach)</Trans>}
                  {/*<ReactJson src={e} />*/}
                </>
              }
              title={
                <>
                  {format(e.date, "EEEE, dd.MM.yyyy HH:mm", { locale: de })}
                  {/*format(e.date, "HH:mm", {
                    timeZone: "Europe/Berlin",
                  })*/}
                </>
              }
              wrapper="sidebar"
            />
          ))}
        </div>
      )}

      {params.medication && (
        <Button
          className={styles.submitButton}
          large
          onClick={() => {
            if (fillStatus.includes(params.medication)) {
              setFillStatus([
                ...fillStatus.filter((f) => f !== params.medication),
              ]);
            } else {
              setFillStatus([...fillStatus, params.medication]);
              history.push(
                `/${params.organization}/${params.entry}/fill/fillTray/${location.search}`
              );
            }
          }}
        >
          {fillStatus.includes(params.medication) ? (
            <Trans>Medication not filled</Trans>
          ) : (
            <Trans>Medication filled</Trans>
          )}
        </Button>
      )}
    </SettingsContentWrapper>
  );
}
