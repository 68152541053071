import React from "react";
import Empty from "components/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKiwiBird, faLayerPlus } from "@fortawesome/pro-light-svg-icons";
import ButtonRouter from "components/ButtonRouter";
import { Trans } from "react-i18next";

export default function PatientsEmpty() {
  return (
    <Empty
      icon={<FontAwesomeIcon icon={faLayerPlus} size="10x" />}
      kind="large"
      title={<Trans>Welcome</Trans>}
      button={
        <ButtonRouter to="/users/new/?selector=addPatient" withOrganization>
          <Trans>Create a patient</Trans>
        </ButtonRouter>
      }
    >
      <Trans>Setup a patient to continue</Trans>
    </Empty>
  );
}
