import React, { useEffect } from "react";

import { Button, Select, SelectItem, Story, Item } from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFieldArray } from "react-hook-form";

import { faPlus, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notificationsApi } from "ducks/notificationsApi";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { messagesApi } from "ducks/messagesApi";
import messageKinds from "./messageList";
import ReactJson from "react-json-view";

export default function SettingsNotificationsDetail() {
  const store = useSettingsForm({
    name: "messages",
    api: messagesApi,
    // url: `/${params.organization}/messages`,
  });

  const {
    entryData,
    form: { control, register, watch },
  } = store;

  const messageKind = messageKinds["battery"];

  const [updateStatus, updateStatusResult] =
    messagesApi.useUpdateStatusMutation();

  useEffect(() => {
    if (entryData?.id && entryData.status !== "read")
      updateStatus({ id: entryData.id, data: { status: "read" } });
  }, [entryData?.id]);

  return (
    <SettingsContentWrapper {...store}>
      <FontAwesomeIcon icon={messageKind.icon} />
      <ReactJson src={entryData} />

      {/*<TextInput labelText="Description" {...register("description")} />*/}

      <SettingsSubmitButton {...store} />
    </SettingsContentWrapper>
  );
}
