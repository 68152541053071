import { combineReducers } from "redux";
import { devicesApi } from "./devices";

import { calendarsApi } from "./calendars";

import globalState from "./globalState";
//import filter from "./filter.legacy";
import auth from "./auth";
import { accountsApi } from "./accounts";
import { emptySplitApi } from "./emptyApi";
import { organizationsApi } from "./organizationsApi";
import { medicationsApi } from "./medicationsApi";
import { stocksApi } from "./stocksApi";
import { storesApi } from "./storesApi";
import { abdasApi } from "./abdasApi";
import { iotDevicesApi } from "./iotDevicesApi";
import { notificationsApi } from "./notificationsApi";
import fills from "./fills";
import { messagesApi } from "./messagesApi";

const rootReducer = (history) =>
  combineReducers({
    auth: auth.reducer,
    /*medications: medications.reducer,
    calendars: calendars.reducer,
    stores: stores.reducer,
    stocks: stocks.reducer,*/
    globalState: globalState.reducer,
    /*notifications: notifications.reducer,
    users: users.reducer,*/
    //globalFormState: globalFormState.reducer,
    //devices: devices.reducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    /*organizations: organizations.reducer,*/
  });

export default rootReducer;

/*export function* rootSaga() {
  yield all([
    ...calendarsSagas,
    ...organizationsSagas,
    ...stocksSagas,
    ...notificationsSagas,
    ...medicationsSagas,
    ...devicesSagas,
    ...usersSagas,
    ...storesSagas,
  ]);
}
*/
