import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "@un/react";
import DatePicker, { registerLocale } from "react-datepicker";
import styles from "./dateRangePicker.module.scss";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { isValid } from "date-fns";
import classnames from "classnames";

export default function DateRangePicker({
  startDate,
  endDate,
  clearEndDate,
  fullWidth,
  onChangeStart,
  onChangeEnd,
  startDateProps,
  endDateProps,
}) {
  console.log("starrtDate", startDate, endDate);

  const wrapperClasses = classnames(styles.dateRangePicker, {
    [`${styles.fullWidthWrapper}`]: fullWidth,
  });

  const inputClasses = classnames(styles.dateRangePickerInput, "wfp--input", {
    [`${styles.fullWidth}`]: fullWidth,
  });

  //return null;
  if (!startDate || (isValid(startDate) && (!startDate || isValid(startDate))))
    return (
      <div className={wrapperClasses}>
        <DatePicker
          selected={startDate}
          className={inputClasses}
          onChange={onChangeStart}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          withPortal
          {...startDateProps}
        />
        <FontAwesomeIcon
          icon={faArrowRight}
          className={`${styles.dateRangePickerIcon}`}
        />
        <DatePicker
          selected={endDate}
          className={inputClasses}
          onChange={onChangeEnd}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          withPortal
          isClearable={endDate && clearEndDate}
          {...endDateProps}
        />
        {/*clearEndDate && (
          <Button onClick={() => onChangeEnd(undefined)}>clear</Button>
        )*/}
      </div>
    );
  return null;
}
