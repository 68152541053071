import React from "react";
import styles from "./styles.module.scss";
import { Trans } from "react-i18next";
import { useActiveUser, useActiveUserDevice } from "helpers/useUsers";
import { Button } from "@un/react";
import useIsDesktop from "helpers/useIsDesktop";
import NoDeviceAssigned from "components/SettingsDevices/NoDeviceAssigned";
import FillStartButton from "components/FillProcess/FillStart";
import Status from "components/Status";

export default function SidebarContent({ handlePrint }) {
  const isDesktop = useIsDesktop();
  const currentPatient = useActiveUser();
  const activeUserDevice = useActiveUserDevice();

  return (
    <>
      <div className={styles.meta}>
        <p className={`${styles.comment} ${styles.address}`}>
          {currentPatient?.meta?.street}, {currentPatient?.meta?.postal}{" "}
          {currentPatient?.meta?.city}
        </p>
        <p className={styles.comment}>
          {currentPatient?.meta?.retirementHomeAddress}
        </p>
        <p className={styles.comment}>
          {currentPatient?.meta?.incompatibilities}
        </p>
        <p className={styles.comment}>{currentPatient?.meta?.notes}</p>
        {/*}
        <Button kind="inverse" small onClick={handlePrint}>
          <Trans>Print</Trans>
          </Button>*/}
      </div>

      {/*<div className={styles.meta}>
        <div className={styles.metaValueWrapper}>
          <div className={styles.metaValue}>
            <Value
              value={<Unit type="percentage">71</Unit>}
              secondaryValue="+7%"
              title="Korrekte Einnahmen letzte Woche"
            />
          </div>
          <div className={styles.metaValue}>
            <Value
              value={<Unit type="percentage">75</Unit>}
              secondaryValue="+17%"
              title="Korrekte Einnahmen letzter Monat"
            />
          </div>
        </div>
        </div>*/}

      {/*<div className={styles.currentStatus}>
        Current status
        {currentPatientDevices &&
          currentPatientDevices.map((e) => <div>{e.deviceId}</div>)}
        </div>*/}
      <div className={styles.meta}>
        {isDesktop && <NoDeviceAssigned />}

        <Status
          query={activeUserDevice}
          validate={(query) => query.data && query.data.length > 0}
        >
          <FillStartButton
            customButton={
              <Button className={styles.fillButton}>
                <Trans>Start the filling aid</Trans>
              </Button>
            }
          />
        </Status>
      </div>
      {/*}
      <div className={styles.eventList}>
        {currentUserDevices &&
          currentUserDevices.latestEntries &&
          currentUserDevices.latestEntries.map((e) => (
            <Item
              className={styles.item}
              kind="horizontal"
              additional={moment(e.EventTimestamp).format("DD.MM.YYYY HH:mm")}
              title={eventList[e.EventType]?.name}
              wrapper="sidebar"
            >
              <EventListContent event={e} />
            </Item>
          ))}
          </div>*/}
    </>
  );
}
