import React from "react";

export default function UserMeta({ user }) {
  return (
    <>
      {user.meta?.street && <>{user.meta?.street},</>} {user.meta?.postal}{" "}
      {user.meta?.city}
    </>
  );
}
