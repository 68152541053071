import { useContext, useEffect, useState } from "react";
import { FCM } from "@capacitor-community/fcm";

import {
  PushNotifications,
  ActionPerformed,
} from "@capacitor/push-notifications";

import { Capacitor } from "@capacitor/core";
import { PushContext } from "./PushContext";
import { accountsApi } from "ducks/accounts";
import { useAuth0 } from "contexts/auth0-context";

export default function usePushNotifications() {
  const { user } = useAuth0();
  const nullEntry: any[] = [];
  const [notifications, setnotifications] = useState(nullEntry);
  const [token, setToken] = useState(null);
  const [fcmToken, setFcmToken] = useState(null);

  useEffect(() => {
    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === "denied") {
            //showToast("Push Notification permission denied");
          } else {
            //showToast("Push Notification permission granted");
            register();
          }
        });
      } else {
        register();
      }
    });
  }, []);

  const register = () => {
    console.log("Initializing HomePage");

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token) => {
      //showToast("Push registration success");
      console.log("Push registration success", token);
      setToken(token);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: ", JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.id,
            title: notification.title,
            body: notification.body,
            type: "foreground",
          },
        ]);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.notification.data.id,
            title: notification.notification.data.title,
            body: notification.notification.data.body,
            type: "action",
          },
        ]);
      }
    );
  };

  const [setDeviceToken, setDeviceTokenResult] =
    accountsApi.useSetDeviceTokenMutation();

  const getFcmToken = async () => {
    const fcm = await FCM.getToken();
    setFcmToken(fcm);
    setDeviceToken({ id: user.sub, token: fcm });
  };

  useEffect(() => {
    if (user?.sub) {
      getFcmToken();
    }
  }, [user]);

  const showToast = async (msg: string) => {
    alert(msg);
    /*await Toast.show({
      text: msg,
    });*/
  };
  return { notifications, getFcmToken, fcmToken, register, token };
}

export function usePushContext() {
  const context = useContext(PushContext);
  return context;
}
