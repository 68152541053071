import React, { useState } from "react";
import { Modal, Button } from "@un/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/pro-solid-svg-icons";
import styles from "./styles.module.scss";
import Scanner from "./";
import { Trans } from "react-i18next";
import classnames from "classnames";

export default function ScanButton({
  autoSubmit,
  buttonClassName,
  buttonText,
  customTrigger,
  setValue,
  large,
  onSubmit,
  ...other
}) {
  const prepareSubmit = () => {
    onSubmit(content);

    return true;
  };

  const updateValue = (e) => {
    if (autoSubmit && e) {
      setValue(e);

      toggleModal();
    }
    return true;
  };

  const [content, setContent] = useState();
  const [open, setOpen] = useState();
  const [openFinished, setOpenFinished] = useState();

  const toggleModal = () => {
    setOpen(!open);
  };

  const buttonClasses = classnames(styles.pictureButton, buttonClassName);

  return (
    <>
      <Button
        className={buttonClasses}
        large={large}
        icon={<FontAwesomeIcon icon={faQrcode} />}
        onClick={() => {
          toggleModal();
          setOpenFinished(true);
        }}
        {...other}
      >
        {buttonText}
      </Button>
      <Modal
        shouldCloseAfterSubmit
        lazyLoad={true}
        open={openFinished}
        handleSubmit={prepareSubmit}
        modalHeading={<Trans>Scan code</Trans>}
        passiveModal={!content}
        primaryButtonText={<Trans>Submit</Trans>}
        secondaryButtonText={<Trans>Cancel</Trans>}
        className={styles.module}
        onRequestSubmit={toggleModal}
        onSecondarySubmit={toggleModal}
        onRequestClose={toggleModal}
      >
        <Scanner
          onChange={updateValue}
          open={open}
          closeModal={() => setOpenFinished(false)}
        />
      </Modal>
    </>
  );
}
