import { createSelector } from "@reduxjs/toolkit";
import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const storesApi = generateCrudApi({
  name: "stores",
  endpoints: (builder) => ({
    getCalendarById: builder.query({
      query: (request) => ({
        url: `stores/updatetimes/${request.id}`,
        method: "post",
        body: request.data,
      }),
    }),
  }),
});

export const selectStoresByCaseId = (state, { patient, trayCaseNumber }) => {
  const selectStoresByUser = storesApi.endpoints.search.select({ patient });
  return createSelector(
    selectStoresByUser,
    (usersResult, trayCaseNumber) => {
      return { usersResult, trayCaseNumber };
    } /*{
      console.log("usersResultusersResultusersResultusersResult", usersResult);
      return usersResult.data
        ? usersResult.data.filter((s) => s.EventMessage?.off === trayCaseNumber)
        : [];
    }*/
  );
};
