import React, { useState } from "react";
import ReactJson from "react-json-view";

import { useForm } from "react-hook-form";

import { devicesApi } from "ducks/devices";
import styles from "./deviceStatus.module.scss";

export default function DeviceStatus({ id }) {
  const { data: singleDevice } = devicesApi.useGetSingleDevicesQuery(id);

  return (
    <>
      <h3>Status</h3>
      {singleDevice && <ReactJson src={singleDevice} collapsed={2} />}

      {singleDevice?.iotDevice?.simData?.dataVolumeTotal && (
        <>
          <br />
          <div className={styles.dataVolumeWrapper}>
            <div className={styles.dataVolume}>
              <div
                className={styles.dataVolumeUsed}
                style={{
                  width: `${
                    ((singleDevice.iotDevice.simData.dataVolumeTotal -
                      singleDevice.iotDevice.simData.dataVolumeLeft) /
                      singleDevice.iotDevice.simData.dataVolumeTotal) *
                    100
                  }%`,
                }}
              />
            </div>
            {Math.round(
              singleDevice.iotDevice.simData.dataVolumeTotal -
                singleDevice.iotDevice.simData.dataVolumeLeft
            )}{" "}
            MB used (
            {Math.round(singleDevice.iotDevice.simData.dataVolumeTotal)} MB
            total)
          </div>
        </>
      )}
    </>
  );
}
