import React, { useEffect, useState } from "react";
import moment from "moment";

import ReactJson from "react-json-view";
import styles from "./trayView.module.scss";
import {
  Button,
  InlineLoading,
  BlockNotification,
  NumberInput,
} from "@un/react";
import { useForm } from "react-hook-form";
import CrudButton from "components/CrudButton";
import MultiCheckbox from "components/MultiCheckbox";
import { devicesApi } from "ducks/devices";

export default function TrayView({ id }) {
  const amount = 28;
  const rows = [];

  const { register, handleSubmit } = useForm();
  const [timeoutLed, setTimeoutLed] = useState(5);
  //const updateShadowLight = useUpdateShadowLight();

  const [updateShadowLight, updateShadowLightResult] =
    devicesApi.useUpdateShadowLightMutation();

  const [updateLedLightByDeviceId, updateLedLightByDeviceIdResult] =
    devicesApi.useUpdateLedLightByDeviceIdMutation();

  const [updateCaseStatus, updatCaseStatusResult] =
    devicesApi.useUpdateCaseStatusMutation();

  const { data: singleDevice } = updatCaseStatusResult;

  const { data: shadowLightStatus } = updateShadowLightResult;

  for (var i = 0; i < amount; i++) {
    rows.push(
      <MultiCheckbox
        className={styles.trayViewEntry}
        labelText={
          <>
            <span className={styles.trayViewEntryTitle}>{i + 1}</span>

            {singleDevice?.device?.shadow?.state.reported?.led && (
              <div className={styles.trayViewList}>
                {moment
                  .unix(
                    singleDevice?.device?.shadow?.metadata?.reported?.led?.[
                      i
                    ]?.[1]?.timestamp
                  )
                  .format("DD.MM.YY HH:mm")}{" "}
                ({singleDevice?.device?.shadow?.state?.reported?.led?.[i]?.[1]})
              </div>
            )}
            {singleDevice?.caseStatus?.message?.[i] && (
              <div className={styles.trayViewJson}>
                <ReactJson
                  collapsed
                  src={singleDevice?.caseStatus.message?.[i]}
                />
              </div>
            )}
          </>
        }
        {...register(`tray[${i}]`)}
        id={`tray-${i}`}
      />
    );
  }

  useEffect(() => {
    updateCaseStatus(id);
  }, [id]);

  const onSubmit = (values) => {
    const ledValues = values.tray.map((e, i) => {
      return [i + 1, e === true ? 100 : 0];
    });

    updateLedLightByDeviceId({
      id,
      data: {
        led: ledValues,
        timeout: timeoutLed,
      },
    });
  };
  return (
    <>
      {singleDevice?.shadow && (
        <>
          <h3>Shadow</h3>
          <p>Lorem ipsum bin jomen dolor et sulamen.</p>
          <br />
          <ReactJson collapsed src={singleDevice?.shadow} />
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Case Status</h3>
        <Button onClick={() => updateCaseStatus(id)}>Get case status</Button>
        {updatCaseStatusResult.isLoading && (
          <InlineLoading
            description="Load case status..."
            //success={updatCaseStatusResult.isSuccess}
          />
        )}
        <br /> <br />
        {updatCaseStatusResult?.data && (
          <>
            <h4>Case Status result</h4>
            <ReactJson collapsed src={updatCaseStatusResult?.data} />
          </>
        )}
        {updatCaseStatusResult?.error && (
          <BlockNotification
            title="Fetching failed"
            subtitle={
              <ReactJson collapsed src={updatCaseStatusResult?.error} />
            }
            kind="error"
          />
        )}
        <div className={styles.trayView}>{rows}</div>
        <NumberInput
          labelText="Timeout"
          helperText="The time the leds should be turned on"
          onChange={(value) => setTimeoutLed(value)}
          value={timeoutLed}
          addonAfter={<>seconds</>}
        />
        <br />
        <CrudButton loading={shadowLightStatus} type="submit">
          Submit lights
        </CrudButton>
        {updateLedLightByDeviceIdResult.isLoading && (
          <InlineLoading description="LEDs are updating..." />
        )}
        {updateLedLightByDeviceIdResult?.data && (
          <ReactJson collapsed src={updateLedLightByDeviceIdResult?.data} />
        )}
      </form>
    </>
  );
}
