import React from "react";
import { InputGroup, TextInput, ModalWrapper } from "@un/react";
//import styles from "./styles.module.scss";
import medications from "ducks/medications";
import useSettingsForm from "helpers/useSettingsFormNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/pro-solid-svg-icons";
import ScanButton from "components/Scanner/ScanButton";
import ColorInput from "components/ColorInput";
import { useParams } from "react-router-dom";
import { Controller } from "react-hook-form";
import MultiCheckbox from "components/MultiCheckbox";
import pillList from "helpers/pillList";
import styles from "./styles.module.scss";
import MedicationIcon from "components/MedicationIcon";
import { Trans } from "react-i18next";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import UploadButton from "components/UploadButton";
import { useDispatch } from "react-redux";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { medicationsApi } from "ducks/medicationsApi";

export default function SettingsMedicationsDetail() {
  const params = useParams();
  const store = useSettingsForm({
    name: "medications",
    api: medicationsApi,
    url: `/${params.organization}/medications`,
    entryName: (entry) => entry?.meta?.name,
  });

  const {
    entryData,
    register,
    form: { control, watch },
    urlId,
  } = store;

  const color = watch("meta.color");
  const dispatch = useDispatch();

  return (
    <SettingsContentWrapper {...store}>
      {/*<ScanButton buttonText={<Trans>Scan from packaging</Trans>} />*/}
      <TextInput labelText={<Trans>Name</Trans>} {...register("meta.name")} />
      <h3>
        <Trans>Additional information</Trans>
      </h3>
      <TextInput
        labelText={<Trans>Manufacturer</Trans>}
        {...register("meta.manufacturer")}
      />
      <TextInput
        labelText={<Trans>Active ingredients</Trans>}
        {...register("meta.activeIngredients")}
      />
      <InputGroup
        labelText={<Trans>Dosage form</Trans>}
        helperText={
          <Trans>Please select the dosage form of the medicine</Trans>
        }
        className={styles.weekdayWrapper}
      >
        {Object.entries(pillList).map((e, i) => (
          <MultiCheckbox
            className={styles.medicationRadio}
            labelText={
              <div>
                <MedicationIcon
                  icon={e[1]}
                  color={color}
                  className={`${styles.medicationIcon} ${
                    !color ? styles.medicationIconNoColor : ""
                  }`}
                />
              </div>
            }
            type="radio"
            id={e[0]}
            value={e[0]}
            {...register("meta.dosageForm")}
          />
        ))}
      </InputGroup>
      <Controller
        render={({ field }) => <ColorInput {...field} />}
        name="meta.color"
        control={control}
        labelText={<Trans>Color</Trans>}
        helperText={
          <Trans>
            Choosing a color will help you to distinguish the medication
          </Trans>
        }
      />
      {/*<UploadButton
        userImage={entryData?.avatar}
        onUpload={(file) => {
          dispatch(
            medications.actions.updateUserImage({
              medication: urlId,
              bodyFormData: file,
            })
          );
        }}
      />*/}
      <SettingsSubmitButton {...store} />
    </SettingsContentWrapper>
  );
}
