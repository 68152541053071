import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "@un/react";
import globalState from "ducks/globalState";

export default function useApiErrors() {
  const errors = useSelector((state) => state.globalState.errors);
  const history = useHistory();
  const dispatch = useDispatch();
  console.warn("We got a rejected action joo!", errors);

  useEffect(() => {
    if (errors.length > 0 && errors[errors.length - 1]) {
      const error = errors[errors.length - 1];
      if (
        error?.payload?.status === 400 &&
        error.payload.data.message === "User does not exist"
      ) {
        dispatch(globalState.actions.reset());
        //history.push("/new-user");
      }
      if (error?.payload?.status === 401) {
        dispatch(globalState.actions.reset());
        history.push("/login");
      }
    }
  }, [errors.length]);
  return null;
}
