import React from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";

import LoginWrapper, { LoginWrapperTitle } from "components/AuthWrapper";
import styles from "./device.module.scss";
import hospitalIllustration from "./device.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { Button, ModalWrapper, TextInput } from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";

import { Trans } from "react-i18next";
import ButtonRouter from "components/ButtonRouter";
import SubmitWrapper from "components/SubmitWrapper";
import SettingsDevicesNew from "components/SettingsDevices/SettingsDevicesNew";

const SettingsMobileHeader = () => {
  return null;
};

const SettingsContentHeader = () => {
  return null;
};

const FormContent = ({
  children,
  handleSubmit,
  onSubmit,
  className,
  notification,
  formNotification,
  formClasses,
}) => {
  return (
    <form /*className={className}*/ onSubmit={handleSubmit(onSubmit)}>
      {notification}
      {formNotification}
      <div /*className={formClasses}*/>{children}</div>
    </form>
  );
};

const Content = ({ className, children }) => (
  <div className={styles.deviceModal}>{children}</div>
);

const SidebarContentBody = ({ className, children }) => (
  <div className={styles.deviceModal}>{children}</div>
);

export default function Device() {
  const { organization } = useParams();
  return (
    <LoginWrapper
      rightSide={
        <img
          alt="Illustration of a hospital"
          className={styles.image}
          src={hospitalIllustration}
        />
      }
    >
      <LoginWrapperTitle kind="small">
        <Trans>Connect Anabox Smart</Trans>
      </LoginWrapperTitle>

      <p className={styles.text}>
        Wenn Sie bereits eine Anabox Smart besitzen kann Sie direkt mit dem
        Patienten verknüpft werden
      </p>

      <SubmitWrapper>
        <ButtonRouter isPlain withOrganization to={`/onboarding/success`}>
          <Trans>Skip step</Trans>
        </ButtonRouter>
        <ModalWrapper
          passiveModal
          modalHeading={<Trans>Connect device</Trans>}
          customButton={
            <Button large>
              <Trans>Connect device</Trans>
            </Button>
          }
        >
          <SettingsDevicesNew
            components={{
              SettingsMobileHeader,
              SettingsContentHeader,
              FormContent,
              Content,
              SidebarContentBody,
            }}
          />
        </ModalWrapper>
      </SubmitWrapper>

      {/*<div className={styles.deviceAdd}>
        <SettingsDevicesNew />
    </div>*/}
    </LoginWrapper>
  );
}
