import React from "react";

import styles from "./auth-wrapper.module.scss";

import { Button, Icon } from "@un/react";
import useIsDesktop from "helpers/useIsDesktop";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";
import ButtonRouter from "components/ButtonRouter";

const AuthWrapper = ({
  children,
  logo,
  rightSide,
  customBack,
  showBackLink,
  backLinkText,
  backLink,
  hideContentMobile,
  backLinkIconReverse = true,
  width,
}) => {
  const classes = classnames(
    {
      [styles.loginHideContentMobile]: hideContentMobile === true,
    },
    styles.login
  );

  const isDesktop = useIsDesktop();

  const props = {
    icon: <FontAwesomeIcon icon={faChevronLeft} />,
    iconReverse: backLinkIconReverse,
    kind: isDesktop ? "tertiary" : undefined,
    className: styles.backLink,
    to: backLink,
  };

  const backLinkElement = customBack ? (
    customBack(props)
  ) : (
    <ButtonRouter {...props} kind="tertiary">
      {backLinkText ? backLinkText : "Back to login"}
    </ButtonRouter>
  );

  return (
    <div id="auth-wrapper-scroll" className={classes}>
      <section
        className={styles.loginForm}
        style={{ width: width ? `${width}vw` : undefined }}
      >
        <div className={styles.loginLogo}>{logo}</div>

        {showBackLink && backLinkElement}
        <div className={styles.loginFormContent}>{children}</div>
      </section>
      {rightSide && (
        <section
          className={styles.loginContent}
          style={{ width: width ? `${100 - width}vw` : undefined }}
        >
          {/*showBackLink && backLinkElement*/}
          {rightSide}
        </section>
      )}
    </div>
  );
};

export default AuthWrapper;

export function LoginWrapperTitle({ kind, ...other }) {
  const classes = classnames(styles.loginWrapperTitle, {
    [styles.loginWrapperTitleSmall]: kind === "small",
  });

  return <h4 className={classes} {...other} />;
}
