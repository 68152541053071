import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./calendar.scss";
import styles from "./styles.module.scss";

import Toolbar from "./Toolbar";
import TrayOverview from "./TrayOverview";
import qs from "qs";
import Event from "./Event";
import { calendarFuturePoints } from "@wirewire/helpers";
import Agenda from "./Agenda";
import { calendarsApi } from "ducks/calendars";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useActiveUserDevice } from "helpers/useUsers";
import useBakedEvents from "helpers/calendars/useBakedEvents";

import NoDeviceAssigned from "components/SettingsDevices/NoDeviceAssigned";
import useIsDesktop from "helpers/useIsDesktop";
import { endOfDay, startOfISOWeek, startOfWeek } from "date-fns";

import { formatISO } from "date-fns/esm";
import Status from "components/Status";
import { Trans } from "react-i18next";
import { devicesApi } from "ducks/devices";

const localizer = momentLocalizer(moment);

export default function CalendarPage({ fillOnly, innerRef }) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const isDesktop = useIsDesktop();

  const { date, view } = qs.parse(location.search, { ignoreQueryPrefix: true });

  let range = TrayOverview.range(date);

  const calendarsApiResult = calendarsApi.useSearchCalendarsQuery({
    patient: params.entry,
  });
  const { data = [] } = calendarsApiResult;

  const { eventsBaked, eventsBakedReduced } = useBakedEvents();

  const result = React.useMemo(() => {
    const dataForCalendar = calendarFuturePoints({
      events: data,
      from: range[0],
      to: endOfDay(range[range.length - 1]),
    });

    return dataForCalendar.map((e) => {
      return { ...e, start: e.date, end: e.date };
    });
  }, [data, range]);

  const currentUserDevices = useActiveUserDevice();
  const reeeee = devicesApi.useGetEventsQuery(
    {
      id: currentUserDevices.data?.[0]?.id,
      params: {
        DateStart: formatISO(range[0]),
        DateEnd: formatISO(endOfDay(range[range.length - 1])),
      },
    },
    {
      skip:
        currentUserDevices.data?.[0]?.id === undefined ||
        formatISO(range[0]) === undefined,
    }
  );

  const { data: iotDevicesQueryData } = reeeee;

  const showSelect = (e) => {};
  const handleSelect = (e) => {};

  let components = {
    event: Event, // used by each view (Month, Day, Week)
    toolbar: Toolbar,
  };

  const onNavigate = (props) => {
    const params = {
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      date: new Date(props).toISOString(),
      trayDate: startOfISOWeek(new Date(props)).toISOString(),
    };
    history.push(`${location.pathname}?${qs.stringify(params)}`);
  };

  const onView = (props) => {
    const params = {
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      view: props,
    };
    history.push(`${location.pathname}?${qs.stringify(params)}`);
  };

  const [pingDevice, resultPingDevice] =
    devicesApi.useUpdatePindByDeviceIdMutation();

  const currentActiveDevices = useActiveUserDevice();

  useEffect(() => {
    if (currentActiveDevices.data?.[0]?.id)
      pingDevice(currentActiveDevices.data[0].id);
  }, [currentUserDevices.data?.[0]?.id]);

  return (
    <div className={styles.calendarWrapper} ref={innerRef}>
      {/*<ReactJson src={currentUserDevices} />
  <ReactJson src={reeeee} />*/}
      <Status
        fetching={
          <div className={styles.loading}>
            <Trans>Loading...</Trans>
          </div>
        }
        query={calendarsApiResult}
        showContent
      >
        {!isDesktop && <NoDeviceAssigned />}
        <Calendar
          components={components}
          selectable
          selectRangeFormat={(e) => {
            return null;
          }}
          localizer={localizer}
          formats={{
            agendaTimeRangeFormat: ({ start, end }, culture, localizer) =>
              `${localizer.format(start, "LT")}`,
          }}
          events={result}
          eventsBaked={eventsBaked}
          eventsBakedReduced={eventsBakedReduced}
          startAccessor="start"
          endAccessor="end"
          messages={{
            tray: "Tray View",
            dayDispender: "7-Tage",
          }}
          views={
            fillOnly
              ? { tray: TrayOverview }
              : {
                  tray: TrayOverview,
                  //dayDispender: DaysDispenderView,
                  /*month: true,
                week: true,
                day: true,*/
                  agenda: Agenda,
                }
          }
          defaultView="tray"
          iotDevicesQueryData={iotDevicesQueryData}
          date={moment(date)}
          onNavigate={onNavigate}
          onSelectEvent={showSelect}
          onSelectSlot={handleSelect}
          onView={onView}
          view={view}
          style={{ height: "100%" }}
        />
      </Status>
    </div>
  );
}
