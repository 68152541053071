import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const iotDevicesApi = generateCrudApi({
  name: "iotdevice",
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (request) => ({
        url: `iotdevice/events/${request.id}`,
        method: "get",
        params: request.params,
      }),
    }),
  }),
});
//events/nrf-352656100669616?DateStart=2021-04-23T19:37%2B01:00&DateEnd=2021-05-24T21:37%2B01:00&TypeFilter=
