import React, { Component, createContext, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js";
import { store } from "store";
import axiosInterceptors from "axiosInterceptors";
import urlToken from "helpers/urlToken";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

// create the context
export const Auth0Context = createContext();
export const useAuth0 = () => useContext(Auth0Context);

export class Auth0Provider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAuthenticated: false,
      user: null,
      token: false,
      auth0Client: Auth0Client,
    };
  }

  config: Auth0ClientOptions = {
    //cache: this.sessionStorageCache,
    cacheLocation: "localstorage",
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
    client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
    redirect_uri: Capacitor.isNativePlatform()
      ? "com.wirewire.memo://wirewirememo.eu.auth0.com/capacitor/com.wirewire.memo/login"
      : window.location.origin + "/login",
    useRefreshTokens: true,
  };
  componentDidMount() {
    this.initializeAuth0();
  }
  // initialize the auth0 library
  initializeAuth0 = async () => {
    var token;
    var isAuthenticated;

    const auth0Client = await createAuth0Client(this.config);
    this.setState({ auth0Client });
    // check to see if they have been redirected after login
    console.log(window.location.href);
    if (
      !window.location.href.includes("com.wirewire.memo") &&
      window.location.search.includes("code=")
    ) {
      return this.handleRedirectCallback();
    } else {
      CapApp.addListener("appUrlOpen", async ({ url }) => {
        if (
          url.includes("state") &&
          (url.includes("code") || url.includes("error"))
        ) {
          await this.handleRedirectCallback(url);
        }
        // No-op on Android
        if (Capacitor.getPlatform() === "ios") await Browser.close();
      });
    }

    isAuthenticated = await auth0Client.isAuthenticated();

    if (
      !isAuthenticated &&
      window.IS_APP &&
      this.props.message?.REFRESH_TOKEN &&
      this.props.message?.REFRESH_TOKEN !== "not found"
    ) {
      const cacheContent = {
        body: {
          refresh_token: this.props.message?.REFRESH_TOKEN,
        },
      };

      const cache = await localStorage.setItem(
        "@@auth0spajs@@::Dg458npvOkZ2uJNsGx9ONMnXVEOXPy0y::default::openid profile email offline_access",
        JSON.stringify(cacheContent)
      );

      await auth0Client.getTokenSilently();
      isAuthenticated = await auth0Client.isAuthenticated();
    }

    const user = isAuthenticated ? await auth0Client.getUser() : null;

    token = await auth0Client.getIdTokenClaims();

    if (token)
      axiosInterceptors.setupInterceptors(
        store,
        token.__raw,
        this.props.history
        //this.state.auth0client.loginWithRedirect
      );
    this.setState({
      isLoading: false,
      isAuthenticated,
      user,
      token: token?.__raw,
    });
    /*} else {
        this.setState({ isLoading: false, isAuthenticated, user: {} });
      }*/
    //}
  };

  updateMetadata = async () => {
    await this.state.auth0Client.getTokenSilently({ ignoreCache: true });
    const user = await this.state.auth0Client.getUser();
    this.setState({
      user,
    });
  };

  handleRedirectCallback = async (url) => {
    console.log("handleRedirectCallback", url);
    this.setState({ isLoading: true });
    await this.state.auth0Client.handleRedirectCallback(url);

    const user = await this.state.auth0Client.getUser();

    const token = await this.state.auth0Client.getIdTokenClaims();
    /*axiosInterceptors.setupInterceptors(
      store,
      token.__raw,
      this.props.history
    );*/

    this.setState({
      user,
      isAuthenticated: true,
      isLoading: false,
      token: token.__raw,
    });
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  render() {
    const { auth0Client, isLoading, isAuthenticated, token, user } = this.state;
    const { children } = this.props;

    const configObject = {
      isLoading,
      isAuthenticated,
      token,
      user,
      updateMetadata: this.updateMetadata,
      handleRedirectCallback: (url) => this.handleRedirectCallback(url),
      buildAuthorizeUrl: (...p: any) => auth0Client.buildAuthorizeUrl(...p),
      loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
      getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
      getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
      logout: async (...p: any) => {
        if (Capacitor.isNativePlatform()) {
          await Browser.open({
            url: auth0Client.buildLogoutUrl({
              returnTo:
                "com.wirewire.memo://wirewirememo.eu.auth0.com/capacitor/com.wirewire.memo",
            }),
          });
          await auth0Client.logout({ localOnly: true });
          window.location.reload();
        } else {
          auth0Client.logout({
            returnTo: process.env.REACT_APP_AUTH_REDIRECT_URL,
          });
        }
        this.props.postMessage({ openRoute: "/logout" });
      },
    };

    if (
      isLoading === false &&
      isAuthenticated === false &&
      window.IS_APP !== "true"
    ) {
      this.props.history.push("/login");
    }

    return (
      <Auth0Context.Provider value={configObject}>
        {children}
      </Auth0Context.Provider>
    );
  }
}
