import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

export default function SettingsTitle({ additional, children, kind, narrow }) {
  const classes = classnames(styles.settingsTitle, {
    [`${styles.subTitle}`]: kind === "subtitle",
    [`${styles.narrow}`]: narrow,
  });

  return (
    <h2 className={classes}>
      {children}
      {additional}
    </h2>
  );
}
