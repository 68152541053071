import React from "react";
import { Button, Modal, ModalWrapper } from "@un/react";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import qs from "qs";

export default function DeleteModal({
  deleteEntry,
  disableDelete = false,
  entryData,
  entryName,
  entryTitle,
  entryDataId,
  customButton,
  customDeleteQuestion,
  customDeleteQuestionTitle,
  customDeleteButtonText,
  customDeleteRedirect,
  urlId,
  primaryButtonText = <Trans>Delete</Trans>,
  secondaryButtonText = <Trans>Cancel</Trans>,
  name,
  nameSingular,
  search,
  narrow,
  overviewUrl,
  url,
  resultDeleteSingle,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const pushOverview = () => {
    const defaultRedirect = `${overviewUrl}/?${qs.stringify({
      ...search,
      deleted: "true",
    })}`;
    if (customDeleteRedirect)
      customDeleteRedirect({ defaultRedirect, entryDataId });
    else history.push(defaultRedirect);
  };

  if (resultDeleteSingle?.data?.id) {
    setTimeout(() => {
      //pushOverview();
    }, 2000);

    return (
      <Modal
        hideClose
        open
        onRequestSubmit={pushOverview}
        modalHeading={<Trans>Delete successful</Trans>}
        primaryButtonText={<Trans>Continue</Trans>}
      >
        <Trans>The entry was successfully deleted</Trans>
      </Modal>
    );
  }

  return (
    <ModalWrapper
      modalHeading={
        customDeleteQuestionTitle || (
          <Trans
            i18nKey="deleteQuestionTitle"
            values={{ name: t(name + "-SINGULAR") }}
          />
        )
      }
      primaryButtonText={
        resultDeleteSingle?.isLoading ? (
          <Trans>Loading...</Trans>
        ) : (
          primaryButtonText
        )
      }
      primaryButtonDisabled={disableDelete || resultDeleteSingle?.isLoading}
      secondaryButtonText={secondaryButtonText}
      handleSubmit={deleteEntry}
      customButton={
        customButton ? (
          customButton
        ) : (
          <Button
            kind="danger"
            //to={`${url}`}
            icon={narrow ? <FontAwesomeIcon icon={faTrashAlt} /> : undefined}
          >
            {narrow ? undefined : customDeleteButtonText ? (
              customDeleteButtonText
            ) : (
              <Trans
                i18nKey="deleteName"
                values={{ name: t(name + "-SINGULAR") }}
              />
            )}
          </Button>
        )
      }
    >
      {customDeleteQuestion ? (
        customDeleteQuestion
      ) : entryTitle ? (
        <Trans i18nKey="deleteQuestion" values={{ name: `${entryTitle}` }}>
          Are you sure that you want to delete <b>{{ name }}</b>
        </Trans>
      ) : (
        <Trans>Are you sure that you want to delete the entry?</Trans>
      )}
    </ModalWrapper>
  );
}
