import React from "react";
import SidebarContent from "./SidebarContent";

import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router-dom";

import SelectCase from "../SelectPatient";
import { Button } from "@un/react";

import { Trans } from "react-i18next";
import { useAuth0 } from "contexts/auth0-context";
import { useActiveUserDevice } from "helpers/useUsers";
import ButtonRouter from "components/ButtonRouter";
import { faFill, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import qs from "qs";
import { useLocation } from "react-router";
import moment from "moment";
import FillStartButton from "components/FillProcess/FillStart";
import useCurrentOrganization from "helpers/organizations/useCurrentOrganization";
import ReactJson from "react-json-view";
import NewEntryButton from "components/Calendar/NewEntryButton";
import useQs from "helpers/useQs";

function Sidebar({ handlePrint }) {
  const params = useParams();
  const location = useLocation();
  const { user } = useAuth0();

  const allQuery = useCurrentOrganization();
  const { data: currentOrganization } = allQuery;

  const activeUserDevice = useActiveUserDevice();
  const { trayDate, timeCategory } = useQs();

  if (!currentOrganization) return null;

  const locationParams = {
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
  };

  const to = `/calendar/${params.entry}/new/${moment(
    locationParams.date ? locationParams.date : undefined
  ).toISOString()}?${location.search}`;

  return (
    <>
      <div className={styles.folder}>
        <div>
          <h2 className={styles.folderTitle}>
            {currentOrganization.kind === "private"
              ? "Übersicht"
              : currentOrganization?.name}
          </h2>
          <p className={styles.folderSubTitle}>{user && user.name}</p>
        </div>
      </div>
      <div className={styles.selectCase}>
        <SelectCase />

        {
          activeUserDevice.data && activeUserDevice.data.length > 0 ? (
            <FillStartButton
              customButton={
                <Button className={styles.fillButton} kind="tertiary">
                  <FontAwesomeIcon icon={faFill} />
                </Button>
              }
            />
          ) : null /*(
          <Button className={styles.fillButton} kind="tertiary">
            <Trans>Add device</Trans>
          </Button>
       ) */
        }
        {/*<ButtonRouter
          className={styles.addButton}
          withOrganization
          to={to}
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
        />*/}
        <NewEntryButton
          className={styles.addButton}
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          newDate={trayDate}
          newTimeCategory={timeCategory}
          kind="primary"
          small={false}
          iconReverse={false}
        >
          <Trans>Intake</Trans>
        </NewEntryButton>
      </div>

      <div className={styles.sidebarContent}>
        <SidebarContent handlePrint={handlePrint} />
      </div>
    </>
  );
}

export default Sidebar;
