import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const notificationsApi = generateCrudApi({
  name: "notifications",
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: (request) => ({
        url: `notifications/`,
        method: "get",
      }),
    }),
    sendDefaultNotification: builder.mutation({
      query: (request) => ({
        url: `notifications/test`,
        body: request.data,
        method: "post",
      }),
    }),
  }),
});
