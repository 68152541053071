import React, { useEffect, useState } from "react";

import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import { devicesApi } from "ducks/devices";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSettingsForm from "helpers/useSettingsFormNew";
import { Button, TextInput } from "@un/react";
import styles from "./settingsDevicesDetail.module.scss";
import ScanButton from "components/Scanner/ScanButton";
import fillIllustration from "./01-d.png";
import devicesButton from "./devicesButton.png";
import devicesError from "./illustrations/device-error.png";
import deviceSuccess from "./illustrations/device-success.png";

import qrDevices from "./illustrations/qr-scan.mp4";
import qrDevicesButton from "./illustrations/QR-Button_4.mp4";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { useDebug } from "helpers/useCurrentUser";
import ReactJson from "react-json-view";
import ButtonRouter from "components/ButtonRouter";
import classnames from "classnames";
import InlineLoadingLarge from "components/InlineLoadingLarge";

function InfoWrapper({ className, children, image }) {
  const infoWrapperClasses = classnames(styles.infoWrapper, className);

  return (
    <div className={infoWrapperClasses}>
      <div className={styles.infoWrapperImageContainer}>
        <div className={styles.infoWrapperImage}>{image}</div>
      </div>
      {children}
    </div>
  );
}

export default function SettingsDevicesNew({ components }) {
  const params = useParams();
  const [formValues, setValues] = useState();
  const [response, setResponse] = useState();
  const [step, setStep] = useState("start");

  const debug = useDebug();
  const { t } = useTranslation();

  const resetSingle = () => {
    setStep("start");
    setResponse(undefined);
    setValues(undefined);
  };

  const [registerDevice, registerDeviceResult] =
    devicesApi.useRegisterDeviceMutation();

  const submitNewDigitalDevice = (values) => {
    const submitValues = {
      id: values.deviceId.replace(/\s/g, ""),
      body: { organization: params.organization, enable: true },
    };
    setStep("onboarding");
    registerDevice(submitValues);
    setValues(submitValues);
  };

  useEffect(() => {
    if (registerDeviceResult.data) setResponse(registerDeviceResult);
    if (
      step === "onboarding" &&
      registerDeviceResult.data?.activation_status &&
      registerDeviceResult.data?.activation_status !== "success" &&
      registerDeviceResult.data?.activation_status !== "timeout"
    ) {
      setTimeout(() => {
        formValues.body.enable = false;
        registerDevice(formValues);
      }, 4000);
    }
  }, [registerDeviceResult.data?.activation_status]);

  const setDeviceId = (e) => {
    setValue("deviceId", e);
    submitNewDigitalDevice({ deviceId: e });
  };

  const store = useSettingsForm({
    name: "devices",
    api: devicesApi,
    customSubmit: submitNewDigitalDevice,
  });

  const {
    form: {
      formState: { errors },
      register,
      setValue,
      watch,
    },
  } = store;

  const manually = watch("manually");

  const cancelButton = (
    <div className={styles.cancelButton}>
      <Button onClick={resetSingle} kind="tertiary">
        <Trans>Cancel</Trans>
      </Button>
    </div>
  );

  /*<div className={styles.info}>*/
  /*<img alt="Scan introduction" src={fillIllustration} />*/
  return (
    <SettingsContentWrapper
      {...store}
      hideMessages
      fullHeight
      components={components}
      resultCreateSingle={registerDeviceResult}
      title={<Trans>Register new device</Trans>}
    >
      {step === "start" && (
        <>
          <InfoWrapper
            image={
              <video
                autoPlay
                loop
                muted
                playsInline
                className={styles.video}
                poster={devicesError}
              >
                <source src={qrDevices} type="video/mp4" />
              </video>
            }
          >
            <p>
              <Trans i18nKey="TURNANDSCAN">
                Turn the device over
                <wbr /> and scan the code
              </Trans>
              <small>
                <Trans i18nKey="MAKESUREFULLYCHARGED">
                  Make sure the device
                  <wbr /> is fully charged
                </Trans>
              </small>
            </p>
          </InfoWrapper>

          <div className={styles.buttonWrapper}>
            <ScanButton
              autoSubmit
              buttonClassName={styles.buttonClassName}
              buttonText={<Trans>Scan code</Trans>}
              large
              setValue={setDeviceId}
            />
            {!manually ? (
              <Button
                kind="tertiary"
                large
                onClick={() => setValue("manually", !manually)}
              >
                <Trans>Or type code</Trans>
              </Button>
            ) : (
              <div className={styles.manuallyWrapper}>
                <TextInput
                  {...register("deviceId", {
                    required: true,
                    minLength: 19,
                    maxLength: 19,
                  })}
                  invalid={errors.deviceId}
                  placeholder={t("19-digit code")}
                  invalidText={t("Device Id not valid")}
                />
                <SettingsSubmitButton title={<Trans>Add device</Trans>} />
              </div>
            )}

            <ButtonRouter
              isPlain
              to={`./new?analog=true`}
              className={styles.analogButton}
            >
              <Trans>Add analog device</Trans>
            </ButtonRouter>
          </div>
        </>
      )}

      {step === "onboarding" && registerDeviceResult.isError ? (
        <>
          <InfoWrapper
            className={styles.error}
            image={<img alt="Device error" src={devicesError} />}
          >
            <p>
              <Trans>The registration failed</Trans>
              <small>
                <Trans>{registerDeviceResult?.error?.data?.message}</Trans>
              </small>
            </p>
          </InfoWrapper>
          <div className={styles.cancelButton}>
            <Button onClick={resetSingle} kind="tertiary">
              <Trans>Start again</Trans>
            </Button>
          </div>
        </>
      ) : step === "onboarding" &&
        response?.data?.activation_status === undefined ? (
        <>
          <InfoWrapper image={<InlineLoadingLarge />}>
            <p>
              <Trans>Waiting for device...</Trans>
            </p>
          </InfoWrapper>
          {cancelButton}
        </>
      ) : step === "onboarding" &&
        response?.data?.activation_status === "pending" ? (
        <>
          <InfoWrapper
            image={
              <video autoPlay loop muted playsInline className={styles.video}>
                <source src={qrDevicesButton} type="video/mp4" />
              </video>
            }
          >
            <p>
              <Trans>Press the side button for 1 second</Trans>
              <small>Warte einige Sekunden nach dem Ton.</small>
            </p>
          </InfoWrapper>
          {cancelButton}
        </>
      ) : step === "onboarding" &&
        response?.data?.activation_status === "device_confirmed" ? (
        <>
          <InfoWrapper
            image={<img alt="Scan introduction" src={deviceSuccess} />}
          >
            <p>
              <Trans>You pressed the button. Waiting for activation...</Trans>
            </p>
          </InfoWrapper>
          {cancelButton}
        </>
      ) : step === "onboarding" &&
        response?.data?.activation_status === "timeout" ? (
        <>
          <InfoWrapper
            className={styles.error}
            image={<img alt="Scan introduction" src={devicesError} />}
          >
            <p>
              <Trans>No button press was recognized. Please start again.</Trans>
            </p>
          </InfoWrapper>
          <div className={styles.cancelButton}>
            <Button onClick={resetSingle} kind="tertiary">
              <Trans>Start again</Trans>
            </Button>
          </div>
        </>
      ) : step === "onboarding" &&
        response?.data?.activation_status === "success" ? (
        <>
          <InfoWrapper
            className={styles.success}
            image={<img alt="Scan introduction" src={deviceSuccess} />}
          >
            <p>
              <Trans>Device successfully activated</Trans>
              <small>You can use the device now.</small>
            </p>
          </InfoWrapper>
          <div className={styles.cancelButton}>
            <ButtonRouter
              withOrganization
              to={`/devices/${response?.data?.createdDevice?.id}`}
            >
              <Trans>Go to device</Trans>
            </ButtonRouter>
          </div>
        </>
      ) : null}

      {debug && 1 === 2 && <ReactJson src={registerDeviceResult} />}
    </SettingsContentWrapper>
  );
}
