import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//import LocalStorageBackend from "i18next-localstorage-backend";

const options = { caches: ["localStorage", "cookie"] };

i18n
  .use(LanguageDetector)
  //.use(Backend)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {
      en: {
        translations: {
          "Data for lorem ipsum":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Welcome to React": "Welcome to React and react-i18next",
          welcome: "Hello <br/> <strong>World</strong>",
          "Switch organization": "Change Org.",
          deleteName: "Delete {{name}}",
          detailsName: "{{name}} Details",
          accounts: "Accounts",
          "account-SINGULAR": "Account",
          intakeCountText:
            "There are <1>{{count}} intake times</1> in the selected period.",
          OffsetTimeHelperText:
            "Set the Offset in which it is OK to take out med too early without warning in Seconds Alarm Time + Offset is the time the alarm stops More than one cup can blink if in the same offset region Default:600",
          UPDATEINTAKETIMES: "This will update {{count}} intake times",
        },
      },
      de: {
        translations: {
          "Data for lorem ipsum": "Data for lorem ipsum",
          "Start the filling aid": "Befüllhilfe starten",
          "Start using the app": "Starten",
          AGB_TEXT:
            "Mit klick auf `Starten` stimmen Sie zu die AGB gelesen und zur Kenntnis genommen zu haben.",
          "How do you want to use memo?": "Wie möchten Sie Memo nutzen?",
          Settings: "Verwaltung",
          "Return to overview": "Zurück zur Übersicht",
          "What do you want to do?":
            "Benutzer einladen oder Patienten anlegen?",
          "You can invite an existing user or create a patient.":
            "Sie können weitere Benutzer einladen oder neue Patienten anlegen.",
          "Invite user": "Benutzer einladen",
          "Add patient": "Patient anlegen",
          Today: "Heute",
          Back: "Zurück",
          Next: "Weiter",
          Continue: "Weiter",
          "Tray View": "Spenderansicht",
          Month: "Monat",
          Week: "Woche",
          Day: "Tag",
          Agenda: "Agenda",
          DAILY: "täglich",
          WEEKLY: "wöchentlich",
          Patients: "Patienten",
          Devices: "Geräte",
          Security: "Sicherheit",
          Amount: "Anzahl",
          Weekdays: "Wochentage",
          Medications: "Medikamente",
          Organization: "Organisation",
          Notification: "Mitteilungen",
          Override: "Überschreiben",
          "This is you": "Das bist du",
          "Add user": "Benutzer hinzufügen",
          "Switch org.": "Org wechseln",
          "Switch organization": "Organisation wechseln",
          "No photo set": "Kein Foto festgelegt",
          "Upload photo": "Foto hochladen",
          "Contact information": "Kontaktinformationen",
          Gender: "Geschlecht",
          Language: "Sprache",
          male: "männlich",
          female: "weiblich",
          Users: "Benutzer",
          excludeDateE: "{{excludeDate}} ausschließen",
          "Exclude individual intake time": "Einzelne Einnahme ausschließen ",
          "anabox-smart": "Anabox Smart",
          Street: "Straße",
          City: "Stadt",
          "First name": "Vorname",
          "Last name": "Nachname",
          "Postal code": "PLZ",
          "Delete intake": "Eintrag löschen",
          "Phone number": "Telefonnummer",
          "Change picture": "Bild ändern",
          "Connected Apps": "Verbundene Apps",
          "Contact persons": "Kontaktpersonen",
          "Treating doctor": "Behandelnder Arzt",
          "Add new calendar": "Erstellen",
          "Update calendar": "Aktualisieren",
          Timeframe: "Zeitraum",
          Calendar: "Kalender",
          Insurance: "Versicherung",
          "Insurance number": "Versicherungsnummer",
          New: "Neu",
          "Co-payment exemption": "Zuzahlungsbefreiung",
          "Retirement home": "Seniorenheim",
          "Retirement home residents": "Retirement home residents",
          filled: "befüllt",
          "Change organization": "Organisation wechseln",
          Address: "Anschrift",
          Incompatibilities: "Unverträglichkeiten",

          Notes: "Anmerkungen",
          "Update patient": "Patient aktualisieren",
          "Remove patient": "Patient löschen",
          "You are using Anmed Memo offline":
            "Sie verwenden Anmed Memo offline",
          "You are using Anmed Memo as a offline application. No data will be uploaded and all data is stored inside your browsers local storage.":
            "Sie verwenden Anmed Memo als offline Anwendung. Es werden keine Daten ins Internet geladen. Alle Daten sind in Ihrem Browser gespeichert.",
          Save: "Speichern",
          Print: "Drucken",
          Logout: "Abmelden",
          "Add new device": "Neues Gerät hinzufügen",
          "Add device": "Gerät hinzufügen",
          "Return to devices": "Zurück zur Übersicht",
          "New device": "Neues Gerät",
          "Which product do you want add?":
            "Welches Gerät möchten Sie hinzufügen?",
          "Search...": "Suchen...",
          Birthdate: "Geburtsdatum",
          Date: "Datum",
          Stocks: "Lager",
          Frequency: "Frequenz",
          once: "einmalig",
          daily: "täglich",
          weekly: "wöchentlich",
          monthly: "monatlich",
          Update: "Aktualisieren",
          "Repeat intake": "Wiederholende Einnahme",
          new: "neu",
          "Edit medication": "Medikation bearbeiten",
          "not taken!": "nicht eingenommen!",
          punctual: "pünktlich",
          late: "zu spät",
          Instruction: "Anweisungen",
          "Add medication": "Medikation hinzufügen",
          "Add Patient": "Patient hinzufügen",
          "Add stock": "Eintrag hinzufügen",
          "Start typing to search...": "Tippe um zu suchen...",
          Notifications: "Mitteilungen",
          notifications: "Mitteilungen",
          stocks: "Lager",
          "Are you sure you want to log out?":
            "Möchten Sie sich wirklich abmelden?",
          "Select a medication...": "Medikament wählen...",

          "Manage custom medications": "Eigene Medikamente verwalten",
          Stock: "Lager",
          created: "erstellt",
          "New entry": "Neuer Eintrag für",
          "was successfully created.": "wurde erfolgreich erstellt.",
          "View intake": "Medikamenteneinnahme",
          Return: "Zurück",
          "Create medication intake": "Neue Einnahme",
          "Edit medication intake": "Einnahme bearbeiten",
          "Unnamed device": "Unbenanntes Gerät",
          "Unnamed user": "Unbenannter Benutzer",
          Unnamed: "Unbekannte",
          "Select an organization": "Organisation auswählen",
          "Create new organization": "Neue Organisation",
          No: "Keine",
          found: "gefunden",
          Welcome: "Willkommen",

          "Setup a patient to continue": "Erstelle einen Patienten",
          "Create a patient": "Neuer Patient",
          "Sign up": "Registrieren",
          Login: "Anmelden",
          "Intake times": "Einnahmezeiten",
          "No device assigned": "Kein Gerät zugeordnet",
          "You need to add a device to the user":
            "Ordne dem Benutzer ein Gerät zu",
          "Create device": "Neues Gerät",
          "Private organization": "Private Organisation",
          "Unnamed organization": "Unbenannte Organisation",
          "Delete fills & continue": "Füllungen löschen & weiter",
          "Show all": "Alle anzeigen",
          "Finish filling": "Befüllung abschließen",

          "Fill was successfull": "Befüllung erfolgreich",
          "Day is filled": "Tag wurde befüllt",
          "New Intake": "Neue Einnahme",
          Fill: "Befüllen",
          Reset: "Zurücksetzen",
          "Organization name": "Name der Organisation",
          Description: "Beschreibung",
          "Default intake times": "Standardeinnahmezeiten",
          morning: "Morgen",
          noon: "Mittag",
          "after noon": "Abend",
          night: "Nacht",
          "Associated device": "Zugeordnetes Gerät",
          "Go to device": "Zum Gerät",
          Category: "Kategorie",
          patient: "Patient",
          pharmacist: "Apotheker",
          doctor: "Arzt",
          nurse: "Pflegekraft",
          relative: "Angehöriger",
          account: "Account",
          "Update organization": "Organisation aktualisieren",
          organization: "Organisation",
          deleteName: "{{name}} löschen",
          newName: "Neuer {{name}}",
          detailsName: "{{name}} Details",
          deleteQuestion: "Möchtest du <1>{{ name }}</1> wirklich löschen?",
          "Last Admin can not be removed":
            "Letzter Admin kann nicht entfernt werden",
          "You need to add another admin first.":
            "Lege zuerst einen anderen Administrator an.",
          Remove: "Entfernen",
          deleteQuestionRemove:
            "Möchtest du <1>{{ name }}</1> wirklich entfernen?",
          "Exit organization": "Organisation verlassen",
          "Do you want to leave the organization?":
            "Möchtest du die Organisation verlassen?",
          "Remove user": "Benutzer entfernen",
          users: "Benutzer",
          "not taken": "nicht eingenommen",
          early: "zu früh",
          hours: "Stunden",
          "Organization deleted": "Organisation gelöscht",
          "The organization was successfully deleted":
            "Die Organisation wurde erfolgreich gelöscht",
          "A description for the device": "Kurze Beschreibung des Gerätes",
          devices: "Geräte",
          "Update devices": "Gerät aktualisieren",
          tagesbox: "ANABOX 7-Tage",
          "analog device": "analoges Gerät",
          "No deviceId found": "Keine Geräte-ID gefunden",
          "was successfully activated. You can now start using the device.":
            "wurde erfolgreich erstellt. Du kannst das Gerät jetzt nutzen.",
          "Create a new organization": "Neue Organisation erstellen",
          "Name of the organization": "Name der Organisation",
          "Create organization": "Organisation erstellen",
          "Admin can not be deleted": "Admin kann nicht gelöscht werden",
          "The last admin can not be deleted. You need to add another admin first.":
            "Der letzte Administrator kann nicht gelöscht werden. Du musst erst einen anderen Admin hinzufügen.",
          Cancel: "Abbrechen",
          Delete: "Löschen",
          "users-SINGULAR": "Benutzer",
          "devices-SINGULAR": "Gerät",
          "medications-SINGULAR": "Medikament",
          "organizations-SINGULAR": "Organisation",
          "calendars-SINGULAR": "Einnahme",
          "stocks-SINGULAR": "Lager",
          "accounts-SINGULAR": "Account",
          "notifications-SINGULAR": "Mitteilung",
          deleteQuestionTitle: `{{name}} löschen`,
          "Are you sure that you want to delete the entry?":
            "Möchtest du den Eintrag wirklich löschen?",
          "Go to device": "Zum Gerät",
          userConnectedDevice:
            "Dem Benutzer wurde eine <1>{{name}}</1> zugeordnet.",
          "No device setup": "Kein Gerät zugeordnet",
          "Add a device to this patient": "Ordne dem Patienten ein Gerät zu",
          "Are you sure you want to go to?":
            "Möchtest du die Seite wirklich wechseln, ohne zu speichern?",
          "Show in Apotheken Umschau": "In Apotheken Umschau anzeigen",
          "Unknown medication": "Unbekanntes Medikament",
          "Intake time": "Einnahmezeit",
          Custom: "Anpassen",
          AddNewNameSingular: "{{name}} erstellen",
          UpdateNameSingular: "{{name}} aktualisieren",
          medications: "Medikamente",
          "no description": "keine Beschreibung",
          "account-SINGULAR": "Account",
          "Language (Sprache)": "Sprache (language)",
          accounts: "Accounts",
          nameTitle: "This is your name",
          "Add device": "Hinzufügen",
          "Battery low": "Batteriestand niedrig",
          "Please charge the device": "Bitte lade das Gerät auf.",
          userMessagesUnread_one:
            "Hello <1>{{name}}</1>, you have {{count}} unread message. <5>Go to message</5>.",
          userMessagesUnread_other:
            "Hello <1>{{name}}</1>, you have {{count}} unread messages.  <5>Go to messages</5>.",
          noNameFound: "Kein {{name}} gefunden",
          "Add digital device": "Digitales Gerät hinzufügen",
          "Add analog device": "Analoges Gerät hinzufügen",
          "This is your account": "Dies ist dein Account",
          "Change your language, etc. in the account settings.":
            "Du kannst die Sprache, etc. in den Account-Einstellungen ändern.",
          "Account settings": "Accounteinstellungen",
          intakes: "Einnahmen",
          "No intake times found in this period":
            "Keine Einnahmezeiten in diesem Zeitraum",
          "Add intake times or use another period.":
            "Füge Einnahmezeiten hinzu oder wähle einen anderen Zeitraum.",
          "The selected dates have current fills":
            "Der ausgewählte Zeitraum wurde schon befüllt",
          "By continuing the following period be removed:":
            "Wenn du fortfahren wirst, wird die folgende Befüllung entfernt:",
          "Advanced settings": "Erweiterte Einstellungen",
          "Alarm intensity": "Alarm Intensität",
          "Choose between different alarm intensities of the device":
            "Wähle zwischen den verschiedenen Alarmstufen der Anabox smart",
          Disabled: "Kein Alarm",
          "Light only": "Nur Lichtsignal",
          "Light & Short beeb": "Licht & kurzes Piepsen",
          "Light & Intense beeb": "Licht & lautes Piepsen",
          "Offset time": "Alarmdauer",
          "Show all medications": "Alle Medikamente anzeigen",
          "Select a week": "Wähle eine Woche",
          intakeCountText:
            "Es gibt <1>{{count}} Einnahmenzeiten</1> im ausgewählten Zeitraum.",
          OffsetTimeHelperText:
            "Nach wie vielen Stunden wird der Alarm automatisch deaktiviert?",
          "Some selected dates are in the past":
            "Einige der asgewählter Zeiten liegen in der Vergangenheit",
          "Are you sure you want to continue?": "Möchtest du fortfahren?",
          Hours: "Stunden",
          "Saving...": "Speichern...",
          Saved: "Gespeichert",
          updated: "aktualisiert",
          "was successfully saved.": "wurde erfolgreich gespeichert.",
          Overview: "Übersicht",
          Loading: "Lädt",
          "Loading...": "Lädt...",
          "Creating failed": "Erstellung fehlgeschlagen",
          "Updating failed": "Aktualisierung fehlgeschlagen",
          "Error while creating a new entry.":
            "Fehler beim Erstellen eines neuen Eintrags.",
          "There was an error while updating the entry.":
            "Es gab einen Fehler beim Aktualisieren des Eintrags.",
          "Loading failed": "Laden fehlgeschlagen",
          "There was an error while loading the entry.":
            "Es gab einen Fehler beim Laden des Eintrags.",
          "Error while loading": "Laden fehlgeschlagen",
          "Please check your connection":
            " Bitte prüfe deine Internetverbindung",

          "Loading organizations...": "Lade Organisationen...",
          "Update Account": "Account aktualisieren",
          Refetch: "Neu laden",
          "Sign Up": "Registrieren",
          "Or type code": "Oder Code eingeben",
          "Manage account": "Account verwalten",
          "Scan code": "Code scannen",
          "Processing login": "Login wird verarbeitet",
          "You will be redirected": "Du wirst weitergeleitet",
          "Delete successful": "Erfolgreich gelöscht",
          "The entry was successfully deleted":
            "Der Eintrag wurde erfolgreich gelöscht",
          "Press the side button for 1 second":
            "Drücke jetzt den Knopf an der Seite für 1 Sekunde",
          "Name of the devices user": "Name des Gerätenutzers",
          "Register new device": "Neues Gerät registrieren",
          "Add new analoge device": "Neues analoges Gerät anlegen",
          "Edit device": "Gerät bearbeiten",
          "19-digit code": "19-stelliger Code",
          "Device Id not valid": "Geräte-ID nicht gültig",
          "Calendar is loading...": "Kalender wird geladen...",
          "Waiting for device...": "Warte auf Gerät...",
          TURNANDSCAN:
            "Drehe das Gerät auf die Rückseite,<1/> um den Code zu scannen",
          MAKESUREFULLYCHARGED:
            "Stelle sicher,<1/> dass das Gerät geladen ist.",
          "Go to Device": "Zum Gerät",
          "You pressed the button. Waiting for activation...":
            "Du hast den Knopf gedrückt. Warte einen Moment...",
          "Entire day": "Gesamter Tag",
          "No intakes": "Keine Einnahmen",
          "Scan the code on the case": "Scanne den Code auf dem Gerät",
          "The registration failed": "Die Registrierung ist fehlgeschlagen",
          "Device already existing and registred": "Gerät bereits registriert",
          Intake: "Einnahme",
          "Start date": "Startdatum",
          "Intake time": "Einnahmezeit",
          "Scan packaging/medication plan": "Packung/Medikationsplan scannen",
          "Start again": "Erneut starten",
          "Create your own medications": "Eigenes Medikament anlegen",
          ABDAEXPLAIN:
            "Die App verwendet die ABDA-Datenbank mit über 150.000 Fertigarzneimitteln. Auf dieser Seite können weitere Medikamente angelegt werden.",
          "Additional information": "Zusätzliche Informationen",
          "Dosage form": "Dosierungsform",
          "Please select the dosage form of the medicine":
            "Bitte wähle die Dosierungsform des Medikaments",
          Color: "Farbe",
          "Choosing a color will help you to distinguish the medication":
            "Die Farbe hilft dabei das Medikament zu unterscheiden",
          Manufacturer: "Hersteller",
          "Active ingredients": "Wirkstoffe",
          "Scan from packaging": "Scanne von Packung",
          "no color": "keine Farbe",
          "Select a photo": "Foto auswählen",
          "Get started": "Los geht's!",
          "Create a organization or get invited by another user.":
            "Erstelle eine Organisation oder werde von einem anderen Benutzer eingeladen.",
          "Create patient": "Patient anlegen",
          Birthdate: "Geburtsdatum",
          Timezone: "Zeitzone",
          "Select the timezone where the device is in use":
            "Wähle die Zeitzone in der das Gerät verwendet wird",
          "Unfortunately, no medications match your search":
            "Leider konnte kein Medikament gefunden werden",
          "Add anyway": "Trotzdem hinzufügen",
          Manage: "Verwalten",
          "Device successfully activated": "Gerät erfolgreich aktiviert",
          "You can use the device now.": "Du kannst das Gerät jetzt benutzen.",
          "tablet-PLURAL": "Tabletten",
          "gramm-PLURAL": "Gramm",
          Attention: "Achtung",
          "milliliter-PLURAL": "Mililiter",
          UPDATEINTAKETIMES: "{{count}} Einnahmezeiten werden geändert.",
          "Assign device": "Gerät zuordnen",
          planned: "geplant",
          filled: "befüllt",
          "Start and end date": "Beginn und Ende der Einnahme",
          "Indefinite end": "ohne Enddatum",
          email: "E-Mail",
          "Settings loading...": "Lade Verwaltung...",
          "Enter the email of an user you want to invite.":
            "Gib die E-Mail des Benutzers ein den du einladen möchtest.",
          "No user assigned": "Kein Benutzer zugeordnet",
          "Select a tray": "Wähle ein Fach aus",
          "No button press was recognized. Please start again.":
            "Es wurde kein Knopfdruck erkannt. Bitte erneut starten.",
          Planning: "in Planung",
          "Waiting to join the organization":
            "Wartet darauf der Organisation beizutreten",
          "The user is invited to join the organization, but has not yet accepted to join this organization.":
            "Der Benutzer wurde eingeladen, der Organisation beizutreten, hat jedoch noch nicht akzeptiert, dieser Organisation beizutreten.",
          "Enable debug and developer settings":
            "Debug- und Entwickler-Einstellungen aktivieren",
          Messages: "Infos",
          messages: "Benachrichtigungen",
          "What is the patients name?": "Wie lautet der Name des Patienten?",
          "Error occured": "Es ist ein Fehler aufgetreten",
          "Skip step": "Überspringen",
          "Connect device": "Gerät verbinden",
          "Setup complete": "Einrichtung abgeschlossen",
          "Go to calendar": "Zum Kalender",
          "Connect Anabox Smart": "Anabox Smart verbinden",
          "Switch group": "Gruppe wechseln",
          Hello: "Hallo",
          Edit: "Ändern",
          "The medication data will now be synchronized with the Anabox Smart.":
            "Die Medikationsdaten werden nun mit der Anabox Smart synchronisiert.",
          "Setup completed. You can setup a patient and a device later.":
            "Einrichtung abgeschlossen. Du kannst einen Patienten und ein Gerät später einrichten.",
          "Account Settings": "Kontoeinstellungen",
          German: "Deutsch",
          English: "Englisch",
          "Developer Settings": "Entwickler-Einstellungen",
          "API version": "API-Version",
          "Override the API endpoint": "API-Endpunkt überschreiben",
          Standard: "Standard",
          Development: "Entwicklung",
          Production: "Produktion",
          "Send push notification": "Push-Benachrichtigungen senden",
          "filling aid": "Befüllungshilfe",
          Datum: "Datum",
          Time: "Zeit",
          Event: "Ereignis",
          "All alarms are disabled": "alle Alarme sind deaktiviert",
          "The tray to be taken is flashing without any sound":
            "einzunehmende Fach blink ohne Ton",
          "The tray to be taken is flashing with a short beeb sound":
            "einzunehmende Fach blink mit einem kurzen Ton",
          "The tray to be taken is flashing with a long and loud sound":
            "einzunehmende Fach blink mit langen und lauten Ton",
          "More Information...": "Weitere Informationen...",
          "empty stomach": "Vormedizin (nüchtern)",
          "(empty stomach)": "(nüchtern)",
          "cm-PLURAL": "Zentimeter",
          "Fl-PLURAL": "Flaschen",
          "g-PLURAL": "Gramm",
          "IE-PLURAL": "Internationale Einheiten",
          "kg-PLURAL": "Kilogramm",
          "l-PLURAL": "Liter",
          "m-PLURAL": "Meter",
          "mg-PLURAL": "Milligramm",
          "ml-PLURAL": "Milliliter",
          "mm-PLURAL": "Millimeter",
          "P-PLURAL": "Packungen",
          "Sp-PLURAL": "Sprühstöße",
          "St-PLURAL": "Stück",
          "µg-PLURAL": "Mikrogramm",
          "Change time": "Zeit ändern",
          Unit: "Einheit",
          "Medication filled": "Medikament befüllt",
          "Medication not filled": "Medikament nicht befüllt",
          "Die Befüllung wurde abgeschlossen.":
            "Die Befüllung wurde abgeschlossen.",
          "Filling aid finished": "Befüllungshilfe abgeschlossen",
        },
      },
    },
    fallbackLng: "en",
    //debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
