import { emptySplitApi } from "../ducks/emptyApi";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

export function generateCrudApi({ name, endpoints = () => {} }) {
  const myNameCapitialized = capitalizeFirstLetter(name);
  const api = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
      ...endpoints(builder),
      [`getAll${myNameCapitialized}`]: builder.query({
        query: (organizationId) => ({
          url: `${name}`,
          method: "get",
          params: { organization: organizationId },
        }),
        transformResponse: (response, meta, arg) => response.results,
        providesTags: (result) => {
          const output = result
            ? [
                ...result.map(({ id }) => ({ type: name, id })),
                { type: name, id: name + "LIST" },
              ]
            : [{ type: name, id: name + "LIST" }];

          return output;
        },
      }),
      [`search${myNameCapitialized}`]: builder.query({
        query: (params) => ({
          url: `${name}`,
          method: "get",
          params: { ...params },
        }),
        transformResponse: (response, meta, arg) => response.results,
        providesTags: (result) => {
          const output = result
            ? [
                ...result.map(({ id }) => ({ type: name, id })),
                { type: name, id: name + "SEARCH" },
              ]
            : [{ type: name, id: name + "SEARCH" }];

          return output;
        },
      }),
      [`createSingle${myNameCapitialized}`]: builder.mutation({
        query: (initialPost) => ({
          url: `${name}`,
          body: initialPost.values,
          method: "post",
        }),
        invalidatesTags: [
          { type: name, id: name + "LIST" },
          { type: name, id: name + "SEARCH" },
        ],
      }),
      [`getSingle${myNameCapitialized}`]: builder.query({
        query: (request) => ({
          url: `${name}/${request}`,
          method: "get",
        }),
        providesTags: (result, error, id) => [{ type: name, id }],
      }),

      [`updateSingle${myNameCapitialized}`]: builder.mutation({
        query: (initialPost) => ({
          url: `${name}/${initialPost.id}`,
          body: initialPost.values,
          method: "post",
          //params: { postID: initialPost.postId },
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: name, id },
          "calendars",

          { type: name, id: name + "SEARCH" },
          { type: "calendars", id: "calendars" + "SEARCH" },
        ],
      }),
      [`deleteSingle${myNameCapitialized}`]: builder.mutation({
        query: (initialPost) => ({
          url: `${name}/${initialPost.id}`,
          method: "DELETE",
        }),
        invalidatesTags: (result, error, entry) => {
          return [
            { type: name, id: name + "LIST" },
            { type: name, id: name + "SEARCH" },
          ];
          //return [{ type: name, id: entry.id }];
        },
      }),
    }),
  });
  return { ...api, name };
}
