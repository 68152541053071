import { messagesApi } from "ducks/messagesApi";
import React from "react";
import styles from "./notification.module.scss";

export default function Notification() {
  const { data } = messagesApi.useGetAllMessagesQuery();
  if (!data) return null;
  return (
    <div className={styles.notification}>
      {data.filter((e) => e.status !== "read").length}
    </div>
  );
}
