import React from "react";
import { Button, BlockNotification, tooltipStyle } from "@un/react";
import ButtonRouter from "components/ButtonRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { Trans } from "react-i18next";
import { useActiveUserDevice } from "helpers/useUsers";
import Battery, { useBatteryStatus } from "components/Battery";
import styles from "./noDeviceAsssigned.module.scss";

export default function NoDeviceAssigned() {
  const currentUserDevices = useActiveUserDevice();

  const batteryStatus = useBatteryStatus({
    deviceClass: "memo",
    level: currentUserDevices.data?.[0]?.deviceStatus?.batLevel,
  });

  console.log("currentUserDevices", currentUserDevices.data);
  if (currentUserDevices.data && currentUserDevices.data.length === 0)
    return (
      <BlockNotification
        kind="warning"
        title={<Trans>No device assigned</Trans>}
        block
        className={styles.blockNotification}
        actions={
          <ButtonRouter
            notification
            withOrganization
            isLink
            to={`/devices/new`}
            icon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            <Trans>Add device</Trans>
          </ButtonRouter> /*<NotificationActionButton>Action</NotificationActionButton>*/
        }
        iconDescription="describes the close button"
        lowContrast
        statusIconDescription="describes the status icon"
        subtitle={<Trans>You need to add a device to the user</Trans>}
        hideCloseButton
      ></BlockNotification>
    );

  if (
    currentUserDevices.data?.[0]?.deviceStatus &&
    batteryStatus.color !== "green"
  ) {
    return (
      <BlockNotification
        className={styles.blockNotification}
        kind="warning"
        title={<Trans>Battery low</Trans>}
        actions={
          <Battery
            level={currentUserDevices?.deviceStatus?.batLevel}
            style={{ width: "30px" }}
            className={styles.battery}
          />
        }
        iconDescription="describes the close button"
        lowContrast
        statusIconDescription="describes the status icon"
        subtitle={<Trans>Please charge the device</Trans>}
        hideCloseButton
      />
    );
  }
  return null;
}
