import PropTypes from "prop-types";
import React, { useEffect } from "react";
import clsx from "clsx";
import { navigate } from "react-big-calendar/lib/utils/constants";
import { Button } from "@un/react";

import styles from "./toolbar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faChevronRight,
  faPlusCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { Trans } from "react-i18next";
import useIsDesktop from "helpers/useIsDesktop";
import calendarList from "helpers/calendarList";

function Toolbar(props) {
  let {
    localizer: { messages },
    label,
  } = props;

  const isDesktop = useIsDesktop();

  const changeNavigate = (action) => {
    props.onNavigate(action);
  };

  const changeView = (view) => {
    props.onView(view);
  };

  const viewNamesGroup = (messages) => {
    let viewNames = props.views;
    const view = props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <Button
          type="button"
          kind="tertiary"
          key={name}
          className={clsx({ "rbc-active": view === name })}
          onClick={() => changeView(name)}
        >
          {isDesktop ? (
            <Trans>{messages[name]}</Trans>
          ) : (
            <div>
              <FontAwesomeIcon icon={calendarList[name].icon} />
            </div>
          )}
        </Button>
      ));
    }
  };
  return (
    <div className={styles.toolbar}>
      <span className="rbc-btn-group">
        <Button
          type="button"
          kind="tertiary"
          onClick={() => changeNavigate(navigate.TODAY)}
        >
          <Trans>{messages.today}</Trans>
        </Button>
        <Button
          type="button"
          kind="tertiary"
          className={styles.toolbarButton}
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={() => changeNavigate(navigate.PREVIOUS)}
          content={isDesktop ? <Trans>{messages.previous}</Trans> : undefined}
        />
        <Button
          type="button"
          kind="tertiary"
          className={styles.toolbarButton}
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => changeNavigate(navigate.NEXT)}
          content={isDesktop ? <Trans>{messages.next}</Trans> : undefined}
        />
      </span>

      <span className="rbc-toolbar-label">{label}</span>

      <span className="rbc-btn-group">{viewNamesGroup(messages)}</span>
    </div>
  );
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
