import React, { useState } from "react";
import { components } from "react-select";
import Select from "react-select";

import { Button, InlineLoading, Input } from "@un/react";
import lodash from "lodash";
import styles from "./styles.module.scss";
import classnames from "classnames";
import ButtonRouter from "components/ButtonRouter";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import useMedications from "helpers/useMedications";
import { Trans } from "react-i18next";
import ReactJson from "react-json-view";
import ImportMedicationPlan from "components/CalendarForm/ImportMedicationPlan";
import ScanButton from "components/Scanner/ScanButton";
import InlineLoadingLarge from "components/InlineLoadingLarge";
import { medicationsApi } from "ducks/medicationsApi";
import { useDispatch } from "react-redux";
import { abdasApi } from "ducks/abdasApi";

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className={styles.singleValueTitle}>
        {props.data?.Kurzname ? props.data?.Kurzname : props.data?.meta?.name}
      </div>
      <div className={styles.singleValueSubTitle}>
        {props.data?.manufacturer?.Firmenname
          ? props.data?.manufacturer?.Firmenname
          : props.data?.meta?.manufacturer}
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={styles.singleValueTitle}>
        {props.data?.Kurzname ? props.data?.Kurzname : props.data?.meta?.name}
      </div>
      <div className={styles.singleValueSubTitle}>
        {props.data?.manufacturer?.Firmenname
          ? props.data?.manufacturer?.Firmenname
          : props.data?.meta?.manufacturer}
      </div>
    </components.Option>
  );
};

const MenuList = (props) => {
  const { organization } = useParams();
  console.log("prospsps", props.selectProps.inputValue, props);

  return <components.MenuList {...props}>{props.children}</components.MenuList>;
};

export default function MedicationSelect(props) {
  const {
    className,
    field,
    onChange,
    labelText,
    medicationData,
    localMedicationData,
    entryData,
    ref,
  } = props;
  //const medicationData = entryData.medicationData;
  console.log("entryData", entryData);

  const [trigger, result, lastPromiseInfo] = abdasApi.useLazySearchAbdasQuery();

  const [value, setValue] = useState(undefined);
  const [inputValue, setInputValue] = useState(undefined);

  const medicationsList = useMedications();

  useEffect(() => {
    console.log("nneewwqwqe");
    setValue(medicationData || localMedicationData);
  }, [medicationData, localMedicationData]);

  const invalid =
    field && field.errorsRef && lodash.get(field.errorsRef.current, props.name);

  const classes = classnames(`wfp--form-item commodity-select`, className, {
    [`wfp--text-input--invalid`]: invalid,
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      minHeight: 48,
    }),
  };

  const LoadingMessage = (props) => {
    return (
      <InlineLoadingLarge
        className={styles.loading}
        description={<Trans>Loading...</Trans>}
      />
    );
  };

  const NoOptionsMessage = (props) => {
    const [createSingle, createSingleResult] =
      medicationsApi.useCreateSingleMedicationsMutation();

    const text = props.selectProps.inputValue
      ? "Unfortunately, no medications match your search"
      : "Start typing to search...";
    const { organization } = useParams();
    if (result.isFetching) return <LoadingMessage />;
    return (
      <>
        <div className={styles.noOptionsMessage}>
          <div className={styles.noOptionsMessageTitle}>
            <Trans>{text}</Trans>
          </div>
        </div>
        <div className={styles.menuAdditions}>
          <Button
            className={styles.manage}
            kind="tertiary"
            onClick={() =>
              createSingle({
                values: {
                  meta: { name: props.selectProps.inputValue },
                  organization: organization,
                },
              })
            }
          >
            <Trans>Add anyway</Trans>
          </Button>
        </div>
      </>
    );
  };

  const LoadingIndicator = (props: LoadingIndicatorProps<ColourOption>) => {
    return (
      <>
        <InlineLoading />
        dassdd
      </>
    );
  };

  const medicationListFiltered = inputValue
    ? medicationsList.data.filter((e) =>
        e.meta.name.toLowerCase().includes(inputValue.toLowerCase())
      )
    : medicationsList.data
    ? medicationsList.data
    : [];

  const res = inputValue && result.data ? result.data : [];

  const options = [...medicationListFiltered, ...res];
  console.log(result);

  return (
    <Input
      formItemClassName={classes}
      invalid={invalid}
      invalidText={invalid && invalid.message}
      labelText={labelText}
    >
      {() => (
        <div className={styles.input}>
          {/*<ReactJson src={result} />*/}
          <Select
            styles={customStyles}
            value={value}
            options={options}
            inputRef={ref}
            getOptionLabel={(option) =>
              option.Kurzname ? option.Kurzname : option.id
            }
            getOptionValue={(option) =>
              option.Kurzname ? option.Kurzname : option.id
            }
            className={`${styles.select} wfp--react-select-container`}
            classNamePrefix="wfp--react-select"
            isLoading={result.isFetching}
            components={{
              NoOptionsMessage,
              MenuList,
              SingleValue,
              LoadingMessage,
              Option,
            }}
            onInputChange={(e) => {
              setInputValue(e);
              console.log("sasasdssa", e);
              trigger({ search: e, limit: 10 });
            }}
            export
            filterOption={(option, inputValue) => option}
            placeholder={i18next.t("Select a medication...")}
            defaultOptions
            //cacheOptions
            //menuIsOpen
            onChange={(selected) => {
              console.log("selsadasec", selected);
              onChange(selected);
            }}
          />
          <ScanButton
            autoSubmit
            buttonClassName={styles.scanButton}
            buttonText={<Trans>Scan</Trans>}
            setValue={(e) => {
              setInputValue(e);
              trigger({ search: e, limit: 10 });
            }}
          />
        </div>
      )}
    </Input>
  );
}
