import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "fills",
  initialState: { current: undefined, startDate: undefined, fills: [] },
  reducers: {
    setCurrentMedication: (state, action) => ({
      ...state,
      current: action.payload,
    }),
    setCurrentDate: (state, action) => ({
      ...state,
      startDate: action.payload,
    }),
    setFilledFills: (state, action) => ({
      ...state,
      fills: { ...state.fills, ...action.payload },
    }),
  },
});

export const getCurrentMedication = (state) => state.fills.current;
export const getCurrentFill = (state) => state.fills;
