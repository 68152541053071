import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import { Button, Item } from "@un/react";
import { messagesApi } from "ducks/messagesApi";
import { notificationsApi } from "ducks/notificationsApi";
import React from "react";
import ReactJson from "react-json-view";
import { usePushContext } from "helpers/notifications/usePush";

export default function NotificationsList() {
  const notifications = notificationsApi.useGetAllNotificationsQuery();
  const messages = messagesApi.useGetAllNotificationsQuery();

  const pushContext = usePushContext();
  return (
    <SettingsContentWrapper title="Notifications Debug">
      <h4>Push Context</h4>
      {pushContext && (
        <>
          <ReactJson src={pushContext} collapsed />
          <Button onClick={pushContext.register}>
            Register push notifications
          </Button>

          <Button onClick={pushContext.getFcmToken}>Get FcmToken</Button>

          {pushContext.fcmToken?.token}
        </>
      )}

      <h4>Messages</h4>

      {messages.data?.results &&
        messages.data.results.map((e) => (
          <Item
            title={<>Entry {e.kind}</>}
            kind="horizontal"
            wrapper="repeater"
            additional={<>{e.original.createdAt}</>}
          >
            <ReactJson src={e} collapsed />
          </Item>
        ))}
      <ReactJson src={messages} />
      <h4>Notifications</h4>
      <ReactJson src={notifications} />
      <h4>Push Context</h4>
      <ReactJson src={pushContext} collapsed />
    </SettingsContentWrapper>
  );
}
