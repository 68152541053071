import { calendarsApi } from "ducks/calendars";
import { useSelector } from "react-redux";
import useDayTimeList from "../useDayTimeList";
import { areIntervalsOverlapping } from "date-fns";

import { useParams } from "react-router-dom";

/* All baked Events and all baked events reduced to there respective frames */

export default function useBakedEvents() {
  const params = useParams();
  const { data = [] } = calendarsApi.useSearchCalendarsQuery({
    patient: params.entry,
  });
  return isBakedEvents(data);
}

export function isBakedEvents(events) {
  const eventsBaked = events.filter((e) => e.bake === true);
  const eventsBakedReducedObject = [];
  eventsBaked.forEach(
    (e) => (eventsBakedReducedObject[`${e.from}-${e.to}`] = e)
  );
  const eventsBakedReduced = Object.values(eventsBakedReducedObject);
  return { eventsBaked, eventsBakedReduced };
}

/* Check if a given date is within the given baked events */
export function isBakedTime({ eventsBakedReduced = [], from, to }) {
  var timeIsBaked = false;
  var bakedEvents = [];

  eventsBakedReduced.forEach((f) => {
    if (
      areIntervalsOverlapping(
        { start: from, end: to || from },
        { start: new Date(f.from), end: new Date(f.to) }
      )
    ) {
      timeIsBaked = true;

      bakedEvents.push(f);
    }
  });

  return { timeIsBaked, bakedEvents };
}

//Max containing periods for specific time
export function isBakedPeriodsInFrameMaxBoundariesOnly({
  eventsBakedReduced,
  from,
  to,
}) {
  const { bakedEvents } = isBakedTime({
    eventsBakedReduced,
    from,
    to,
  });

  if (bakedEvents.length === 0) return null;
  const fromBoundaries = bakedEvents.reduce(function (a, b) {
    return a.from < b.from ? a.from : b.from;
  });
  const toBoundaries = bakedEvents.reduce(function (a, b) {
    return a.to > b.to ? a.to : b.to;
  });

  return { from: fromBoundaries.from, to: toBoundaries.to };
}

export function useIsBaked({ from, to }) {
  const { eventsBakedReduced } = useBakedEvents();
  return isBakedTime({ eventsBakedReduced, from, to });
}

const useFutureEvents = () => {
  const params = useParams();
  const { data = [] } = calendarsApi.useSearchCalendarsQuery({
    patient: params.entry,
  });
  const futureEvents = data.filter((e) => e.bake !== true);
  return futureEvents;
};

export const useTrays = () => {
  const dayTimeList = useDayTimeList();
  const futureEvents = useFutureEvents();
  return { dayTimeList, futureEvents };
};
