import React from "react";
import { Input } from "@un/react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import { useController } from "react-hook-form";
import { Trans } from "react-i18next";
import { Button } from "@un/react";

export default function TimeInputWithOverride(props) {
  const { control, entryData, defaultTime, name } = props;
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    //rules: { required: true },
  });

  const privateTime = true;

  return (
    <Input
      type="time"
      {...props}
      //value={value}
      formItemClassName={styles.dateInput}
      //addonAfter={<FontAwesomeIcon icon={faClock} />}
    >
      {(props) => {
        const { ...other } = props;
        return (
          <>
            <div className={styles.dateInputWrapper}>
              {(value === "" || value === undefined) &&
              privateTime === false ? (
                <>
                  <div className={styles.dateWrapper}>{defaultTime}</div>
                  <Button
                    kind="tertiary"
                    onClick={() => onChange(() => onChange(defaultTime))}
                  >
                    <Trans>Custom</Trans>
                  </Button>
                </>
              ) : (
                <>
                  <div className={styles.input}>
                    <FontAwesomeIcon icon={faClock} className={styles.icon} />
                    <input
                      type="time"
                      name={name}
                      {...other}
                      value={value}
                      onChange={onChange}
                    />
                  </div>
                  {!privateTime && (
                    <Button
                      kind="tertiary"
                      onClick={() => onChange(() => onChange(""))}
                    >
                      <Trans>Reset</Trans>
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        );
      }}
    </Input>
  );
}
