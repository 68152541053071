import React, { useState } from "react";
import { InlineLoading, Item } from "@un/react";
import eventList, { EventListContent } from "helpers/events";
import moment from "moment";
import ReactJson from "react-json-view";
import { iotDevicesApi } from "ducks/iotDevicesApi";
import { addDays, formatISO, subDays } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker";
import DateRangePicker from "components/WeekPicker/DateRangePicker";

export default function MessageLog({ id }) {
  /*const [dateRange, setDateRange] = useState([
    formatISO(subDays(new Date(), 30), formatISO(addDays(new Date(), 30))),
  ]);*/

  const [startDate, setStartDate] = useState(subDays(new Date(), 14));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));

  const { data, isFetching } = iotDevicesApi.useGetEventsQuery({
    id: id,
    params: {
      DateStart: formatISO(startDate),
      DateEnd: formatISO(endDate),
    },
  });

  return (
    <>
      <h3>Message log</h3>
      <>
        <DateRangePicker
          startDate={startDate}
          onChangeStart={(date) => setStartDate(date)}
          onChangeEnd={(date) => setEndDate(date)}
          endDate={endDate}
        />
        <br /> <br />
      </>
      <div>
        {isFetching ? (
          <InlineLoading description="Message log loading..." />
        ) : data?.message ? (
          data.message.map((e) => (
            <Item
              //className={styles.item}
              kind="horizontal"
              wrapper="repeater"
              additional={moment(e.EventTimestamp).format("DD.MM.YYYY HH:mm")}
              title={eventList[e.EventType]?.name}
            >
              <EventListContent event={e} />
              <ReactJson collapsed src={e} />
            </Item>
          ))
        ) : (
          <>Nothing Found</>
        )}
      </div>
    </>
  );
}
