import React from "react";
import Avatar from "components/Avatar";
import useSettingsOverview from "helpers/useSettingsOverviewNew";
import { Trans } from "react-i18next";
import SettingsSidebar from "components/Settings/SettingsWithSidebar";
import { Item } from "@un/react";
import SettingsStockDetail from "./SettingsStockDetail";
import { UserName } from "components/UserName";
import { stocksApi } from "ducks/stocksApi";

export default function SettingsPatients() {
  const settingsOverview = useSettingsOverview({
    name: "stocks",
    titleKey: "lastName",
    api: stocksApi,
  });

  return (
    <SettingsSidebar
      settingsOverview={settingsOverview}
      details={<SettingsStockDetail />}
      item={({ e, ...other }) => (
        <Item
          kind="horizontal"
          wrapper="repeater"
          title={
            e.productCode ? (
              e.productCode
            ) : (
              <>
                <Trans>Unnamed</Trans> <Trans>{e.kind}</Trans>
              </>
            )
          }
          {...other}
          subContent={e.patient && <UserName id={e.patient} />}
          image={<Avatar kind="medium" user={e} />}
        ></Item>
      )}
    ></SettingsSidebar>
  );
}
