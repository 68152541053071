import React from "react";
import useSettingsOverview from "helpers/useSettingsOverviewNew";
import SettingsSidebar from "components/Settings/SettingsWithSidebar";
import { Item } from "@un/react";
import SettingsNotificationDetail from "./SettingsMessagesDetail";
import NotificationIcon from "components/NotificationIcon";
import { notificationsApi } from "ducks/notificationsApi";
import { useParams } from "react-router-dom";
import NotificationsList from "./MessagesList";
import ButtonRouter from "components/ButtonRouter";
import { messagesApi } from "ducks/messagesApi";
import messageKinds, { messageKindSelect } from "./messageList";
import { Trans } from "react-i18next";
import { formatDistance } from "date-fns";
import { de } from "date-fns/locale";

const SettingsNotificationsItem = ({ e, ...other }) => {
  const messageKind = messageKindSelect(e);
  return (
    <Item
      title={<>{messageKind.title}</>}
      {...other}
      additional={
        <div>
          {formatDistance(new Date(e.createdAt), new Date(), {
            addSuffix: true,
            locale: de,
          })}
        </div>
      }
      subContent={<>{messageKind.detail}</>}
      image={
        <NotificationIcon kind="medium" condition={e.kind} status={e.status} />
      }
    ></Item>
  );
};

export default function SettingsMessages() {
  const settingsOverview = useSettingsOverview({
    name: "messages",
    titleKey: "lastName",
    api: messagesApi,
  });
  const params = useParams();

  return (
    <SettingsSidebar
      customButtons={
        <ButtonRouter>
          <Trans>Edit</Trans>
        </ButtonRouter>
      }
      search={<></>}
      /*search={
        <ButtonRouter withOrganization to={`/notifications/list`}>
          Notifications
        </ButtonRouter>
      }*/
      settingsOverview={settingsOverview}
      details={
        params.entry === "list" ? (
          <NotificationsList />
        ) : (
          <SettingsNotificationDetail />
        )
      }
      item={SettingsNotificationsItem}
    ></SettingsSidebar>
  );
}
