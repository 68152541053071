import { SettingsSidebarNoSidebar } from "components/Settings/SettingsWithSidebar";
import React from "react";
import { Story } from "@un/react";
import styles from "./styles.module.scss";
//import Content from "./content.mdx";
//import { MDXProvider } from "@mdx-js/react";

export default function SettingsHelp() {
  /*const components = {
    em: (props) => <i {...props} />,
  };*/

  return (
    <SettingsSidebarNoSidebar
      fullHeight
      fullWidth
      title="Imprint"
      hideTitle
      hideHeaderRight
    >
      <iframe
        title="Imprint"
        src={`${process.env.REACT_APP_SERVER_WEBSITE_URL}/blog/imprint/?app=true`}
        className={styles.iframe}
      />
    </SettingsSidebarNoSidebar>
  );
}
