import React from "react";
import { Input } from "@un/react";
import styles from "./multiCheckboxWrapper.module.scss";
import classnames from "classnames";

export default function MultiCheckboxWrapper({
  children,
  className,
  mobile,
  kind,
  ...other
}) {
  const classes = classnames(
    {
      [styles.vertical]: kind === "vertical",
      [styles.mobileVertical]: mobile === "vertical",
    },
    styles.inputGroup,
    className
  );

  return (
    <Input {...other}>{() => <div className={classes}>{children}</div>}</Input>
  );
}
