import React from "react";
import { Trans } from "react-i18next";
import {
  BlockNotification,
  InlineLoading,
  NotificationActionButton,
} from "@un/react";
import ReactJson from "react-json-view";
import { useDebug } from "helpers/useCurrentUser";

export default function Status({
  children,
  query,
  validate,
  fetching,
  loading,
  notSuccessContent,
  showContent = false,
}) {
  const isDebug = useDebug();
  if (!query) return null;
  const { isFetching, isError, isLoading, isSuccess } = query;

  const success = validate ? validate(query) : isSuccess;

  const info =
    isLoading && loading !== undefined ? (
      loading
    ) : isFetching && fetching !== undefined ? (
      fetching
    ) : isFetching ? (
      <InlineLoading description={<Trans>Loading...</Trans>} />
    ) : isError ? (
      <BlockNotification
        kind="warning"
        title={<Trans>Error while loading</Trans>}
        subtitle={
          <>
            <Trans>Please check your connection</Trans>
            {isDebug && <ReactJson src={query} collapsed />}
          </>
        }
        actions={
          <NotificationActionButton onClick={query.refetch}>
            <Trans>Refetch</Trans>
          </NotificationActionButton>
        }
      />
    ) : null;

  return (
    <>
      {info}
      {isSuccess && !success && notSuccessContent}
      {(showContent || success) && children}
    </>
  );
}
