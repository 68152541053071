import React, { useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";

import styles from "./styles.module.scss";

import { Select } from "@un/react";
import prepareScan from "./prepareScan";
import classNames from "classnames";
import Empty from "components/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/pro-light-svg-icons";
import { isMobile, isTablet } from "react-device-detect";
import { Trans } from "react-i18next";

export default function Scanner({ closeModal, onChange, open }) {
  const [points, setPoints] = useState();
  const mobile = isMobile || isTablet;
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [scan, setScan] = useState();
  const [controls, setControls] = useState();

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [scannerDimensions, setScannerDimensions] = useState({
    width: 640 * 2, //Math.min(window.innerWidth, 640),
    height: 480 * 2,
  });

  const codeReader = new BrowserMultiFormatReader();

  const fixOrientation = function (width, height) {
    const d = {
      width: width,
      height: height,
    };

    if (mobile) {
      if (window.matchMedia("(orientation:portrait)").matches) {
        //if (md.userAgent() !== "Safari") {
        d.width = height;
        d.height = width;
        //}
      }
    }
    return d;
  };

  useEffect(() => {
    if (open === true) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        startReader();
      } else {
        alert("getUserMedia() is not supported by your browser");
      }
    } else if (open === false) {
      stopReader(true);
    }
  }, [open]);

  async function stopReader(close) {
    if (controls) controls.stop();
    if (close) closeModal();
    return true;
  }

  async function startReader(videoId) {
    setPoints();
    setScan();
    onChange();

    const devices = await navigator.mediaDevices.enumerateDevices();

    console.log("devices", devices);
    setVideoInputDevices(devices.filter((d) => d.kind === "videoinput"));
    canvasRef.current
      .getContext("2d")
      .clearRect(0, 0, scannerDimensions.width, scannerDimensions.height);

    const currentDeviceData = await streamVideo(videoId);

    await decodeNow(currentDeviceData);
  }

  async function streamVideo(videoId) {
    const size = {
      //width: 1920,
      width: 640 * 2,
      height: 480 * 2,
      facingMode: "environment",
    };
    const calculatedConstraints = videoId
      ? { video: { deviceId: { exact: videoId }, ...size } }
      : { video: size };

    return calculatedConstraints;
  }

  function changeDevice(e) {
    const videoId = e.target.value;
    stopReader();
    startReader(videoId);
  }

  async function decodeNow(currentDeviceData) {
    const videoElement = document.querySelector("#video");
    const result = await codeReader.decodeFromConstraints(
      currentDeviceData,
      videoElement,
      (result, error, controls) => {
        if (result) {
          console.log("Code scan results", result);
          canvasRef.current
            .getContext("2d")
            .drawImage(
              videoRef.current,
              0,
              0,
              currentDeviceData.video.width,
              currentDeviceData.video.height
            );

          const data = prepareScan(result?.text);

          setPoints(result.resultPoints);
          stopReader();

          onChange(data);
        }
      }
    );
    setControls(result);
  }

  var path = [];
  if (points)
    points.forEach((e) => {
      path.push(`${Math.round(e.x)} ${Math.round(e.y)}`);
    });

  const classes = classNames(
    {
      [styles.isScanned]: scan,
      [styles.isEnvironment]:
        mobile || scannerDimensions?.facingMode === "environment", // TODO: improve mirror handler
    },
    styles.scanner
  );

  return (
    <div className={classes}>
      <div className={styles.videoel}>
        <div className={styles.toolbar}>
          {videoInputDevices.length > 1 &&
            !scannerDimensions?.facingMode === "environment" && (
              <Select
                onChange={changeDevice}
                className={styles.select}
                value={scannerDimensions.deviceId}
              >
                {videoInputDevices.map((e) => (
                  <option value={e.deviceId}>{e.label}</option>
                ))}
              </Select>
            )}
        </div>

        <div className={styles.videoScale}>
          <div className={styles.videoWrapper}>
            <video
              id="video"
              width={scannerDimensions.width}
              height={scannerDimensions.height}
              playsinline
              muted
              className={styles.video}
              ref={videoRef}
            />
            <canvas
              id="canvas"
              class={styles.canvas}
              width={scannerDimensions.width}
              height={scannerDimensions.height}
              ref={canvasRef}
            ></canvas>
            <svg
              width={scannerDimensions.width}
              height={scannerDimensions.height}
              viewBox={`0 0 ${scannerDimensions.width} ${scannerDimensions.height}`}
              className={styles.points}
            >
              <polygon
                points={path.join(" ")}
                className={styles.pointsPolygon}
              />
            </svg>
          </div>
        </div>
      </div>

      {!scan && (
        <Empty
          className={styles.empty}
          kind="large"
          icon={<FontAwesomeIcon icon={faQrcode} />}
        >
          <Trans>Scan the code on the case</Trans>
        </Empty>
      )}
      {/*<div className={styles.results}>
        <pre>{scan && JSON.stringify(scan, null, " ")}</pre>
      </div>*/}
    </div>
  );
}
