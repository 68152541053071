import React, { useEffect } from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";

import LoginWrapper, { LoginWrapperTitle } from "components/AuthWrapper";
import styles from "./patient.module.scss";
import hospitalIllustration from "./patient.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { Modal, TextInput } from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";

import { Trans } from "react-i18next";
import ButtonRouter from "components/ButtonRouter";
import i18next from "i18next";
import { organizationsApi } from "ducks/organizationsApi";
import FormRow from "components/FormRow";
import { Col } from "react-flexbox-grid";
import SubmitWrapper from "components/SubmitWrapper";
import { usersApi } from "ducks/usersApi";

export default function SelectOrganization() {
  const prepareSubmit = (values) => {
    const { action, ...other } = values;
    return { ...other, role: "patient" };
  };
  const store = useSettingsForm({
    api: usersApi,
    url: `/createOrganization`,
    hideTitle: true,
    hideHeaderRight: true,
    prepareSubmit,
    preventRedirect: true,
    newEntryData: { action: "private" },
  });

  const {
    register,
    handleSubmit,
    onSubmit,
    resultCreateSingle,
    regularFormStyle,
    form,
  } = store;

  const {
    watch,
    formState: { errors },
  } = form;

  const history = useHistory();
  const { organization } = useParams();

  useEffect(() => {
    if (resultCreateSingle.data) {
      history.push(
        `/${organization}/onboarding/device?user=${resultCreateSingle.data.id}`
      );
    }
  }, [resultCreateSingle.isSuccess]);

  return (
    <LoginWrapper
      rightSide={
        <img
          alt="Illustration of a hospital"
          className={styles.image}
          src={hospitalIllustration}
        />
      }
    >
      <LoginWrapperTitle kind="small">
        <Trans>What is the patients name?</Trans>
      </LoginWrapperTitle>

      <div className={regularFormStyle}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <Col xs={12} md={6}>
              <TextInput
                labelText={<Trans>First name</Trans>}
                {...register("meta.firstName")}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextInput
                labelText={<Trans>Last name</Trans>}
                {...register("meta.lastName")}
              />
            </Col>
          </FormRow>

          <SubmitWrapper>
            <ButtonRouter
              isPlain
              withOrganization
              to={`/onboarding/success?skip=true`}
            >
              <Trans>Skip step</Trans>
            </ButtonRouter>
            <SettingsSubmitButton
              large
              {...store}
              wrapper="inline"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              title={<Trans>Continue</Trans>}
            />
          </SubmitWrapper>
        </form>
      </div>
    </LoginWrapper>
  );
}
