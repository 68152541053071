import React, { useEffect, useState } from "react";

import gs1js from "gs1js";
import styles from "./styles.module.scss";
import convert from "xml-js";
import moment from "moment";
import axios from "axios";

const constraints = {
  video: true,
};

export default function prepareScan(text) {
  if (!text) return;
  var myReader = new gs1js.GS1Reader(text);
  const applicationIdentifiers = myReader.getApplicationIdentifiers();

  console.log("applicationIdentifiers", applicationIdentifiers);

  let data;

  if (text.startsWith("<MP ")) {
    const fhirContent = axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}ukfFhirConverter`,
      {
        data: text,
      }
    );

    console.log("text", text);
    return fhirContent.data;
  }

  /* 
  <MP v="025" U="02BD2867FB024401A590D59D94E1FFAE" l="de-DE">
    <P g="Jürgen" f="Wernersen" b="19400324"/>
    <A n="Praxis Dr. Michael Müller" s="Schloßstr. 22" z="10555" c="Berlin" p="030-1234567" e="dr.mueller@kbv-net.de" t="2018-07-01T12:00:00"/>
    <S>
      <M p="230272" m="1" du="1" r="Herz/Blutdruck"/>
      <M p="2223945" m="1" du="1" r="Blutdruck"/>
      <M p="558736" m="20" v="20" du="p" i="Wechseln der Injektionsstellen, unmittelbar vor einer Mahlzeit spritzen" r="Diabetes"/>
      <M p="9900751" v="1" du="1" r="Blutfette"/></S><S t="zu besonderen Zeiten anzuwendende Medikamente">
      <M p="2239828" t="alle drei Tage 1" du="1" i="auf wechselnde Stellen aufkleben" r="Schmerzen"/>
    </S>
    <S c="418">
      <M p="2455874" m="1" du="1" r="Stimmung"/>
    </S>
  </MP>
  
  SecurPharm
  [
    { identifier: "01", value: "04150009395105", length: 14 },
    { identifier: "21", value: "10759959563490", length: 14 },
    { identifier: "17", value: "221031", length: 6 },
    { identifier: "10", value: "KK8545", length: 6 },
  ]*/

  // App QR-Code
  else if (applicationIdentifiers.find((e) => e.identifier === "9N")) {
    return applicationIdentifiers.find((e) => e.identifier === "9N").value;
  } else if (applicationIdentifiers.find((e) => e.identifier === "21")) {
    data = applicationIdentifiers.find((e) => e.identifier === "21")?.value;
  } else if (
    applicationIdentifiers &&
    applicationIdentifiers.length === 4 &&
    applicationIdentifiers.find((e) => e.identifier === "01")
  ) {
    data = {
      productCode: applicationIdentifiers.find((e) => e.identifier === "01")
        .value,
      serialNumber: applicationIdentifiers.find((e) => e.identifier === "21")
        .value,
      expDate: moment(
        "20" + applicationIdentifiers.find((e) => e.identifier === "17").value
      ).format("YYYY-MM-DD"),
      batchNumber: applicationIdentifiers.find((e) => e.identifier === "10")
        .value,
    };
  } else if (
    //PPN
    applicationIdentifiers &&
    applicationIdentifiers.length === 5 &&
    1 === 2
  ) {
    /*console.log(
      "expDate",
      "20" + applicationIdentifiers.find((e) => e.identifier === "D2").value,
      applicationIdentifiers
        .find((e) => e.identifier === "D2")
        .value.slice(3, 5),
      moment("20100801")
    );*/

    const expDateValue = applicationIdentifiers.find(
      (e) => e.identifier === "D2"
    ).value;

    const expDateMoment =
      "202" +
      (expDateValue.slice(3, 5) === "00"
        ? expDateValue.slice(0, 3) + "01"
        : expDateValue);
    data = {
      productCode: applicationIdentifiers.find((e) => e.identifier === "9N")
        .value,
      serialNumber: applicationIdentifiers.find((e) => e.identifier === "S1")
        .value,
      expDate: moment(expDateMoment).format("YYYY-MM-DD"),
      batchNumber: applicationIdentifiers.find((e) => e.identifier === "1T")
        .value,
    };
  }
  return data;
}
