import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

export default function useEventMessages(messageHandler) {
  const [data, setData] = useState(null);
  const [port, setPort] = useState(null);
  const [lastMessage, setLastMessage] = useState();
  const [message, setMessage] = useState({
    messageInfos: "start of the message layers",
  });

  useEffect(() => {
    // listen for messages
    window.addEventListener(
      "message",
      function (event) {
        if (event.data === "capturePort") {
          // capture port2 coming from the Dart side
          if (event.ports[0] != null) {
            // the port is ready for communication,
            // so you can use port.postMessage(message); wherever you want

            console.log("start message port", event.ports[0]);

            Sentry.captureMessage("Some message was registered");
            setPort(event.ports[0]);
            // To listen to messages coming from the Dart side, set the onmessage event listener
            event.ports[0].onmessage = function (event) {
              console.log("message", event);

              Sentry.addBreadcrumb({
                category: "incoming event",
                message: JSON.stringify(event),
                level: Sentry.Severity.Info,
              });

              //Sentry.captureException(event);
              //Sentry.captureMessage({ ...message, ...JSON.parse(event.data) });
              setLastMessage(JSON.parse(event.data));
              setMessage({ ...message, ...JSON.parse(event.data) });
              setData(JSON.parse(event.data));
              if (messageHandler) messageHandler(event);

              // event.data contains the message data coming from the Dart side
            };
          }
        }
      },
      false
    );
  }, []);

  const postMessage = (post) => {
    if (port) port.postMessage(JSON.stringify(post));
    //port.postMessage(JSON.stringify({ openRoute: "/error" }));
  };

  const getMessage = (key) => {
    return message[key];
  };

  return { port, postMessage, getMessage, message, lastMessage };
}
