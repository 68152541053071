import { deviceByKind } from "helpers/deviceList";
import React from "react";
import { Trans } from "react-i18next";
import styles from "./deviceIdFormatted.module.scss";

export default function DeviceIdFormatted({ children, title, kind }) {
  if (deviceByKind(kind)?.analog === true) return <Trans>analog device</Trans>;
  if (!children) return <Trans>No deviceId found</Trans>;
  const deviceIdSplit = children.split("-");

  let joy = deviceIdSplit[1].match(/.{1,3}/g);

  return (
    <span className={styles.name}>
      {title && (
        <>
          <Trans>{title}</Trans>{" "}
        </>
      )}
      {deviceIdSplit[0]}-
      {joy.map((e) => (
        <span>{e}</span>
      ))}
    </span>
  );
}
