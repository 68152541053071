import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const organizationsApi = generateCrudApi({
  name: "organizations",
  endpoints: (builder) => ({
    getCalendarById: builder.query({
      query: (request) => ({
        url: `organizations/updatetimes/${request.id}`,
        method: "post",
        body: request.data,
      }),
    }),
  }),
});
