import React, { createContext } from "react";
import usePushNotifications from "./usePush";
import { Button } from "@un/react";

export const PushContext = createContext("Default Value");

export default function NotificationsProvider({ children }) {
  const pushNotifcations = usePushNotifications();

  return (
    <PushContext.Provider value={pushNotifcations}>
      {children}
    </PushContext.Provider>
  );
}

export function NotificationsProviderWeb({ children }) {
  return (
    <PushContext.Provider value={undefined}>{children}</PushContext.Provider>
  );
}
