import { useAuth0 } from "contexts/auth0-context";
import accounts from "ducks/accounts";
import auth, { getAuthToken } from "ducks/auth";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import { App as CapApp } from "@capacitor/app";

export default function useAccount() {
  const { i18n } = useTranslation();
  const auth0Context = useAuth0();
  const { token, user, handleRedirectCallback } = auth0Context;
  const dispatch = useDispatch();

  const reduxToken = useSelector(getAuthToken);

  useEffect(() => {
    dispatch(auth.actions.setTokenSync(token));
    if (user) Sentry.setUser({ name: user.name, email: user.email });
  }, [token]);

  useEffect(() => {
    i18n.changeLanguage(
      user?.["http://yourdomain/claims/user"]?.app_metadata?.language,
      (err, t) => {
        if (err) return console.log("something went wrong loading", err);
      }
    );
  }, [user?.["http://yourdomain/claims/user"]?.app_metadata?.language]);

  /* useEffect(() => {
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        console.log("RhandleRedirectCallbacksdssaasdasdaads");
        await handleRedirectCallback(url);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);*/

  return { reduxToken, token, ...auth0Context };
}
