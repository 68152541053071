import React from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import DatePicker, { registerLocale } from "react-datepicker";
import { de } from "date-fns/locale";
import {
  startOfWeek,
  endOfWeek,
  endOfDay,
  startOfDay,
  startOfISOWeek,
} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";
import useBakedEvents, { isBakedTime } from "helpers/calendars/useBakedEvents";
registerLocale("de", de);

export default function WeekPicker({ startDate, onChange }) {
  const { eventsBakedReduced } = useBakedEvents();

  return (
    <div className="week-picker">
      <DatePicker
        selected={startDate?.from}
        onChange={(date) => onChange(date)}
        inline
        minDate={startOfISOWeek(new Date())}
        dayClassName={(date) => {
          const { timeIsBaked } = isBakedTime({
            eventsBakedReduced,
            from: startOfDay(date),
            to: endOfDay(date),
          });

          return timeIsBaked
            ? "react-datepicker__baked"
            : "react-datepicker__unbaked";
        }}
        startDate={startDate?.from}
        endDate={startDate?.to}
        locale="de"
      />
    </div>
  );
}
