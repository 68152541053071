import React from "react";
import Empty from "components/Empty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-light-svg-icons";
import { Trans } from "react-i18next";

export default function SettingsSecurity() {
  return (
    <Empty
      icon={<FontAwesomeIcon icon={faShieldCheck} />}
      kind="large"
      noBackground
      title={<Trans>You are using Anmed Memo offline</Trans>}
    >
      <Trans>
        You are using Anmed Memo as a offline application. No data will be
        uploaded and all data is stored inside your browsers local storage.
      </Trans>
    </Empty>
  );
}
