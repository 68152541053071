import React, { useEffect } from "react";
import SettingsContentHeader from "./SettingsContentHeader";
import styles from "./styles.module.scss";
import {
  BlockNotification,
  SidebarContentBody,
  List,
  ListItem,
} from "@un/react";
import SettingsMobileHeader from "./SettingsMobileHeader";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import i18next from "i18next";
import { Trans } from "react-i18next";
import classnames from "classnames";
import qs from "qs";
import scrollToTop from "helpers/scrollToTop";
import ReactJson from "react-json-view";

const FormContent = ({
  children,
  handleSubmit,
  onSubmit,
  className,
  notification,
  formNotification,
  formClasses,
}) => {
  if (handleSubmit && onSubmit)
    return (
      <form className={className} onSubmit={handleSubmit(onSubmit)}>
        {notification}
        {formNotification}
        <div className={formClasses}>{children}</div>
      </form>
    );
  return <div className={className}>{children}</div>;
};

const AfterContent = ({ className, after }) => {
  if (after) <div className={styles.form}>{after}</div>;
  return null;
};

const Content = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const SettingsContentWrapper = (props) => {
  const {
    after,
    children,
    header,
    mobileHeader,
    components: componentsOverride = {},
    handleSubmit,
    onSubmit,
    fullHeight,
    idElement,
    customNewEntrySuccessText,
    StatusBlockquote,
    fullWidth,
    search,
    hideMessages,
    form = { formState: {} },
    singleQuery,
    showReturnDesktop = false,
    resultCreateSingle,
    resultUpdateSingle,
    latestCrudId,
    entryDataId,
    urlId,
    url,
    ...other
  } = props;

  const defaultComponents = {
    SettingsMobileHeader,
    SettingsContentHeader,
    StatusBlockquote,
    SidebarContentBody,
    Content,
    FormContent,
    AfterContent,
  };
  const components = { ...defaultComponents, ...componentsOverride };

  const history = useHistory();
  const updateName = props.entryName
    ? props.entryName(
        other.latestCrudUpdate ||
          resultCreateSingle?.data ||
          resultUpdateSingle?.data
      )
    : "";

  const classes = classnames(styles.regularForm, {
    [`${styles.narrow}`]: other.narrow,
    [`${styles.fullWidth}`]: fullWidth,
    [`${styles.global}`]: other.global,
  });

  useEffect(() => {
    if (resultCreateSingle?.isSuccess) {
      reset(undefined, { keepValues: true, dirty: false });
    }
    if (resultCreateSingle?.isSuccess || resultCreateSingle?.isError)
      scrollToTop();
  }, [resultCreateSingle]);

  useEffect(() => {
    if (resultUpdateSingle?.isSuccess) {
      reset(undefined, { keepValues: true, dirty: false });
    }
    if (resultUpdateSingle?.isSuccess || resultUpdateSingle?.isError)
      scrollToTop();
  }, [resultUpdateSingle]);

  const notification = hideMessages ? null : resultCreateSingle?.isSuccess &&
    idElement(resultCreateSingle.data) === urlId ? (
    <BlockNotification
      block
      kind="success"
      withIcon
      title={
        <>
          <Trans>{other.name + "-SINGULAR"}</Trans> <Trans>created</Trans>
        </>
      }
      subtitle={
        <>
          {customNewEntrySuccessText ? (
            customNewEntrySuccessText(other.entryData)
          ) : (
            <>
              <Trans>New entry</Trans> <b>{updateName}</b>{" "}
              <Trans>was successfully created.</Trans>
            </>
          )}
        </>
      }
      className={styles.infoBlockquote}
    />
  ) : (latestCrudId === entryDataId && entryDataId) ||
    (resultUpdateSingle?.isSuccess &&
      idElement(resultUpdateSingle?.data) === urlId) ? (
    <BlockNotification
      block
      kind="success"
      withIcon
      title={
        <>
          <Trans>{other.name + "-SINGULAR"}</Trans> <Trans>updated</Trans>
        </>
      }
      subtitle={
        <>
          <b>{updateName}</b> <Trans>was successfully saved.</Trans>
        </>
      }
      className={styles.infoBlockquote}
    />
  ) : resultCreateSingle?.isError || other.error ? (
    <BlockNotification
      block
      kind="warning"
      title={<Trans>Creating failed</Trans>}
      subtitle={
        <>
          <Trans>
            {resultCreateSingle.error?.data?.message ||
              "Error while creating a new entry."}
          </Trans>

          <ReactJson collapsed src={resultCreateSingle} />
        </>
      }
    />
  ) : resultUpdateSingle?.isError ? (
    <BlockNotification
      block
      kind="warning"
      title={<Trans>Updating failed</Trans>}
      subtitle={
        <>
          <Trans>There was an error while updating the entry.</Trans>
          <ReactJson collapsed src={resultUpdateSingle} />
        </>
      }
    />
  ) : singleQuery?.isError ? (
    <BlockNotification
      block
      kind="warning"
      title={<Trans>Loading failed</Trans>}
      subtitle={
        <>
          <Trans>There was an error while loading the entry.</Trans>
          <ReactJson collapsed src={singleQuery} />
        </>
      }
    />
  ) : null;

  const {
    formState: { isDirty, errors },
    reset,
  } = form;

  const errorsArray = errors ? Object.entries(errors) : [];

  const formNotification =
    errorsArray.length > 0 ? (
      <BlockNotification
        block
        kind="warning"
        title={<Trans>Error occured</Trans>}
        subtitle={
          <>
            <Trans>There was an error while loading the entry.</Trans>
            <List kind="simple">
              {errorsArray.map(([i, e]) => (
                <ListItem title={i}>{<Trans>{e.message}</Trans>}</ListItem>
              ))}
            </List>
          </>
        }
      />
    ) : null;

  const formClasses = classnames(styles.formWrapper, {
    [`${styles.isLoading}`]: singleQuery?.isLoading || singleQuery?.isFetching,
    [`${styles.error}`]: singleQuery?.error,
  });

  const formElClasses = classnames(styles.form, {
    [`${styles.fullHeight}`]: fullHeight,
  });

  useEffect(() => {
    //scrollToTop();
    if (resultCreateSingle?.data?.id && urlId === "new") {
      history.push(
        `${url}/${resultCreateSingle.data.id}?${qs.stringify({
          ...search,
        })}`
      );
    }
  }, [resultCreateSingle]);

  const content = (
    <components.Content className={classes}>
      <components.SettingsContentHeader
        {...props}
        showReturnDesktop={showReturnDesktop}
      />
      {components.StatusBlockquote && <components.StatusBlockquote />}
      <components.FormContent
        className={formElClasses}
        children={children}
        formClasses={formClasses}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        notification={notification}
        formNotification={formNotification}
      />
      <components.AfterContent className={styles.form} after={after} />
    </components.Content>
  );

  return (
    <>
      <Prompt
        when={
          isDirty === true && !(resultCreateSingle?.data?.id && urlId === "new")
        }
        message={(location) => i18next.t(`Are you sure you want to go to?`)}
      />
      {other.global ? (
        <>{content}</>
      ) : (
        <>
          <components.SettingsMobileHeader {...props} />{" "}
          <components.SidebarContentBody children={content} />
        </>
      )}
    </>
  );
};

export default SettingsContentWrapper;
