import React from "react";
import CrudButton from "components/CrudButton";
import { Trans, useTranslation } from "react-i18next";

const SettingsSubmitButton = ({
  loadingCrud,
  name,
  title,
  urlId,
  resultCreateSingle,
  resultUpdateSingle,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <CrudButton
      type="submit"
      loading={
        loadingCrud ||
        resultCreateSingle?.isLoading ||
        resultUpdateSingle?.isLoading
      }
      {...other}
    >
      {title ? (
        title
      ) : (
        <Trans
          i18nKey={
            title
              ? title
              : urlId === "new"
              ? "AddNewNameSingular"
              : "UpdateNameSingular"
          }
          values={{ name: t(`${name}-SINGULAR`) }}
        />
      )}
    </CrudButton>
  );
};

export default SettingsSubmitButton;
