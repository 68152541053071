import React from "react";
import Avatar from "components/Avatar";
import styles from "./styles.module.scss";

export default function UserDetails({ user }) {
  if (!user) return null;
  return (
    <div className={styles.userDetails}>
      <Avatar user={user} kind="large" className={styles.avatar} />
      <div>
        <h3 className={styles.name}>
          {user.meta?.firstName} {user.meta?.lastName}
        </h3>
        <div className={styles.meta}>
          <p className={styles.meta?.address}>
            {user.meta?.street}, {user.meta?.postal} {user.meta?.city}
          </p>
        </div>
      </div>
    </div>
  );
}
