import React from "react";
import { Trans } from "react-i18next";

export default function OrganizationName({ organization }) {
  return (
    <>
      {organization.name ? (
        organization.name
      ) : organization.kind === "private" ? (
        <Trans>Private organization</Trans>
      ) : (
        <Trans>Unnamed organization</Trans>
      )}
    </>
  );
}
