import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import "./translation/i18n";
import "moment/locale/de";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Capacitor } from "@capacitor/core";
import adapter from "webrtc-adapter";

import { StatusBar, Style } from "@capacitor/status-bar";

const setStatusBarStyleDark = async () => {
  if (Capacitor.isNativePlatform()) {
    await StatusBar.setStyle({ style: Style.Light });
  }
};

setStatusBarStyleDark();

//import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://c0005be9f25a4b21919359f7c37abd2a@o1076131.ingest.sentry.io/6077427",
    integrations: [new Integrations.BrowserTracing()],
    release: "0.0.1", //process.env.REACT_APP_SENTRY_RELEASE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      <App />
      {/*</PersistGate>*/}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

if (!Capacitor.isNativePlatform()) {
  console.log("Use service worker");
  serviceWorkerRegistration.register({
    onSuccess: () => alert("You use the cached version now."),
    onUpdate: (registration) => {
      if (window.confirm("Update to latest memo version?")) {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
          window.location.reload();
        }
      }
    },
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
