import { createSlice } from "@reduxjs/toolkit";

const globalState = createSlice({
  name: "globalState",
  initialState: {
    errors: [],
    lastUser: undefined,
  },
  reducers: {
    addError: (state, action, filter) => {
      state.errors.push(action.payload);
    },
    setLastUser: (state, action, filter) => {
      state.lastUser = action.payload;
    },
    reset: (state, action, filter) => {
      state.errors = [];
    },
  },
});

export default globalState;
