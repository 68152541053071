import {
  Button,
  TextInput,
  BlockNotification,
  InputGroup,
  TextArea,
  Checkbox,
} from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { Trans, useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNotesMedical,
  faUserChart,
  faUserInjured,
  faUserNurse,
} from "@fortawesome/pro-light-svg-icons";
import users from "ducks/users";
import { useDispatch } from "react-redux";
import AdressInput from "components/AddressInputs";
import { Col, Row } from "react-flexbox-grid";
import MultiCheckbox from "components/MultiCheckbox";
import { faUserMd } from "@fortawesome/pro-light-svg-icons";
import styles from "./styles.module.scss";

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import UploadButton from "../UploadButton";
import Avatar from "components/Avatar";

import IntakeTimes from "components/IntakeTimes";
import {
  faCheckCircle,
  faTimesCircle,
  faPlusCircle,
  faChevronRight,
  faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import useCurrentUser, { useDebug } from "helpers/useCurrentUser";
import {
  useActiveUserDevice,
  useUsersWithCurrentOrganization,
} from "helpers/useUsers";
import ButtonRouter from "components/ButtonRouter";
import DeviceIcon from "components/DeviceIcon";
import FormRow from "components/FormRow";
import DeleteModal from "components/DeleteModal";
import { usersApi } from "ducks/usersApi";
import MultiCheckboxWrapper from "components/MultiCheckbox/MultiCheckboxWrapper";
import useCurrentOrganization from "helpers/organizations/useCurrentOrganization";
import UserName, { UserNameNew, userNameString } from "components/UserName";
import DateInput from "components/DateInput";
import TimezoneSelect from "components/TimezoneSelect";
import { Controller } from "react-hook-form";

import globalState from "ducks/globalState";
import InlineLoadingLarge from "components/InlineLoadingLarge";
import GenderPicker from "components/GenderPicker";

import ReactJson from "react-json-view";

const prepareSubmit = (values) => {
  const {
    action,
    avatar,
    organizations,
    auth0User,
    owner,
    status,
    ...newValues
  } = values;
  return newValues;
};

export default function SettingsUsersDetail() {
  const params = useParams();
  const { entry } = params;
  const history = useHistory();

  const activeUserDevice = useActiveUserDevice();

  const adminUsers = useUsersWithCurrentOrganization({ role: "admin" });

  const currentUser = useCurrentUser();

  const store = useSettingsForm({
    name: "users",
    //getEntryById: users.selectors.byId,
    api: usersApi,
    url: `/${params.organization}/users`,
    prepareSubmit,
    newEntryData: { role: "patient" },
    prepareFormEntry: (values) => {
      const timezone = values?.timezone ? values?.timezone : "Europe/Berlin";
      const role = values?.role ? values?.role : "patient";
      const category = values?.category ? values?.category : "patient";
      return { ...values, timezone, role, category };
    },
    entryName: (entry) => userNameString({ id: entry?.id }), //${entry?.meta?.firstName} ${entry?.meta?.lastName}`,
    customDeleteRedirect: ({ defaultRedirect, urlId }) => {
      if (currentUser.data?.id === urlId) history.push(`/`);
      else history.push(defaultRedirect);
    },
  });

  const dispatch = useDispatch();

  const {
    urlId,
    entryData,
    organizationId,
    name,
    entryTitle,
    form: { setValue, control, errors, register, watch },
  } = store;

  const role = watch("role");
  const category = watch("category");

  useEffect(() => {
    if (role === "patient" && urlId === "new") setValue("category", "patient");
  }, [role]);

  const acceptOrganizationInvite = () => {
    dispatch(
      users.actions.fetchAcceptOrganization({
        values: {
          userId: urlId,
          organizationId: organizationId,
          status: "accept",
        },
      })
    );
  };

  const { t } = useTranslation();

  const currentOrganization = useCurrentOrganization();

  useEffect(() => {
    if (params.entry && category === "patient")
      dispatch(globalState.actions.setLastUser(params.entry));
  }, [params.entry]);

  const categories = {
    patient: { icon: faUserInjured },
    pharmacist: { icon: faNotesMedical },
    doctor: { icon: faUserMd },
    nurse: { icon: faUserNurse },
    relative: { icon: faUserChart },
  };

  const categoryInputs = Object.entries(categories).map(([key, c]) => (
    <MultiCheckbox
      mobile="vertical"
      labelText={<Trans>{key}</Trans>}
      icon={<FontAwesomeIcon icon={c.icon} />}
      value={key}
      type="radio"
      {...register("category")}
    />
  ));

  const disableDelete =
    adminUsers.length < 2 && entryData && entryData.role === "admin"
      ? true
      : false;

  const customDeleteQuestionTitle =
    adminUsers.length < 2 && entryData && entryData.role === "admin" ? (
      <Trans>Last Admin can not be removed</Trans>
    ) : currentUser.data?.id === urlId ? (
      <Trans>Exit organization</Trans>
    ) : undefined;

  const customDeleteQuestion =
    adminUsers.length < 2 && entryData && entryData.role === "admin" ? (
      <Trans>You need to add another admin first.</Trans>
    ) : currentUser.data?.id === urlId ? (
      <Trans>Do you want to leave the organization?</Trans>
    ) : (
      <Trans i18nKey="deleteQuestionRemove" values={{ name: `${entryTitle}` }}>
        Are you sure that you want to remove <b>{{ name }}</b> from the
        organization?
      </Trans>
    );

  if (!entryData)
    return (
      <SettingsContentWrapper
        {...store}
        disableDelete={disableDelete}
        customDeleteQuestionTitle={customDeleteQuestionTitle}
        customDeleteQuestion={customDeleteQuestion}
        primaryButtonText={<Trans>Remove</Trans>}
        customDeleteButtonText={<Trans>Remove user</Trans>}
        title={
          urlId === "new" && role === "patient" ? (
            <Trans>Create patient</Trans>
          ) : urlId === "new" ? (
            <Trans>Invite user</Trans>
          ) : (
            <UserNameNew user={entryData} />
          )
        }
      >
        <InlineLoadingLarge />
      </SettingsContentWrapper>
    );

  return (
    <SettingsContentWrapper
      {...store}
      disableDelete={disableDelete}
      customDeleteQuestionTitle={customDeleteQuestionTitle}
      customDeleteQuestion={customDeleteQuestion}
      primaryButtonText={<Trans>Remove</Trans>}
      customDeleteButtonText={<Trans>Remove user</Trans>}
      title={
        urlId === "new" && role === "patient" ? (
          <Trans>Create patient</Trans>
        ) : urlId === "new" ? (
          <Trans>Invite user</Trans>
        ) : (
          <UserNameNew user={entryData} />
        )
      }
    >
      {urlId !== "new" && (
        <>
          {activeUserDevice.data?.[0]?.id ? (
            <BlockNotification
              actions={
                <ButtonRouter
                  withOrganization
                  isLink
                  icon={<FontAwesomeIcon icon={faChevronRight} />}
                  to={`/devices/${activeUserDevice.data[0].id}`}
                >
                  <Trans>Go to device</Trans>
                </ButtonRouter>
              }
              advancedActions
              title={<Trans>Associated device</Trans>}
              subtitle={
                <Trans
                  i18nKey="userConnectedDevice"
                  values={{ name: t(activeUserDevice.data[0].kind) }}
                >
                  The user has a connected <b>{name}</b>.
                </Trans>
              }
              kind="info"
              icon={
                <DeviceIcon
                  device={activeUserDevice.data[0].kind}
                  className={styles.deviceIcon}
                />
              }
            />
          ) : entryData.category === "patient" ? (
            <BlockNotification
              kind="info"
              actions={
                <ButtonRouter
                  withOrganization
                  isLink
                  to={`/devices`}
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                >
                  <Trans>Assign device</Trans>
                </ButtonRouter>
              }
              advancedActions
              title={<Trans>No device setup</Trans>}
              subtitle={<Trans>Add a device to this patient</Trans>}
            />
          ) : (
            <></>
          )}
        </>
      )}

      {currentUser.data?.id === urlId && (
        <BlockNotification
          title={<Trans>This is your account</Trans>}
          subtitle={
            <Trans>Change your language, etc. in the account settings.</Trans>
          }
          kind="info"
          actions={
            <ButtonRouter
              isLink
              to={`/account`}
              icon={<FontAwesomeIcon icon={faUserCircle} />}
            >
              <Trans>Account settings</Trans>
            </ButtonRouter>
          }
          advancedActions
        />
      )}
      {currentUser.data?.id === urlId &&
      entryData.status === "invited" &&
      urlId !== "new" ? (
        <BlockNotification
          actions={
            <>
              <Button
                onClick={acceptOrganizationInvite}
                icon={<FontAwesomeIcon icon={faCheckCircle} />}
              >
                Accept
              </Button>
              <DeleteModal
                {...store}
                customButton={
                  <Button
                    kind="danger--primary"
                    icon={<FontAwesomeIcon icon={faTimesCircle} />}
                  >
                    Reject
                  </Button>
                }
              />
            </>
          }
          advancedActions
          title={<Trans>Organization membership</Trans>}
          subtitle={<Trans>You are invited to join the organization.</Trans>}
        />
      ) : entryData.status === "invited" ? (
        <BlockNotification
          kind="warning"
          title={<Trans>Waiting to join the organization</Trans>}
          subtitle={
            <Trans>
              The user is invited to join the organization, but has not yet
              accepted to join this organization.
            </Trans>
          }
        />
      ) : null}

      {urlId === "new" && (
        <MultiCheckboxWrapper
          labelText={<Trans>What do you want to do?</Trans>}
          mobileVertical
          helperText={
            <Trans>You can invite an existing user or create a patient.</Trans>
          }
        >
          <MultiCheckbox
            labelText={<Trans>Invite user</Trans>}
            value="user"
            icon={<FontAwesomeIcon icon={faUserNurse} />}
            type="radio"
            {...register("role")}
          />
          <MultiCheckbox
            labelText={<Trans>Add Patient</Trans>}
            value="patient"
            icon={<FontAwesomeIcon icon={faUserInjured} />}
            type="radio"
            {...register("role")}
          />
        </MultiCheckboxWrapper>
      )}
      {(urlId === "new" && role === "patient") || urlId !== "new" ? (
        <>
          {(urlId === "new" || !entryData.owner) && (
            <FormRow>
              <Col xs={12} md={6}>
                <TextInput
                  labelText={<Trans>First name</Trans>}
                  {...register("meta.firstName")}
                />
              </Col>
              <Col xs={12} md={6}>
                <TextInput
                  labelText={<Trans>Last name</Trans>}
                  {...register("meta.lastName")}
                />
              </Col>
            </FormRow>
          )}
          {entry !== "new" && role !== "patient" && (
            <MultiCheckboxWrapper labelText={<Trans>Category</Trans>}>
              {categoryInputs}
            </MultiCheckboxWrapper>
          )}

          <DateInput
            labelText={<Trans>Birthdate</Trans>}
            {...register("meta.birthdate")}
          />

          <GenderPicker register={register} name="meta.gender" />

          {category === "patient" && (
            <>
              <Controller
                control={control}
                name="timezone"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TimezoneSelect
                    onChange={(e) => onChange(e.utc[0])}
                    onBlur={onBlur}
                    value={value}
                    labelText={<Trans>Timezone</Trans>}
                    helperText={
                      <Trans>
                        Select the timezone where the device is in use
                      </Trans>
                    }
                  />
                )}
              />

              {/*  <h3>
                <Trans>Intake times</Trans>
              </h3>
              <IntakeTimes
                control={control}
                watch={watch}
                register={register}
                id={urlId}
                entryData={entryData}
                errors={errors}
                  />*/}
            </>
          )}
          <h3>
            <Trans>Contact information</Trans>
          </h3>
          <div>
            {urlId !== "new" && (
              <>
                {entryData && entryData.avatar ? (
                  <>
                    <Avatar
                      alt="avatar"
                      image={entryData.avatar}
                      className={styles.currentImage}
                    />
                  </>
                ) : (
                  <>
                    <div className={styles.noImage}>
                      <Trans>No photo set</Trans>
                    </div>
                    <br />
                  </>
                )}
                {/*<UploadButton
                  userImage={entryData?.avatar}
                  onUpload={(file) => {
                    dispatch(
                      users.actions.updateUserImage({
                        user: urlId,
                        bodyFormData: file,
                      })
                    );
                  }}
                />*/}
              </>
            )}
          </div>

          {currentOrganization.data?.kind === "professional" && (
            <>
              <h3>
                <Trans>Address</Trans>
              </h3>
              <AdressInput register={register} />
              {(category === "patient" ||
                (entry === "new" && category === "patient")) && (
                <>
                  <h3>Additional information</h3>
                  <TextArea
                    {...register("meta.contactPersons")}
                    labelText={<Trans>Contact persons</Trans>}
                  />
                  <TextArea
                    {...register("meta.treatingDoctor")}
                    labelText={<Trans>Treating doctor</Trans>}
                  />
                  <FormRow bottom="xs">
                    <Col xs={12} md={4}>
                      <TextInput
                        {...register("meta.insurance")}
                        labelText={<Trans>Insurance</Trans>}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <TextInput
                        {...register("meta.insuranceNumber")}
                        labelText={<Trans>Insurance number</Trans>}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Checkbox
                        labelText={<Trans>Co-payment exemption</Trans>}
                        id="co-payment-payment"
                        {...register("meta.coPaymentExemption")}
                        value="true"
                      />
                    </Col>
                  </FormRow>
                  <h3>
                    <Trans>Retirement home</Trans>
                  </h3>
                  <Checkbox
                    labelText={<Trans>Retirement home residents</Trans>}
                    id="retirement-home"
                    {...register("meta.retirementHomeResident")}
                    value="true"
                  />
                  <TextArea
                    {...register("meta.retirementHomeAddress")}
                    labelText={<Trans>Address</Trans>}
                  />
                  <TextArea
                    {...register("meta.incompatibilities")}
                    labelText={<Trans>Incompatibilities</Trans>}
                  />
                  <TextArea
                    {...register("meta.notes")}
                    labelText={<Trans>Notes</Trans>}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div>
          <TextInput
            labelText={<Trans>email</Trans>}
            helperText={
              <Trans>Enter the email of an user you want to invite.</Trans>
            }
            {...register("email")}
          />
        </div>
      )}
      <SettingsSubmitButton
        {...store}
        title={
          urlId === "new" && role === "patient" ? (
            <Trans>Create patient</Trans>
          ) : urlId === "new" ? (
            <Trans>Invite user</Trans>
          ) : undefined
        }
      />
    </SettingsContentWrapper>
  );
}
