import { useLocation } from "react-router-dom";
import qs from "qs";

export default function useQs() {
  const location = useLocation();
  const { deleted, ...result } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  return result;
}
