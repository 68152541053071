import React from "react";
import { Input } from "@un/react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-regular-svg-icons";

const TimeInput = React.forwardRef((props, ref) => (
  <Input
    type="time"
    {...props}
    value={props.value}
    formItemClassName={styles.dateInput}
    addonAfter={<FontAwesomeIcon icon={faClock} />}
  >
    {(props) => {
      return (
        <div className={styles.dateInputWrapper}>
          <input {...props} ref={ref} />
        </div>
      );
    }}
  </Input>
));

export default TimeInput;
