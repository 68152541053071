import { faSunrise } from "@fortawesome/pro-light-svg-icons";

const unitList = {
  cm: { name: "Zentimeter", icon: faSunrise, shortName: "cm" },
  Fl: { name: "Flasche", icon: faSunrise, shortName: "Fl" },
  g: { name: "Gramm", icon: faSunrise, shortName: "g" },
  IE: { name: "Internationale Einheiten", icon: faSunrise, shortName: "IE" },
  kg: { name: "Kilogramm", icon: faSunrise, shortName: "kg" },
  l: { name: "Liter", icon: faSunrise, shortName: "l" },
  m: { name: "Meter", icon: faSunrise, shortName: "m" },
  mg: { name: "Milligramm", icon: faSunrise, shortName: "mg" },
  ml: { name: "Milliliter", icon: faSunrise, shortName: "ml" },
  mm: { name: "Millimeter", icon: faSunrise, shortName: "mm" },
  P: { name: "Packung", icon: faSunrise, shortName: "P" },
  Sp: { name: "Sprühstöße", icon: faSunrise, shortName: "Sp" },
  St: { name: "Stück", icon: faSunrise, shortName: "St" },
  µg: { name: "Mikrogramm", icon: faSunrise, shortName: "µg" },
};

export default unitList;
