import React from "react";
import styles from "./medicationEntry.module.scss";

import { Tag, Item } from "@un/react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import color from "color";
import classNames from "classnames";
import { presetColors } from "components/ColorInput";
import MedicationName from "components/MedicationName";
import moment from "moment";
import { useDebug } from "helpers/useCurrentUser";
import { /*format,*/ formatISO, isValid } from "date-fns";
import { useActiveUser } from "helpers/useUsers";
import { Trans } from "react-i18next";
import unitList from "helpers/unitList";
const { formatInTimeZone } = require("date-fns-tz");

export default function MedicationEntry({
  entry,
  details,
  fill,
  dot,
  isPast,
  isDesktop,
  timeIsBaked,
  time,
}) {
  const params = useParams();
  const location = useLocation();
  const debug = useDebug();
  const activeUser = useActiveUser();

  const isPlanned = !isPast;
  const classes = classNames(
    {
      [styles.past]: isPast,
      //[styles.isBaked]: entry.bake,
      [styles.isPlanned]: !isPast, //entry.bake === false,
      [styles.dot]: dot,
      [styles.details]: details,
    },
    styles.medicationEntry
  );

  if (/*timeIsBaked && */ entry.bake !== true && isPast) {
    return null; //<div>Filled hide</div>;
  }

  if (/*timeIsBaked && */ entry.bake === true && !isPast) {
    return null; //<div>Filled future</div>;
  }

  const activeColor = entry.localMedicationData?.meta?.color
    ? entry.localMedicationData?.meta?.color
    : entry?.medicationData?.PPN
    ? presetColors[parseInt(entry.medicationData.PPN.slice(-1))]
    : "#fff";

  const style = isPlanned
    ? {
        borderColor: color(activeColor).darken(0.1).hex(),
        color: color(activeColor).darken(0.7).hex(),
        backgroundColor: isDesktop
          ? color(activeColor).lighten(0.45).hex()
          : color(activeColor).darken(0.1).hex(),
      }
    : activeColor
    ? {
        borderColor: color(activeColor).lighten(0.13).hex(),
        color: color(activeColor).darken(0.7).hex(),
        backgroundColor: color(activeColor).lighten(0.13).hex(),
      }
    : undefined;

  //const date = new Date();
  /*const timeZone = "Europe/Berlin";
  const zonedDate = utcToZonedTime(entry.date, timeZone);

  const pattern = "HH:mm 'GMT' XXX (z)";
const output = format(
    entry.date,
    pattern
  );*/

  if (!activeUser?.timezone) return null;
  const content = (
    <>
      <div className={styles.medicationEntryAmount}>
        {entry.amount} <Trans>{unitList[entry.unit]?.shortName}</Trans>
      </div>
      <div className={styles.medicationEntryTitle}>
        {/*<div className={styles.debug}>{formatISO(entry.date)}</div>
        <div className={styles.debug}>{output}</div>
  <div className={styles.debug}>{entry.date.getTime()}</div>
  
    {/*activeUser.timezone &&
              formatInTimeZone(entry.date, activeUser.timezone, "HH:mm") !==
                time && (*/}
        {/*isValid(entry.date) && (
          <>({formatInTimeZone(entry.date, activeUser.timezone, "HH:mm")})</>
        )*/}
        {entry.emptyStomach && <div className={styles.emptyStomach}>N</div>}
        <span>
          <MedicationName entry={entry} />
        </span>

        <div className={styles.medicationEntrySubTitle}>
          {entry.medicationData?.manufacturer?.Firmenname}
          {entry.localMedicationData?.meta?.manufacturer &&
            entry.localMedicationData.meta?.manufacturer.substr(
              entry.localMedicationData.meta?.manufacturer.indexOf(" ") + 1
            )}
        </div>
      </div>
    </>
  );

  if (fill) {
    return (
      <div className={classes} style={style}>
        {content}
      </div>
    );
  }

  const to = `/${params.organization}/calendar/${params.entry}/edit/${
    entry.id
  }/${
    isValid(new Date(entry.date))
      ? formatISO(new Date(entry.date))
      : "no date found"
  }${location.search}`;

  if (details) {
    return (
      <NavLink to={to} className={classes}>
        <Item
          //className={classes}
          image={<div className={styles.detailIcon} style={style}></div>}
          title={<MedicationName entry={entry} />}
          additional={moment(entry.date).format("DD.MM.YYYY HH:mm")}
          kind="horizontal"
          wrapper="sidebar"
          /*hint={
            <Tag>
              <Trans>{entry.bake ? "filled" : "planned"}</Trans>
            </Tag>
          }*/
        >
          {entry.amount} <Trans>{`${entry.unit}-PLURAL`}</Trans>{" "}
          {entry.emptyStomach && <Trans>(empty stomach)</Trans>}
        </Item>
      </NavLink>
    );
  }

  /*if (isDot) {
    const dotClasses = classNames(styles.dot, {});

    return (
      <div
        className={dotClasses}
        style={{ backgroundColor: color(activeColor).lighten(0.15).hex() }}
      ></div>
    );
  }

  if (isDot) {
    const dotClasses = classNames(styles.dot, {});

    return (
      <div
        className={dotClasses}
        style={{ backgroundColor: color(activeColor).lighten(0.15).hex() }}
      ></div>
    );
  }*/

  /* if (dot) {
    return (
      <div
        activeClassName={styles.medicationEntryActive}
        className={classes}
        style={style}
      >
        {content}
      </div>
    );
  }*/

  if (isDesktop)
    return (
      <NavLink
        activeClassName={styles.medicationEntryActive}
        to={to}
        className={classes}
        style={style}
      >
        {content}
      </NavLink>
    );

  return (
    <div
      activeClassName={styles.medicationEntryActive}
      className={classes}
      style={style}
    >
      {content}
    </div>
  );
}
