import React from "react";
import { Button, SidebarContentHeader } from "@un/react";
import SidebarBackButton from "components/SidebarBackButton";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import styles from "./settingsMobileHeader.module.scss";
import { NavLink } from "react-router-dom";
import DeleteModal from "components/DeleteModal";

export default function SettingsMobileHeader(props) {
  const {
    customMobileHeader,
    nameCapitalized,
    overviewUrl,
    hideDelete,
    title,
    urlId,
  } = props;
  return (
    <>
      <SidebarContentHeader>
        <NavLink to={`${overviewUrl}`}>
          <SidebarBackButton />
        </NavLink>
        <div className={styles.mobileName}>
          {customMobileHeader ? (
            customMobileHeader
          ) : title ? (
            title
          ) : (
            <Trans>{nameCapitalized}</Trans>
          )}
        </div>
        {urlId !== "new" && props.deleteEntry && !hideDelete && (
          <DeleteModal
            {...props}
            customButton={
              <Button
                kind="ghost"
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                className={styles.mobileRemove}
              />
            }
          />
        )}
      </SidebarContentHeader>
    </>
  );
}
