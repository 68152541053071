import moment from "moment";

export default function eventTraysToDate(trayNumber) {
  const weekdayNumber = parseInt(trayNumber) % 7;
  const timeNumber = ((parseInt(trayNumber) - 1) / 7).toString().split(".")[0];

  const weekday = moment().day("Monday").add(weekdayNumber, "day");

  return { weekday, timeNumber, weekdayNumber };
}
