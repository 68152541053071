import {
  faCalendarAlt,
  faCalendarDay,
  faList,
} from "@fortawesome/pro-solid-svg-icons";

const calendarList = {
  tray: {
    name: "morning",
    defaultTime: "08:00",
    icon: faCalendarAlt,
    memo: true,
  },
  dayDispender: {
    name: "morning",
    defaultTime: "08:00",
    icon: faCalendarDay,
    memo: true,
  },
  agenda: {
    name: "morning",
    defaultTime: "08:00",
    icon: faList,
    memo: true,
  },
};

export default calendarList;
