import React, { useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";
import { Trans } from "react-i18next";
import {
  faBoxFull,
  faCalendarAlt,
  faClinicMedical,
  faExchange,
  faMonitorHeartRate,
  faPills,
  faUserMd,
  faCog,
  faBell,
} from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

import {
  faBoxFull as faBoxFullActive,
  faCog as faCogActive,
  faBell as faBellActive,
  faClinicMedical as faClinicMedicalActive,
  faCalendarAlt as faCalendarAltActive,
  faMonitorHeartRate as faMonitorHeartRateActive,
  faPills as faPillsActive,
  faUserMd as faUserMdActive,
  faUsersMedical as faUsersMedicalActive,
} from "@fortawesome/pro-solid-svg-icons";
import { useActiveOrganzation } from "helpers/useOrganization";
import { useSelector } from "react-redux";
import useIsDesktop from "helpers/useIsDesktop";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import Notification from "./Notification";

export const useSidebarData = () => {
  const { organization } = useParams();
  const latestUserId = useSelector((state) => state.globalState.lastUser);

  const now = new Date().toISOString();

  return {
    calendar: {
      name: "Calendar",
      to: `/${organization}/calendar/${latestUserId}?trayDate=${now}`,
      icon: faCalendarAlt,
      iconActive: faCalendarAltActive,
    },
    users: {
      name: "Users",
      to: `/${organization}/users`,
      icon: faUserMd,
      iconActive: faUserMdActive,
    },
    medications: {
      name: "Medications",
      to: `/${organization}/medications`,
      icon: faPills,
      iconActive: faPillsActive,
      desktopOnly: true,
      professionalOnly: true,
    },
    stock: {
      name: "Stock",
      to: `/${organization}/stocks`,
      icon: faBoxFull,
      iconActive: faBoxFullActive,
      desktopOnly: true,
      professionalOnly: true,
    },
    devices: {
      name: "Devices",
      to: `/${organization}/devices`,
      icon: faMonitorHeartRate,
      iconActive: faMonitorHeartRateActive,
      //desktopOnly: true,
    },
    notifications: {
      name: "Messages",
      to: `/${organization}/messages`,
      icon: faBell,
      iconActive: faBellActive,
    },
    organization: {
      name: "Organization",
      to: `/${organization}/organization`,
      icon: faClinicMedical,
      iconActive: faClinicMedicalActive,
      desktopOnly: true,
      professionalOnly: true,
    },
    settings: {
      name: "Settings",
      to: `/${organization}/advanced`,
      icon: faCog,
      iconActive: faCogActive,
      //mobileOnly: true,
      exact: true,
    },
  };
};

export default function SettingsList() {
  const history = useHistory();
  const { patient } = useParams();
  const sidebar = useSidebarData();

  const logout = () => {
    history.push("/logout");
  };

  const activeOrganization = useActiveOrganzation();

  const isDesktop = useIsDesktop();

  const inBottomBar = Object.entries(sidebar).filter(([key, c]) =>
    isDesktop || !c.desktopOnly ? true : false
  );

  const [keyboardShow, setKeyboardShow] = useState(false);

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener("keyboardWillShow", (info) => {
      setKeyboardShow(true);
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setKeyboardShow(false);
    });
  }

  if (keyboardShow) return null;

  return (
    <>
      <div className={styles.main}>
        {Object.entries(sidebar).map(([settingsPage, s]) => {
          const classes = classNames({
            [styles.desktopOnly]: s.desktopOnly,
            [styles.mobileOnly]: s.mobileOnly,
            /*[styles.active]:
              settingsPage === "settings" &&
              patient !== undefined &&
              !inBottomBar.find(([key, c]) => key === patient),*/
          });

          if (
            s.professionalOnly === true &&
            activeOrganization?.kind === "private"
          )
            return null;

          return (
            <NavLink
              key={settingsPage}
              to={s.to}
              isPlain
              exact={s.exact}
              className={classes}
              activeClassName={styles.active}
              onTouchStart={(e) => {
                e.preventDefault();
                history.push(s.to);
              }}
            >
              <div className={styles.icon}>
                {settingsPage === "notifications" && <Notification />}
                <FontAwesomeIcon icon={s.icon} className={styles.iconRegular} />
                <FontAwesomeIcon
                  icon={s.iconActive}
                  className={styles.iconActive}
                />
              </div>
              <span>
                <Trans>{s.name}</Trans>
              </span>
            </NavLink>
          );
        })}
      </div>
      <div className={styles.footer}>
        <NavLink to={`/?show=always`} exact>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={faExchange} className={styles.iconRegular} />
          </div>
          <span>
            <Trans>Switch org.</Trans>
          </span>
        </NavLink>
      </div>
    </>
  );
}
