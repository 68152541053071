import React from "react";
import { Button, InlineLoading } from "@un/react";
import ReactJson from "react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import { devicesApi } from "ducks/devices";

export default function PingDevice({ id }) {
  const [pingDevice, resultPingDevice] =
    devicesApi.useUpdatePindByDeviceIdMutation();
  const [rebootDevice, resultRebootDevice] =
    devicesApi.useRebootDeviceMutation();

  const [getDeviceStatus, resultGetDeviceStatus] =
    devicesApi.useGetDeviceStatusMutation();

  const { data } = devicesApi.useGetSingleDevicesQuery(id);

  return (
    <>
      <h3>Ping device</h3>
      <p>
        Ping the device to see if it is online and make it available for faster
        responses.
      </p>
      <br />
      <Button
        icon={<FontAwesomeIcon icon={faSatelliteDish} />}
        onClick={() => pingDevice(id)}
      >
        Ping device
      </Button>{" "}
      <br />
      {resultPingDevice.isLoading && (
        <InlineLoading description="Ping device..." />
      )}
      {resultPingDevice?.data && <ReactJson src={resultPingDevice.data} />}
      <h3>Reboot device</h3>
      <p>Reboot the devices firmware.</p>
      <br />
      <Button
        icon={<FontAwesomeIcon icon={faTrashUndo} />}
        onClick={() => rebootDevice(id)}
      >
        Reboot device
      </Button>
      {resultRebootDevice.isLoading && (
        <InlineLoading description="Reboot device..." />
      )}
      {resultRebootDevice?.data && <ReactJson src={resultRebootDevice.data} />}
      {/*<h3>Get device Status</h3>
      <p>Whattt????</p>
      <br />
      <Button
        icon={<FontAwesomeIcon icon={faTrashUndo} />}
        onClick={() => getDeviceStatus(id)}
      >
        Get Device Status
      </Button>
      {resultGetDeviceStatus.isLoading && (
        <InlineLoading description="Reboot device..." />
      )}
      {resultGetDeviceStatus?.data && (
        <ReactJson src={resultGetDeviceStatus.data} />
      )}*/}
    </>
  );
}
