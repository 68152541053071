import devices, { devicesApi } from "ducks/devices";
import users from "ducks/users";
import { usersApi } from "ducks/usersApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function useUsers({ kind }) {
  const { organization } = useParams();
  const results = usersApi.useGetAllUsersQuery(organization);
  const { data } = results;
  if (kind && data) data.filter((e) => e.role === kind);
  return data;
}

export function useUsersRequest() {
  const { organization } = useParams();
  return usersApi.useGetAllUsersQuery(organization);
}

export function useUsersWithCurrentOrganization({ category, role }) {
  const { organization } = useParams();
  const { data = [] } = usersApi.useGetAllUsersQuery(organization);

  if (role || category)
    return data.filter(
      (e) =>
        (!role || e.role === role) && (!category || e.category === category)
    );
  return data;
}

export function useLatestUser({ category, role }) {
  let userWithCurrentOrganization = useUsersWithCurrentOrganization({
    category,
    role,
  });
  return userWithCurrentOrganization[0];
}

export function useActiveUser() {
  const { entry } = useParams();
  const { data } = usersApi.useGetSingleUsersQuery(entry);
  return data;
}

export function useUserById(id) {
  const { data } = usersApi.useGetSingleUsersQuery(id);
  return data;
}

export function useActiveUserDevice() {
  const { entry } = useParams();
  return devicesApi.useSearchDevicesQuery({ patient: entry });
}
