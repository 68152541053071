import React, { useEffect, useState } from "react";
import WeekPicker from "components/WeekPicker";
import { useDispatch } from "react-redux";
import fills from "ducks/fills";
import styles from "./fillStart.module.scss";
import { Button, Modal } from "@un/react";
import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import useBakedEvents, {
  isBakedPeriodsInFrameMaxBoundariesOnly,
} from "helpers/calendars/useBakedEvents";
import { BlockNotification } from "@un/react";
import { useActiveUserDevice } from "helpers/useUsers";
import { Trans } from "react-i18next";
import { devicesApi } from "ducks/devices";
import {
  addDays,
  endOfISOWeek,
  format,
  isBefore,
  startOfISOWeek,
  startOfWeek,
} from "date-fns";
import { calendarFuturePoints } from "@wirewire/helpers";
import { calendarsApi } from "ducks/calendars";
import qs from "qs";
import ReactJson from "react-json-view";
import { useDebug } from "helpers/useCurrentUser";
import useIsDesktop from "helpers/useIsDesktop";

export default function FillStartButton({ customButton }) {
  const [open, setOpen] = useState();

  const handleClick = () => {
    console.log("clicked");
    setOpen(true);
  };

  let button = customButton ? (
    React.cloneElement(customButton, { onClick: handleClick })
  ) : (
    <Button onClick={() => setOpen(true)}>Open</Button>
  );

  //if (currentUserDevices?.id)
  return (
    <>
      {button}
      {open && <FillStart handleModalClose={() => setOpen(false)} />}
    </>
  );
  //return null;
}

export function FillStart({ handleModalClose }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const isDebug = useDebug();
  const { eventsBakedReduced } = useBakedEvents();

  const [unbakeCalendar, unbakeResult] =
    calendarsApi.useUnbakeCalendarMutation();

  const [isBakedState, setIsBakedState] = useState(false);
  const [period, setPeriod] = useState([]);

  const searchQuery = calendarsApi.useSearchCalendarsQuery({
    patient: params.entry,
  });

  const [pingDevice, resultPingDevice] =
    devicesApi.useUpdatePindByDeviceIdMutation();

  const currentActiveDevices = useActiveUserDevice();

  useEffect(() => {
    pingDevice(currentActiveDevices.data?.[0]?.id);
  }, []);

  const { data: selectedPatientAsArray } = searchQuery;

  const [selectedRange, setStartDate] = useState(null);

  const setStart = (date) => {
    const from = startOfISOWeek(date);
    const to = endOfISOWeek(date);

    const bakedPeriod = isBakedPeriodsInFrameMaxBoundariesOnly({
      eventsBakedReduced,
      from,
      to,
    });

    bakedPeriod ? setIsBakedState(bakedPeriod) : setIsBakedState(false);

    const futurePoints = calendarFuturePoints({
      events: selectedPatientAsArray,
      from,
      to,
      bake: false,
    });

    setStartDate({ from, to });
    setPeriod(futurePoints);
  };

  const isDesktop = useIsDesktop();

  const handleSubmit = () => {
    /*if (isBakedState) {
      unbakeCalendar({
        patient: params.entry,
        from: isBakedState.from,
        to: isBakedState.to,
      });
    }*/
    /*dispatch(
      fills.actions.setCurrentDate({
        from: selectedRange.from.toString(),
        to: selectedRange.to.toString(),
      })
    );*/

    const queryArguments = qs.stringify({
      from: selectedRange.from.toString(),
      to: selectedRange.to.toString(),
    });
    // if (!isDesktop)
    history.push(
      `/${params.organization}/${params.entry}/fill/fillTray?${queryArguments}`
    );
  };

  useEffect(() => {
    setStart(startOfWeek(addDays(new Date(), 7), { weekStartsOn: 1 }));
  }, []);

  const today = new Date();
  const isPast = isBefore(selectedRange?.from, today);

  return (
    <Modal
      open={true}
      withOrganization
      //danger={isBakedState}
      primaryButtonDisabled={period.length === 0}
      primaryButtonText={
        /*isBakedState ? (
          <Trans>Delete fills & continue</Trans>
        ) : (*/
        <Trans>Continue</Trans>
        //)
      }
      secondaryButtonText={<Trans>Cancel</Trans>}
      onRequestClose={handleModalClose}
      warning
      modalHeading={<Trans>Select a week</Trans>}
      inPortal
      onRequestSubmit={handleSubmit}
    >
      <div className={styles.fillStartWrapper}>
        <WeekPicker
          onChange={setStart}
          startDate={selectedRange ? selectedRange : undefined}
        />
        {isPast ? (
          (<BlockNotification
            kind="warning"
            title={<Trans>Some selected dates are in the past</Trans>}
            subtitle={
              <>
                <Trans>Are you sure you want to continue?</Trans>
              </>
            }
          /> /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/ /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/ /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/ /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/ /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/ /*: isBakedState ? (
          <BlockNotification
            kind="error"
            title={<Trans>The selected dates have current fills</Trans>}
            subtitle={
              <>
                <Trans>By continuing the following period be removed:</Trans>{" "}
                {isBakedState?.from &&
                  format(new Date(isBakedState?.from), "dd.MM.yyyy")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={styles.dateIcon}
                />{" "}
                {isBakedState?.to &&
                  format(new Date(isBakedState?.to), "dd.MM.yyyy")}
                {isDebug && <ReactJson src={isBakedState} />}
              </>
            }
          />
        )*/)
        ) : period.length > 0 ? (
          <div>
            <BlockNotification
              kind="info"
              title={
                <>
                  {format(selectedRange?.from, "dd.MM.yyyy")}{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={styles.dateIcon}
                  />{" "}
                  {format(selectedRange?.to, "dd.MM.yyyy")}
                </>
              }
              subtitle={
                <Trans i18nKey="intakeCountText" count={period.length}>
                  There are <strong>name</strong> intake times in the selected
                  period.
                </Trans>
              }
            />
          </div>
        ) : (
          <BlockNotification
            kind="warning"
            title={<Trans>No intake times found in this period</Trans>}
            subtitle={<Trans>Add intake times or use another period.</Trans>}
          />
        )}
      </div>
    </Modal>
  );
  //return null;
}
