import { useAuthStatus } from "helpers/useAuth";
import React from "react";

import styles from "./styles.module.scss";

export default function ErrorInline() {
  const authStatus = useAuthStatus();
  return null;
  if (authStatus)
    return <div className={styles.errorInline}>{authStatus?.message}</div>;
  return null;
}
