import CreateOrganization from "components/SelectOrganization/CreateOrganization";
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import Device from "./Device";

import NewOrganization from "./NewOrganization";
import Patient from "./Patient";
import Success from "./Success";

export default function Onboarding() {
  const params = useParams();
  const { organization } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <Switch>
      <Route path="/:organization/onboarding/patient" component={Patient} />
      <Route path="/:organization/onboarding/device" component={Device} />
      <Route path="/:organization/onboarding/success" component={Success} />
      {/*<Route path="/" component={CreateOrganization} />*/}

      {/*<Route>
                <Redirect
                  from="/:organization/"
                  to={`/${organization}/calendar`}
                />
  </Route>*/}
    </Switch>
  );
}
