import React from "react";
import { User } from "@un/react";
import styles from "./styles.module.scss";
import { useUserById } from "helpers/useUsers";

export default function Avatar({ className, name, kind, user }) {
  const split = user && user.avatar ? user.avatar.split("/") : undefined;
  const fileName = split ? split[split.length - 1] : undefined;

  return (
    <User
      name={name}
      className={`${className} ${kind ? styles[kind] : ""}`}
      image={
        fileName
          ? `${process.env.REACT_APP_SERVER_BASE_URL}users/userimage/?file=user/resized/small/resized-${fileName}`
          : user?.auth0User?.picture
          ? user?.auth0User?.picture
          : undefined
      }
    />
  );
}
