import { generateCrudApi } from "helpers/crudGeneratorExtend";
export const accountsApi = generateCrudApi({
  name: "accounts",
  endpoints: (builder) => ({
    setDeviceToken: builder.mutation({
      query: (request) => ({
        url: `accounts/${request.id}/setDeviceToken`,
        body: request.token,
        method: "post",
      }),
    }),
  }),
});
