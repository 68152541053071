import React from "react";
import { TextInput } from "@un/react";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import IntakeTimes from "components/IntakeTimes";

import useSettingsForm from "helpers/useSettingsFormNew";
import { SettingsSidebarNoSidebar } from "components/Settings/SettingsWithSidebar";
import styles from "./styles.module.scss";
import { useHistory } from "react-router";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { organizationsApi } from "ducks/organizationsApi";

export default function OrganizationPage() {
  const params = useParams();
  const history = useHistory();

  const store = useSettingsForm({
    name: "organizations",
    api: organizationsApi,
    id: params.organization,
    hideHeaderRight: true,
    customDeleteRedirect: () => {
      history.push("/?show=always&action=orgDeleted");
    },
    //url: `/${params.organization}/organization`,
    customOverviewUrl: `/${params.organization}/advanced`,
  });

  const {
    form: { register, control, watch },
  } = store;

  return (
    <SettingsSidebarNoSidebar {...store}>
      <>
        <TextInput
          formItemClassName={styles.name}
          labelText={<Trans>Name of the organization</Trans>}
          {...register("name")}
        />
        <TextInput
          labelText={<Trans>Description</Trans>}
          {...register("meta.description")}
        />
        {/*<TextInput
            labelText={<Trans>Information url</Trans>}
            {...register("meta.url")}
          />*/}

        <h3>
          <Trans>Default intake times</Trans>
        </h3>

        <IntakeTimes control={control} register={register} watch={watch} />
        <SettingsSubmitButton {...store} />
      </>
    </SettingsSidebarNoSidebar>
  );
}
