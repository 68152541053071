import React from "react";
import { InlineLoading, Select, SelectItem } from "@un/react";
import users from "ducks/users";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import { generateUserName } from "components/UserName";
import { usersApi } from "ducks/usersApi";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import { devicesApi } from "ducks/devices";
import { nn } from "date-fns/locale";
import Filter from "components/Filter";

export default function UserSelect({
  labelText = "Patient",
  helperText,
  register,
  filterData,
}) {
  const { organization } = useParams();
  const { data } = usersApi.useGetAllUsersQuery(organization);

  const filteredData = filterData ? filterData(data) : data;

  if (!data)
    return <InlineLoading description={<Trans>Users loading...</Trans>} />;
  return (
    <>
      <Select
        labelText={labelText}
        helperText={helperText}
        {...register}
        className={styles.userSelect}
      >
        <SelectItem text="Kein Benutzer..." value="" />
        {filteredData.map((e) => {
          const text = generateUserName(e);
          return (
            <SelectItem
              disabled={e.disabled}
              key={e.id}
              text={text !== null ? text : "Unnamed user"}
              value={e.id}
            />
          );
        })}
      </Select>
    </>
  );
}
