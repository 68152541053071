import AnaboxSmart from "./devices/Anabox-smart.png";
import Anabox5 from "./devices/Anabox-5.png";
import Anabox7 from "./devices/Anabox-7.png";
import SmartPlug from "./devices/smart-plug.png";
import EinkDisplay from "./devices/eink-display.png";
import SosButton from "./devices/sos-button.png";
import Omat from "./devices/omat.png";
import Sensor from "./devices/sensor.png";
import PetTracker from "./devices/pet-tracker-collar.png";
import TailLight from "./devices/tail-light.png";
import UniversalTracker from "./devices/universal-tracker.png";
import AnaboxMini from "./devices/Anabox-mini.png";
import AnaboxTagesboxCompact from "./devices/Anabox-tagesbox-compact.png";

const deviceList = [
  { id: "anabox-smart", name: "Anabox Smart", image: AnaboxSmart },
  {
    id: "anabox-mini",
    name: "Anabox Mini",
    image: AnaboxMini,
    analog: true,
  },
  {
    id: "tagesbox-compact",
    name: "Tagesbox Compact",
    image: AnaboxTagesboxCompact,
    analog: true,
  },
  {
    id: "tagesbox",
    name: "Anabox Tagesbox",
    image: Anabox5,
    analog: true,
  },
  {
    id: "sieben-tage",
    name: "Anabox 7-Tage",
    image: Anabox7,
    //analog: true,
  },
  {
    id: "eins-sieben-tage",
    name: "Anabox 1x7",
    image: Anabox7,
    analog: true,
  },
  { id: "bike-tracker", name: "Bike Tracker", image: TailLight },
  {
    id: "universal-tracker",
    name: "Universal Tracker",
    image: UniversalTracker,
  },
  { id: "pet-tracker", name: "Pet Tracker", image: PetTracker },
  { id: "letterbox", name: "Letterbox", image: Sensor },
  { id: "fridge", name: "Fridge", image: Sensor },
  {
    id: "eink-display",
    name: "eInk Display",
    image: EinkDisplay,
    usage: ["Hausverwaltung", "Wetterstation"],
    features: [
      {
        title: "Rufknopf",
        description:
          "Weiterleitung des Fahrtwunsches an Busfahrer für Bedarfshaltestellen",
      },
      { title: "Verspätungen" },
      { title: "Zeit bis Ankunft" },
      { title: "Fahrplanauskunft" },
    ],
  },
  { id: "smart-button", name: "Smart button", image: Sensor },
  { id: "sos-button", name: "SOS Button", image: SosButton },
  { id: "sensor", name: "Dynamic sensor", image: Sensor },
  { id: "cooling-sensor", name: "Cooling sensor", image: Sensor },
  {
    id: "smart-plug",
    name: "Smart plug",
    image: SmartPlug,
    message: "Stromausfallmeldung, Ein- und Ausschalten, Temperatur",
  },
  { id: "omat", name: "Omat", image: Omat },
  { id: "doorbell-panel", name: "Doorbell panel", image: Sensor },
  {
    id: "inhalator",
    name: "Inhaler",
    image: Sensor,
    url: "https://www.medicalexpo.de/prod/cohero-health/product-105235-862408.html",
  },
  {
    id: "postcard",
    name: "Postcard printer",
    image: Sensor,
    otherUsage: ["tourists", "weddings"],
    message:
      "Ein Drucker, welcher an typischen Postkartenverkaufsorten aufgehängt wird. Mit seinem eigenen Smartphone kann man eine Postkarte mit eigenen Fotos generieren und drucken. Den selben Drucker kann man auch für Hochzeiten und so verwenden. Die Benutzer gehen einfach in das W-Lan vom Drucker (wie wenn man am Flughafen sich in ein W-Lan einwählt) und das auswählen der Fotos, etc. wird über die automatisch aufploppende Webseite gemacht.",
  },
  //Smartparkplatz
  //Switchbot
  //Wetterstation
  //Fotobox
  //LKW Planen
  //Inhalator
  //Handtuchspender
];

export default deviceList;

export function deviceByKind(device) {
  const result = deviceList.find((e) => e.id === device);
  return result;
}
