import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Loading } from "@un/react";
import useAccount from "helpers/useAccount";
import useApiErrors from "helpers/useApiErrors";
import usePushNotifications from "helpers/notifications/usePush";
export const PrivateRouteWithOrganization = ({
  component: Component,
  logout,
  ...rest
}) => {
  //const currentOrganizationMeta = useSelector(organizations.selectors.meta);

  //const currentUser = useSelector(users.selectors.current);

  const { isAuthenticated, isLoading, token, reduxToken } = useAccount();
  const apiErrors = useApiErrors();

  /*useEffect(() => {
    if (isAuthenticated) {
      if (!userLoaded) {
        dispatch(users.actions.fetchSingle());
        setUserLoaded(true);
      }
    }
  }, [currentUser?.language, isAuthenticated]);*/

  if (isLoading || !token || !reduxToken) {
    return <Loading />;
  }

  if (!isAuthenticated) return null;

  /*if (currentOrganizationMeta.error) {
    return (
      <Empty
        kind="large"
        button={
          <Button
            onClick={() => {
              dispatch(
                organizations.actions.fetch({
                  organizationId: rest.computedMatch.params.organization,
                })
              );
            }}
          >
            Reload
          </Button>
        }
      >
        Could not load organization
      </Empty>
    );
  }*/

  if (rest.computedMatch.params.entry) {
    return <Loading />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) return <Component {...props} {...rest} />;
        return (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export const PrivateRoute = ({ component: Component, logout, ...rest }) => {
  const { isAuthenticated, isLoading, token, reduxToken } = useAccount();
  const apiErrors = useApiErrors();

  if (isLoading || !token || !reduxToken) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
