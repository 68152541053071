/*import { fork } from "@redux-saga/core/effects";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import createEndpoint from "helpers/createEndpoint";
/import {
  crudOperations,
  editGenerator,
  selectors,
} from "helpers/crudGenerator";
*/
import { generateCrudApi } from "helpers/crudGeneratorExtend";
/*
export const addLatestIoTDeviceEvents = createEndpoint({
  name: "fetchLatestIoTDevice",
  fetchSuccess: (state, action, filter) => {
    state.data[action.payload.device.id].latestEntries = action.payload.message;
  },
});

export const updateShadowLight = createEndpoint({
  name: "updateShadowLight",
  fetchSuccess: (state, action, filter) => {
    state.data[action.payload.device.id].shadow = action.payload.shadow;
  },
});

export const pingDevice = createEndpoint({
  name: "pingDevice",
  fetchSuccess: (state, action, filter) => {
    console.log(action);
    state.data[action.payload.device.id].ping = action.payload.ping;
  },
});*/

export const devicesApi = generateCrudApi({
  name: "devices",
  endpoints: (builder) => ({
    getAllDevicesAdmin: builder.query({
      query: (request) => ({
        url: `devices`,
        method: "get",
      }),
      transformResponse: (response) => response.results,
    }),
    getEvents: builder.query({
      query: (request) => ({
        url: `devices/events/${request.id}`,
        method: "get",
        params: request.params,
      }),
      transformResponse: (response) => response,
    }),
    getAlarms: builder.query({
      query: (id) => ({
        url: `devices/alarms/${id}`,
        method: "get",
      }),
      //transformResponse: (response) => response,
    }),
    updateLedLightByDeviceId: builder.mutation({
      query: (request) => ({
        url: `devices/ledlight/${request.id}`,
        body: request.data,
        method: "post",
      }),
    }),
    updatePindByDeviceId: builder.mutation({
      query: (id) => ({
        url: `devices/ping/${id}`,
        method: "post",
      }),
    }),
    rebootDevice: builder.mutation({
      query: (id) => ({
        url: `devices/reboot/${id}`,
        method: "post",
      }),
    }),
    updateCaseStatus: builder.mutation({
      query: (id) => ({
        url: `devices/casestatus/${id}`,
        method: "post",
      }),
    }),
    getDeviceStatus: builder.mutation({
      query: (id) => ({
        url: `devices/getDeviceStatus/${id}`,
        method: "post",
      }),
    }),
    updateShadowLight: builder.mutation({
      query: (request) => ({
        url: `devices/shadowAlarmUpdate/${request.id}`,
        method: "post",
        body: request.body,
      }),
    }),
    registerDevice: builder.mutation({
      query: (request) => ({
        url: `devices/registerdevice/${request.id}`,
        method: "post",
        body: request.body,
      }),
      invalidatesTags: (result, error, arg) =>
        result?.activation_status === "success"
          ? [{ type: "devices", id: "devices" + "LIST" }]
          : [],
    }),
  }),
});
/*
export const rebootDevice = createEndpoint({
  name: "rebootDevice",
  fetchSuccess: (state, action, filter) => {
    state.data[action.payload.device.id].reboot = action.payload.reboot;
  },
});

export const caseStatus = createEndpoint({
  name: "caseStatus",
  fetchSuccess: (state, action, filter) => {
    state.data[action.payload.device.id].caseStatus = action.payload.caseStatus;
  },
});

const devices = createSlice({
  name: "devices",
  initialState: {
    data: {},
    ...addLatestIoTDeviceEvents.initialState,
    ...updateShadowLight.initialState,
    ...pingDevice.initialState,
    ...rebootDevice.initialState,
    ...caseStatus.initialState,
  },
  reducers: {
    ...crudOperations,
    ...addLatestIoTDeviceEvents.reducers,
    ...updateShadowLight.reducers,
    ...pingDevice.reducers,
    ...rebootDevice.reducers,
    ...caseStatus.reducers,
  },
});

export const iotDeviceEventsSaga = addLatestIoTDeviceEvents.createSaga({
  duck: devices,
  customRequest: ({ values }) => {
    const { DeviceId, ...others } = values;
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_BASE_URL}iotdevice/events/${DeviceId}`,
      params: { ...others },
    });
  },
});

export const pingDeviceSaga = pingDevice.createSaga({
  duck: devices,
  customRequest: ({ values }) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_BASE_URL}devices/ping/${values}`,
    });
  },
});

export const rebootDeviceSaga = rebootDevice.createSaga({
  duck: devices,
  customRequest: ({ values }) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_BASE_URL}devices/reboot/${values}`,
    });
  },
});

export const caseStatusSaga = caseStatus.createSaga({
  duck: devices,
  customRequest: ({ values }) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_BASE_URL}devices/casestatus/${values}`,
    });
  },
});

export const updateShadowLightSaga = updateShadowLight.createSaga({
  duck: devices,
  customRequest: ({ values }) => {
    const { DeviceId, ...others } = values;
    console.log("values", values);
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_BASE_URL}iotdevice/device/shadowAlarmUpdate/${DeviceId}`,
      data: { ...others },
    });
  },
});

devices.selectors = selectors(devices);

const sagas = [
  //  fork(iotDeviceEventsSaga),
  fork(updateShadowLightSaga),
  fork(pingDeviceSaga),
  fork(rebootDeviceSaga),
  fork(caseStatusSaga),
];

export const devicesSagas = [...editGenerator({ duck: devices }), ...sagas];

export default devices;
*/
