import React from "react";
import styles from "./calendarDetails.module.scss";
import MedicationEntry from "./MedicationEntry";
import useQs from "helpers/useQs";
import NewEntryButton from "./NewEntryButton";
import useBakedEvents, { isBakedTime } from "helpers/calendars/useBakedEvents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { Trans } from "react-i18next";
import {
  endOfDay,
  format,
  isFuture,
  isPast,
  isValid,
  startOfDay,
} from "date-fns";
import { calendarsApi } from "ducks/calendars";
import { useParams } from "react-router-dom";
import { calendarFuturePoints } from "@wirewire/helpers";
import ReactJson from "react-json-view";
import { useDebug } from "helpers/useCurrentUser";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

import { de } from "date-fns/locale";
import usePunctality from "helpers/usePunctality";
import useDayTimeList from "helpers/useDayTimeList";

export default function CalendarDetails({ iotDevicesQueryData }) {
  const { trayDate, timeCategory } = useQs();
  const debug = useDebug();
  const params = useParams();

  const from = isValid(new Date(trayDate))
    ? startOfDay(new Date(trayDate))
    : undefined;
  const to = isValid(new Date(trayDate))
    ? endOfDay(new Date(trayDate))
    : undefined;

  const { data = [] } = calendarsApi.useSearchCalendarsQuery({
    patient: params.entry,
  });

  const { eventsBakedReduced } = useBakedEvents();

  const dataForCalendar =
    from && to
      ? calendarFuturePoints({
          events: data,
          from,
          to,
        })
      : [];

  const timeCategoryList = useDayTimeList();

  const selectedTimeCategory = timeCategoryList.findIndex(
    (e) => e.key === timeCategory
  );

  const punctuality = usePunctality({
    events: dataForCalendar.filter((d) => d.bake === true),
    date: new Date(trayDate),
    iotDevicesQueryData,
    dayTimeIndex: selectedTimeCategory,
    time: isValid(new Date(trayDate))
      ? format(new Date(trayDate), "HH:mm")
      : undefined,
  });

  if (!trayDate)
    return (
      <div className={styles.noTray}>
        <FontAwesomeIcon icon={faChevronUp} />
        <br /> <Trans>Select a tray</Trans>
      </div>
    );

  const { timeIsBaked, bakedEvents } = isBakedTime({
    eventsBakedReduced,
    from,
    to,
  });

  let dataForCalendarList = isPast(trayDate)
    ? dataForCalendar.filter((d) => d.bake === true)
    : dataForCalendar.filter((d) => d.bake !== true);

  dataForCalendarList = timeCategory
    ? dataForCalendarList.filter((e) => e.timeCategory === timeCategory)
    : dataForCalendarList;

  return (
    <div className={styles.calendarDetails}>
      <div className={styles.title}>
        <div className={styles.fillInfo}>
          <div className={styles.fillInfoTitle}>
            {format(new Date(trayDate), "EEEE dd.MM.yy", { locale: de })}
          </div>
          {timeCategory ? (
            <div className={styles.fillInfoMeta}>
              {format(new Date(trayDate), "HH:mm")}{" "}
              <Trans>{timeCategory}</Trans>
            </div>
          ) : (
            <Trans>Entire day</Trans>
          )}
        </div>
        <div className={styles.fillInfoAction}>
          {
            isPast(from) && punctuality ? (
              <div className={punctuality?.classes}>
                {punctuality?.punctuality?.text}
                {punctuality.intakeTime && (
                  <small className={styles.intakeTime}>
                    {punctuality?.intakeTime} Uhr
                  </small>
                )}
              </div> /*: timeIsBaked ? (
              <div className={styles.fillText}>
                <Trans>Day is filled</Trans>
              </div>
            )*/
            ) : (
              <Trans>Planning</Trans>
            )
            /*<NewEntryButton
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              newDate={trayDate}
              newTimeCategory={timeCategory}
              kind="primary"
              small={false}
              iconReverse={false}
            >
              <Trans>Intake</Trans>
            </NewEntryButton>*/
          }
        </div>
      </div>
      {dataForCalendarList.map((e) => {
        return <MedicationEntry details entry={e} />;
      })}

      {dataForCalendarList.length === 0 && (
        <div className={styles.noEntries}>
          <Trans>No intakes</Trans>
        </div>
      )}

      {/*debug && (
        <div>
          <h3>Debug</h3>
          <ReactJson src={dataForCalendar} collapsed />
          <h3>Punctuality</h3>
          <ReactJson src={punctuality} collapsed />
        </div>
      */}
    </div>
  );
}
