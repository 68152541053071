import React, { createContext, useEffect, useState } from "react";
import "./scss/ui.scss";

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./components/Login";
import Settings from "./components/Settings";
import { PrivateRoute, PrivateRouteWithOrganization } from "modules/Auth";
import Logout from "components/Logout";
import FillProcess from "components/FillProcess";
import ErrorBoundary, {
  ErrorBoundaryReplacement,
} from "components/ErrorBoundary";
import Scanner from "components/Scanner";
import PwaShadow from "components/PwaShadow";
import { UNCoreProvider } from "@un/react";
import { Auth0Provider } from "./contexts/auth0-context";
import SelectOrganization from "components/SelectOrganization";
import CreateOrganization from "components/SelectOrganization/CreateOrganization";
import Account from "components/Account";
import ErrorInline from "components/ErrorInline";
import useEventMessages from "helpers/useEventMessages";
import useTouch from "helpers/useTouch";
import ReactJson from "react-json-view";
import DevicesList from "components/DevicesList";
import NotificationsProvider, {
  NotificationsProviderWeb,
} from "helpers/notifications/PushContext";
import { Capacitor } from "@capacitor/core";
import Onboarding from "components/Onboarding";

const history = createBrowserHistory();

window.setFrontendPipeline = () => {};

function App() {
  const messageHandler = (event) => {
    if (JSON.parse(event.data)["HISTORY"]) {
      history.goBack();
    }
  };

  const { port, postMessage, message, lastMessage } =
    useEventMessages(messageHandler);
  const [token, setToken] = useState(false);
  const touch = useTouch();

  useEffect(() => {
    if (message?.REFRESH_TOKEN /*&& message?.REFRESH_TOKEN !== "not found"*/) {
      setToken(true);
    }
  }, [message?.REFRESH_TOKEN]);

  if (token === false && window.IS_APP) {
    return (
      <div>
        <br />
        <br />
        Debug: waiting for token!
        <br />
        <ReactJson src={message} />
        <h4>Last message</h4>
        <ReactJson src={lastMessage} />
        {token}
      </div>
    );
  }

  const ErrorBoundryComponent =
    process.env.NODE_ENV === "development"
      ? ErrorBoundaryReplacement
      : ErrorBoundary;

  const isNative = Capacitor.isNativePlatform();

  const NotificationsProviderSelector = isNative
    ? NotificationsProvider
    : NotificationsProviderWeb;

  return (
    <div className="App">
      <UNCoreProvider touch={touch}>
        <PwaShadow />
        <ErrorInline />
        <ErrorBoundryComponent>
          <Auth0Provider
            history={history}
            port={port}
            postMessage={postMessage}
            message={message}
            lastMessage={lastMessage}
          >
            <NotificationsProviderSelector>
              <Router history={history}>
                <Switch>
                  <Route path="/scanner" component={Scanner} />
                  <Route path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <PrivateRoute path="/account" component={Account} />
                  <PrivateRoute
                    path="/:organization/onboarding/:step?"
                    component={Onboarding}
                  />
                  <PrivateRoute path="/deviceslist" component={DevicesList} />
                  <PrivateRouteWithOrganization
                    path="/:organization/:patient?/fill"
                    component={FillProcess}
                  />
                  <PrivateRoute
                    path="/createOrganization/:entry?/:detail?"
                    component={CreateOrganization}
                  />
                  <PrivateRouteWithOrganization
                    path="/:organization/:patient?/:action?/:detail?"
                    component={Settings}
                  />
                  <PrivateRoute path="/" component={SelectOrganization} />
                </Switch>
              </Router>
            </NotificationsProviderSelector>
          </Auth0Provider>
        </ErrorBoundryComponent>
      </UNCoreProvider>
    </div>
  );
}

export default App;
