import React, { useState } from "react";
import useSettingsOverview from "helpers/useSettingsOverviewNew";
import SettingsSidebar from "components/Settings/SettingsWithSidebar";
import { Button, Modal, Checkbox, Item } from "@un/react";
import FillDetails from "./FillDetail";
import MedicationIcon from "components/MedicationIcon";
import pillList from "helpers/pillList";
import { Trans } from "react-i18next";

import styles from "./styles.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { calendarsApi } from "ducks/calendars";
import { calendarFuturePoints, compareMedications } from "@wirewire/helpers";
import useQs from "helpers/useQs";
import MedicationName from "components/MedicationName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { format } from "date-fns";
import qs from "qs";
import CrudButton from "components/CrudButton";
import UserDetails from "components/UserDetails";
import { useActiveUser } from "helpers/useUsers";
import { SidebarBackButtonWithLink } from "components/SidebarBackButton";
import ReactJson from "react-json-view";
import { isDesktop } from "react-device-detect";
import useIsDesktop from "helpers/useIsDesktop";

const BackLink = ({ backLink }) => (
  <SidebarBackButtonWithLink to={backLink} showOnDesktop />
);

const SidebarHeaderButton = () => <></>;
const SidebarSearch = () => <></>;

export default function Fill() {
  const { entry, medication, organization } = useParams();

  const [openInfo, setOpenInfo] = useState(true);

  const queryArguments = useQs();
  const currentMedication = queryArguments.medication;
  const from = new Date(queryArguments.from);
  const to = new Date(queryArguments.to);

  const { data: selectedPatientAsArray } = calendarsApi.useSearchCalendarsQuery(
    {
      patient: entry,
    }
  );

  var events = calendarFuturePoints({
    events: selectedPatientAsArray,
    from,
    to,
  });

  const resultsByMedication = events.reduce((result, cur) => {
    const found = result.findIndex((e) => compareMedications(e, cur));

    if (found !== -1) {
      result[found] = {
        ...result[found],
        entries: [...result[found].entries, cur],
      };
      return result;
    }
    return [...result, { medication: cur.medication, ...cur, entries: [cur] }];
  }, []);

  const isDesktop = useIsDesktop();

  const settingsOverviewB = {
    filteredDataArray: resultsByMedication,
    namePluralUpperLetter: <Trans>filling aid</Trans>,
    allQuery: { isSuccess: true },
    entry: medication,
    allowEmptyDetails: isDesktop,
  };
  const activeUser = useActiveUser();
  const [fillStatus, setFillStatus] = useState([]);
  const [fillFinished, setFillFinished] = useState(false);

  console.log("fillStatusfillStatusfillStatus", medication);
  const history = useHistory();
  return (
    <>
      <Modal
        open={fillFinished}
        onRequestSubmit={() =>
          history.push(`/${organization}/calendar/${entry}`)
        }
        onRequestClose={() =>
          history.push(`/${organization}/calendar/${entry}`)
        }
        narrow
        modalLabel={<Trans>Info</Trans>}
        modalHeading={<Trans>Filling aid finished</Trans>}
        primaryButtonText={<Trans>Continue</Trans>}
      >
        <Trans>The filling has been completed.</Trans>
      </Modal>

      <SettingsSidebar
        settingsOverview={settingsOverviewB}
        active={medication ? true : false}
        components={{ BackLink, SidebarHeaderButton, SidebarSearch }}
        details={
          <FillDetails
            events={events}
            resultsByMedication={resultsByMedication}
            selectedPatientAsArray={selectedPatientAsArray}
            fillStatus={fillStatus}
            setFillStatus={setFillStatus}
          />
        }
        backLink={`/${organization}/calendar/${entry}`}
        intro={
          <>
            <Modal
              open={openInfo}
              onRequestSubmit={() => setOpenInfo(false)}
              onRequestClose={() => setOpenInfo(false)}
              narrow
              modalLabel={<Trans>Info</Trans>}
              modalHeading={<Trans>Befüllhilfe</Trans>}
              primaryButtonText={<Trans>Continue</Trans>}
            >
              <Trans>
                Im nächsten Schritt wird die Befüllung der Medikamente
                visualisiert.
              </Trans>
              <Trans>
                Die zugehörigen Becher leuchten am Medikamentenspender um die
                Befüllung zu erleichtern.
              </Trans>
            </Modal>
            <UserDetails user={activeUser} className={styles.patientSummary} />
            <div className={styles.date}>
              <Trans>Timeframe</Trans>: {format(from, "dd.MM.yyyy")}{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                className={styles.dateIcon}
              />{" "}
              {format(to, "dd.MM.yyyy")}
            </div>
          </>
        }
        customDetailLink={(e) => {
          const queryArgumentsNew = qs.stringify({
            ...queryArguments,
            medication: {
              medication: e.medication,
              abdaMedication: e.abdaMedication,
            },
          });

          return `/${organization}/${entry}/fill/fillTray/${e.id}/?${queryArgumentsNew}`;
        }}
        afterSidebar={
          <div className={styles.submitWrapper}>
            <Button
              disabled={resultsByMedication.length > fillStatus.length}
              onClick={() => setFillFinished(true)}
            >
              <Trans>Finish filling</Trans>
            </Button>
          </div>
        }
        item={({ e, ...other }) => {
          return (
            <Item
              active={compareMedications(currentMedication, e)}
              additional={
                <Checkbox
                  type="checkbox"
                  id={`name-${21}`}
                  checked={fillStatus.includes(e.id)}
                />
              }
              kind="horizontal"
              subContent={
                <>
                  {e.entries.length} <Trans>Intake times</Trans>
                </>
              }
              title={
                <MedicationName
                  entry={e.entries[0]}
                  //medicationData={medication}
                />
              }
              wrapper="sidebar"
            />
          );
        }}
      ></SettingsSidebar>
    </>
  );
}
