import useMedicationById from "helpers/useMedicationById";
import React from "react";
import { Trans } from "react-i18next";

function capitalize(name) {
  if (!name) return undefined;

  const arr = name.toLowerCase().split(" ");

  for (var i = 0; i < arr.length; i++) {
    arr[i] =
      arr[i].length > 3
        ? arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        : arr[i];
  }
  return arr.join(" ");
}

export default function MedicationName({ entry, medicationData }) {
  if (!entry) return <>Loading...</>;
  const name = entry?.medicationData?.Kurzname
    ? entry?.medicationData?.Kurzname
    : entry.localMedicationData?.meta?.name
    ? entry.localMedicationData.meta.name.replace(/ .*/, "")
    : undefined;

  return <>{name ? capitalize(name) : <Trans>Unknown medication</Trans>}</>;
}

export function MedicationNameNew({ entry }) {
  const medicationData = useMedicationById(entry?.medication);
  return (
    <>
      {entry?.medicationData?.Kurzname ? (
        entry?.medicationData?.Kurzname
      ) : entry.localMedicationData?.meta?.name ? (
        entry.localMedicationData?.meta.name.replace(/ .*/, "")
      ) : (
        <Trans>Unknown medication</Trans>
      )}
    </>
  );
}
