import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { InlineLoading } from "@un/react";

import styles from "./battery.module.scss";
import { devicesApi } from "ducks/devices";
import { deviceByKind } from "helpers/deviceList";

export const scaleBatteryRange = (value) => {
  var istart = 3300,
    istop = 4000,
    ostart = 0,
    ostop = 100;

  const calcValue =
    ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
  return calcValue > 100 ? 100 : calcValue;
};

export const useBatteryStatus = ({ currentDeviceClass, level }) => {
  const levelScaled = scaleBatteryRange(level);
  const color =
    levelScaled >= 50 ? "green" : levelScaled >= 30 ? "orange" : "red";
  const batteryUnknown = levelScaled < 10 && currentDeviceClass !== "memo";
  return { batteryUnknown, color, levelScaled };
};

const Battery = ({ device, className, currentDeviceClass, level, style }) => {
  const result = devicesApi.useGetSingleDevicesQuery(device.id, {
    skip: !device.id || deviceByKind(device.kind)?.analog === true,
  });

  const { data, isFetching } = result;

  const { batteryUnknown, color, levelScaled } = useBatteryStatus({
    currentDeviceClass,
    level: data?.deviceStatus?.batLevel ? data.deviceStatus?.batLevel : level,
  });

  const batteryWidth = levelScaled;

  const batteryClasses = classNames(className, {
    [styles.battery]: true,
    [styles[color]]: true,
    [styles.batteryUnknown]: batteryUnknown,
  });

  if (isFetching) {
    return <InlineLoading />;
  }

  if (deviceByKind(device.kind)?.analog === true) return null;

  if (batteryUnknown) {
    return (
      <svg
        className={batteryClasses}
        viewBox="0 0 640 320"
        version="1.1"
        style={style}
      >
        <path d="M287.091797,253.662109 L287.091797,238.037109 C287.091797,220.133374 290.346973,205.240945 296.857422,193.359375 C303.367871,181.477805 314.760986,169.189517 331.037109,156.494141 C350.405696,141.194585 362.897433,129.313193 368.512695,120.849609 C374.127958,112.386025 376.935547,102.29498 376.935547,90.5761719 C376.935547,76.9042285 372.378301,66.4062866 363.263672,59.0820312 C354.149043,51.7577759 341.04696,48.0957031 323.957031,48.0957031 C308.494714,48.0957031 294.171941,50.2929468 280.988281,54.6875 C267.804622,59.0820532 254.946677,64.3717139 242.414062,70.5566406 L221.90625,27.5878906 C254.94678,9.19587158 290.346816,0 328.107422,0 C360.008623,0 385.317615,7.81242188 404.035156,23.4375 C422.752698,39.0625781 432.111328,60.6281177 432.111328,88.1347656 C432.111328,100.341858 430.320981,111.206007 426.740234,120.727539 C423.159487,130.249071 417.747758,139.322874 410.504883,147.949219 C403.262008,156.575564 390.770271,167.80592 373.029297,181.640625 C357.892502,193.522195 347.760768,203.369102 342.633789,211.181641 C337.50681,218.99418 334.943359,229.492122 334.943359,242.675781 L334.943359,253.662109 L287.091797,253.662109 Z M277.082031,331.542969 C277.082031,306.966023 289.044802,294.677734 312.970703,294.677734 C324.689512,294.677734 333.641245,297.89222 339.826172,304.321289 C346.011099,310.750358 349.103516,319.82416 349.103516,331.542969 C349.103516,343.099016 345.970409,352.254198 339.704102,359.008789 C333.437794,365.76338 324.52675,369.140625 312.970703,369.140625 C301.414656,369.140625 292.544302,365.84476 286.359375,359.25293 C280.174448,352.6611 277.082031,343.424539 277.082031,331.542969 Z" />

        <g transform="translate(0 .94)">
          <path d="M500,60.0605469 L500,28.0605469 L560,28.0605469 C586.51,28.0605469 608,49.5505469 608,76.0605469 L608,92.0605469 L616,92.0605469 C629.255,92.0605469 640,102.805547 640,116.060547 L640,260.060547 C640,273.315547 629.255,284.060547 616,284.060547 L608,284.060547 L608,300.060547 C608,326.570547 586.51,348.060547 560,348.060547 L416,348.060547 L416,316.060547 L560,316.060547 C568.823,316.060547 576,308.883547 576,300.060547 L576,252.060547 L608,252.060547 L608,124.060547 L576,124.060547 L576,76.0605469 C576,67.2375469 568.823,60.0605469 560,60.0605469 L500,60.0605469 Z M142,60.0605469 L48,60.0605469 C39.177,60.0605469 32,67.2375469 32,76.0605469 L32,300.060547 C32,308.883547 39.177,316.060547 48,316.060547 L215,316.060547 L215,348.060547 L48,348.060547 C21.49,348.060547 0,326.570547 0,300.060547 L0,76.0605469 C0,49.5505469 21.49,28.0605469 48,28.0605469 L142,28.0605469 L142,60.0605469 Z M52,86.0605469 L52,295.060547 L52,86.0605469 Z" />
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={batteryClasses}
      viewBox="0 0 640 320"
      version="1.1"
      style={style}
    >
      <g
        id="Website"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="battery-empty-light">
          <path
            d="M560,32 C568.823,32 576,39.177 576,48 L576,96 L608,96 L608,224 L576,224 L576,272 C576,280.823 568.823,288 560,288 L48,288 C39.177,288 32,280.823 32,272 L32,48 C32,39.177 39.177,32 48,32 L560,32 L560,32 Z M560,0 L48,0 C21.49,0 0,21.49 0,48 L0,272 C0,298.51 21.49,320 48,320 L560,320 C586.51,320 608,298.51 608,272 L608,256 L616,256 C629.255,256 640,245.255 640,232 L640,88 C640,74.745 629.255,64 616,64 L608,64 L608,48 C608,21.49 586.51,0 560,0 Z"
            fill="#000000"
            fillRule="nonzero"
          ></path>
          <rect
            className={styles.batteryStatus}
            fill="#D8D8D8"
            x="52"
            y="58"
            width={batteryWidth * 5}
            height="209"
          ></rect>
        </g>
      </g>
      {/*<text x="255" y="240" class="Rrrrr">?</text>*/}
    </svg>
  );
};

Battery.propTypes = {
  /**
   * The current level 1-100
   */
  level: PropTypes.number,
  /**
   * Custom styling
   */
  style: PropTypes.object,
};

Battery.defaultProps = {
  level: 0,
};

export default Battery;
