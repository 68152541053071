import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./useSettingsForm.module.scss";
import { useForm } from "react-hook-form";
import flatten from "./flatten";
import useQs from "./useQs";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

function useStore({
  additionalHeader,
  customSubmit,
  prepareFormEntry = (e) => {
    return e;
  },
  customOverviewUrl,
  getEntryById,
  api,
  id,
  idElement = (e) => (e ? e.id : undefined),
  disableReset,
  disableDelete,
  customNewEntrySuccessText,
  customDeleteQuestion,
  customDeleteRedirect,
  //name,
  prepareSubmit = (values) => values,
  newEntryData,
  duck,
  titleKey,
  entryName,
  url,
  hideTitle,
  hideHeaderRight,
  entryParam = "entry",
  withOrganization = true,
  settings = {},
}) {
  const name = api.name;
  const params = useParams();

  const organizationId = params.organization;
  const overviewUrl = customOverviewUrl
    ? customOverviewUrl
    : `/${organizationId}/${name}/`;
  const [urlIdState, setUrlId] = useState();
  const [urlState, setUrl] = useState();

  const search = useQs();

  const urlId = id ? id : params[entryParam] ? params[entryParam] : "new";

  console.log("urlIdurlIdurlIdurlId", urlId);

  const singleQuery = api[`useGetSingle${capitalizeFirstLetter(name)}Query`](
    urlId,
    { skip: urlId === "new" }
  );

  const { data, isSuccess } = singleQuery;
  const [createSingle, resultCreateSingle] =
    api[`useCreateSingle${capitalizeFirstLetter(name)}Mutation`]();
  const [updateSingle, resultUpdateSingle] =
    api[`useUpdateSingle${capitalizeFirstLetter(name)}Mutation`]();
  const [deleteSingle, resultDeleteSingle] =
    api[`useDeleteSingle${capitalizeFirstLetter(name)}Mutation`]();

  const entryData =
    urlId === "new" && newEntryData
      ? newEntryData
      : urlId === "new"
      ? {}
      : data;

  const form = useForm({
    defaultValues: prepareFormEntry(entryData),
  });

  const { handleSubmit, register, setValue, reset, getValues } = form;
  // Reset form
  const resetForm = () => {
    const customEntryData = prepareFormEntry(entryData);

    if (
      (urlIdState !== urlId || urlState !== window.location.pathname) &&
      customEntryData
    ) {
      setUrlId(urlId);
      setUrl(window.location.pathname);

      const { medicationData, ...oldValues } = getValues();
      let flatValues = flatten(oldValues);

      Object.entries(flatValues).forEach(([e, i]) => {
        if (typeof i !== "string") {
          //setValue(e, i);
        } else {
          setValue(e, undefined);
        }
      });
    }

    reset(customEntryData);
  };

  useEffect(() => {
    if (urlId === "new" || data?.id) resetForm();
  }, [data?.id, urlId]);

  useEffect(() => {
    if (isSuccess === true) resetForm();
  }, [isSuccess]);

  const onSubmit = async (values) => {
    values = prepareSubmit(values, entryData);
    var continueSubmit = true;
    if (customSubmit) {
      continueSubmit = customSubmit(values, organizationId);
    }
    if (continueSubmit) {
      const payload = {
        ...values,
        organization: withOrganization ? organizationId : undefined,
      };
      if (urlId === "new" || urlId === undefined) {
        try {
          await createSingle({
            values: payload,
          });
        } catch (err) {
          console.error("Failed to save the post: ", err);
        }
      } else {
        try {
          const { id, ...data } = payload;
          await updateSingle({
            values: data,
            id: id ? id : urlId,
          });
        } catch (err) {
          console.error("Failed to save the post: ", err);
        }
      }
    }
  };

  const deleteEntry = async (values) => {
    try {
      await deleteSingle({
        id: urlId,
      });
    } catch (err) {
      console.error("Failed to save the post: ", err);
    }
  };

  const entryDataId = idElement ? idElement(entryData) : entryData?.id;

  const entryTitle =
    entryName && entryName(entryData) ? entryName(entryData) : undefined;

  const urlNew = url || `/${params.organization}/${name}`;

  return {
    onSubmit,
    entryData,
    idElement,
    setValue,
    search,
    //  latestCrudId,
    entryDataId,
    entryName,
    overviewUrl,
    url: urlNew,
    customDeleteRedirect,
    entryTitle,
    handleSubmit,
    resetForm,
    register,
    params,
    disableDelete,
    name,
    deleteEntry,
    reset,
    organizationId,
    regularFormStyle: styles.regularForm,
    singleQuery,
    resultCreateSingle,
    resultUpdateSingle,
    resultDeleteSingle,
    urlId,
    form,
  };
}
export default useStore;
