import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const abdasApi = generateCrudApi({
  name: "abdas",
  endpoints: (builder) => ({
    /* getAbdas: builder.query({
      query: (request) => ({
        url: `/updatetimes/${request.id}`,
        method: "post",
        body: request.data,
      }),
    }),*/
  }),
});
