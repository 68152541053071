import axios from "axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setupInterceptors: (store, token, history, loginWithRedirect) => {
    axios.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.authorization = "Bearer " + token;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // catches if the session ended!
        console.log("error", error);
        if (error) {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.message === "User does not exist"
          ) {
            // history.push("/new-user");
          }

          /*if (
            error.response &&
             error.response.status === 401
          ) {
            localStorage.clear();
            //TOD0: check redirect loginWithRedirect();
            history.push("/");
          }*/

          store.dispatch({ type: "auth/updateStatus", payload: error });
          return Promise.reject(error);
        }
      }
    );
  },
};
