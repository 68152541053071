import React, { useEffect } from "react";

import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDayTimeList from "helpers/useDayTimeList";

import { BlockNotification } from "@un/react";
import { Trans } from "react-i18next";
import TimeInputWithOverride from "components/TimeInput/TimeInputWithOverride";
import { usersApi } from "ducks/usersApi";
import dayTimeListWithIcons from "helpers/dayTimeList";
import ReactJson from "react-json-view";
import { format } from "date-fns-tz";
import { useActiveUser } from "helpers/useUsers";

export default function IntakeTimes({
  entryData,
  id,
  register,
  control,
  watch,
}) {
  const dayTimeList = useDayTimeList();

  const watchIntake = {};

  dayTimeList.forEach((f, i) => {
    watchIntake[`intake-${f.key}`] = watch(`meta.intake-${f.key}`);
  });

  const { data, refetch } = usersApi.useGetCalendarByIdQuery(
    {
      id,
      data: watchIntake,
    },
    { skip: id === "new" }
  );

  useEffect(() => {
    refetch();
  }, [entryData]);

  const activeUser = useActiveUser();

  const Find = ({ data = [], timeSlot }) => {
    const results = data && data.filter((e) => e.timeCategory === timeSlot.key);
    const count = results.length;
    if (
      results.length > 0 &&
      format(new Date(results[0].date), "HH:mm", {
        timeZone: activeUser?.timezone,
      }) !== watchIntake[`intake-${timeSlot.key}`]
    )
      return (
        <BlockNotification
          className={styles.warning}
          title={<Trans>Attention</Trans>}
          kind="warning"
          subtitle={
            <>
              {format(new Date(results[0].date), "HH:mm", {
                timeZone: activeUser?.timezone,
              })}{" "}
              zu {watchIntake[`intake-${timeSlot.key}`]}
              <Trans count={results.length} i18nKey="UPDATEINTAKETIMES">
                This will update {{ count }} intake times
              </Trans>
              <br />
              Von{" "}
              <b>
                {format(new Date(results[0].date), "HH:mm", {
                  timeZone: activeUser?.timezone,
                })}{" "}
                Uhr{" "}
              </b>{" "}
              {watchIntake[`intake-${timeSlot.key}`] ? (
                <>
                  zu <b>{watchIntake[`intake-${timeSlot.key}`]} Uhr</b>.
                </>
              ) : (
                <>zu übergeordneter Zeit (Organisation, Standardzeit).</>
              )}
            </>
          }
          hideCloseButton
          lowContrast
        />
      );
    return null;
  };

  return (
    <div className={styles.intakeTimeWrapper}>
      {dayTimeList.map((f, i) => {
        return (
          <div className={styles.intakeTime}>
            <div className={styles.intakeTimeContent}>
              <div className={styles.intakeTimeIcon}>
                <FontAwesomeIcon icon={dayTimeListWithIcons[f.key].icon} />
              </div>
              <div className={styles.intakeTimeTitle}>
                <Trans>{f.name}</Trans>
              </div>
              <TimeInputWithOverride
                defaultTime={f.time}
                name={`meta.intake-${f.key}`}
                control={control}
              />
            </div>
            <Find data={data} timeSlot={f} />
          </div>
        );
      })}
    </div>
  );
}
