import React from "react";
import { Button, Module, ModuleBody, Wrapper } from "@un/react";
import { Trans } from "react-i18next";
import {
  faStamp,
  faQuestion,
  faUser,
  faExchangeAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles.module.scss";

import { faClinicMedical } from "@fortawesome/pro-light-svg-icons";
import { useSidebarData } from "components/Settings/SettingsList";
import { devicesApi } from "ducks/devices";
import { useAuth0 } from "contexts/auth0-context";

import InlineLoadingLarge from "components/InlineLoadingLarge";

import useCurrentOrganization from "helpers/organizations/useCurrentOrganization";
import UserName, { CurrentUserName, UserNameNew } from "components/UserName";
import image from "./management-background.svg";

export function SettingsAdvancedButton({ content, icon, title }) {
  return (
    <Module noMargin>
      <Link to={content?.to} className={styles.buttonLink}>
        <ModuleBody>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={content?.icon} />
          </div>
          <span>{title}</span>
        </ModuleBody>
      </Link>
    </Module>
  );
}

export function SettingsAdvancedLink({ icon, title, to, external, ...other }) {
  const content = (
    <>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <span>{title}</span>
    </>
  );
  if (external) return <a {...other}>{content}</a>;
  return (
    <Link to={to} {...other}>
      {content}
    </Link>
  );
}

export default function SettingsAdvanced() {
  const { devices, medications, organization, stock } = useSidebarData();

  const { organization: currentOrganzation } = useParams();

  const { data = [] } = devicesApi.useGetAllDevicesQuery(currentOrganzation);

  const { data: currentOrganization } = useCurrentOrganization();

  const auth0 = useAuth0();

  const { logout } = auth0;
  if (!currentOrganization)
    return (
      <InlineLoadingLarge description={<Trans>Settings loading...</Trans>} />
    );

  return (
    <div className={styles.wrapper}>
      <Wrapper pageWidth="md" mobilePageWidth="full">
        <Module noMargin className={styles.main} dark>
          <ModuleBody>
            <img src={image} className={styles.background} />
            {/*<FontAwesomeIcon
              icon={faClinicMedical}
              className={styles.mainIcon}
  />*/}
            <div className={styles.mainContent}>
              <h3>
                {currentOrganization.isLoading ? (
                  <Trans>Loading...</Trans>
                ) : currentOrganization.data?.name ? (
                  currentOrganization.data?.name
                ) : currentOrganization.data?.kind === "private" ? (
                  <Trans>Settings</Trans>
                ) : (
                  <>
                    <Trans>Hello</Trans> <CurrentUserName />!
                  </>
                )}
              </h3>
              <p>
                {currentOrganization.data?.kind === "professional" && (
                  <Trans>professional organization</Trans>
                )}
              </p>
            </div>
          </ModuleBody>
        </Module>
        <div className={styles.settingsAdvancedButtons}>
          {currentOrganization.kind === "professional" && (
            <SettingsAdvancedButton
              content={medications}
              title={<Trans>{medications.name}</Trans>}
            />
          )}
          {currentOrganization.kind === "professional" && (
            <SettingsAdvancedButton
              content={stock}
              title={<Trans>Stock</Trans>}
            />
          )}
          {/*<SettingsAdvancedButton
            content={devices}
            title={
              <>
                {data && data.length} <Trans>Devices</Trans>
              </>
            }
          />*/}
          {currentOrganization.kind === "professional" && (
            <SettingsAdvancedButton
              content={organization}
              title={<Trans>Organization</Trans>}
            />
          )}
        </div>
        <div className={styles.settingsAdvancedLinks}>
          <SettingsAdvancedLink
            icon={faUser}
            to={`/account?organization=${currentOrganzation}`}
            title={<Trans>Manage account</Trans>}
          />
          <SettingsAdvancedLink
            icon={faExchangeAlt}
            to="/?show=always"
            title={<Trans>Switch group</Trans>}
          />
          <SettingsAdvancedLink
            icon={faStamp}
            to={`/${currentOrganzation}/docs/imprint`}
            title={<Trans>Impressum/AGB</Trans>}
          />
          <SettingsAdvancedLink
            icon={faQuestion}
            external
            href={`${process.env.REACT_APP_SERVER_WEBSITE_URL}/blog`}
            target="_blank"
            title={<Trans>Hilfe & Support</Trans>}
          />

          <Button
            className={styles.logout}
            kind="tertiary"
            icon={<FontAwesomeIcon icon={faSignOut} />}
            onClick={() => logout()}
          >
            <Trans>Abmelden</Trans>
          </Button>
        </div>
      </Wrapper>
    </div>
  );
}
