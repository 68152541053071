import React from "react";
import styles from "./settingsSidebar.module.scss";
import { Empty, Search, SidebarScroll, Sidebar } from "@un/react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import ButtonRouter from "components/ButtonRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { faLayerPlus } from "@fortawesome/pro-light-svg-icons";
import Repeater from "components/Repeater";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import mediaQueries from "helpers/mediaQueries";
import i18next from "i18next";
import HelmetTitle from "components/HelmetMeta/HelmetTitle";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import Status from "components/Status";

import InlineLoadingLarge from "components/InlineLoadingLarge";
import { SettingsHeader } from "./SettingsHeader";
import useIsDesktop from "helpers/useIsDesktop";

const SidebarNewButton = ({ newLink, name }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();
  if (!isDesktop) {
    return (
      <ButtonRouter to={newLink} icon={<FontAwesomeIcon icon={faPlus} />}>
        <Trans
          i18nKey="AddNewNameSingular"
          values={{
            name: t(`${name}-SINGULAR`),
          }}
        />{" "}
      </ButtonRouter>
    );
  }
  return null;
};

const SidebarHeaderButton = ({
  customButtons,
  organizationId,
  duckName,
  name,
}) => {
  return (
    <>
      {customButtons ? (
        customButtons
      ) : (
        <ButtonRouter
          to={`/${organizationId}/${duckName || name}/new`}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          {<Trans>New</Trans>}
        </ButtonRouter>
      )}
    </>
  );
};

const SidebarSearch = ({ className, search }) => {
  return <div className={className}>{search}</div>;
};

const SettingsWithSidebar = ({
  actions,
  active,
  afterSidebar,
  children,
  empty,
  components: customComponents,
  search,
  customButtons,
  customDetailLink,
  details,
  intro,
  backLink,
  item,
  SearchComponent,
  sortData,
  settingsOverview,
}) => {
  const params = useParams();
  const history = useHistory();
  const isDesktopOrLaptop = useMediaQuery(mediaQueries.mediaMaxXs);
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const defaultComponents = {
    SidebarNewButton,
    SidebarHeaderButton,
    SidebarSearch,
  };

  const components = { ...defaultComponents, ...customComponents };

  if (
    !settingsOverview.entry &&
    settingsOverview.dataArray?.[0] &&
    isDesktopOrLaptop
  ) {
    history.push(
      `/${settingsOverview.organizationId}/${
        settingsOverview.duckName || settingsOverview.name
      }/${settingsOverview.dataArray[0].id}/`
    );
    return <InlineLoadingLarge description={<Trans>Loading...</Trans>} />;
  }

  const newLink = `/${settingsOverview.organizationId}/${
    settingsOverview.duckName || settingsOverview.name
  }/new`;

  const filteredDataArray =
    sortData && settingsOverview.filteredDataArray
      ? sortData(settingsOverview.filteredDataArray)
      : settingsOverview.filteredDataArray;

  return (
    <>
      <HelmetTitle>{settingsOverview.nameSingular}</HelmetTitle>
      <div className={styles.sidebarWrapper}>
        <Sidebar
          active={active !== undefined ? active : params.entry}
          sidebar={
            <>
              <SettingsHeader
                {...settingsOverview}
                actions={actions}
                backLink={backLink}
                components={components}
                customButtons={customButtons}
                isDesktopOrLaptop={isDesktopOrLaptop}
                search={
                  search || (
                    <Search
                      onChange={settingsOverview.updateSearch}
                      value={settingsOverview.searchObject?.search}
                      placeholder={i18next.t("Search...")}
                      className={styles.searchField}
                    />
                  )
                }
              />
              <SidebarScroll>
                {settingsOverview?.getSaved && settingsOverview.getSaved()}
                {intro && intro}

                <Status
                  query={settingsOverview.allQuery}
                  fetching={null}
                  loading={
                    filteredDataArray &&
                    filteredDataArray.length !== 0 ? null : (
                      <Empty>
                        <>
                          <Trans>Loading</Trans>{" "}
                          <Trans>{settingsOverview.name}</Trans>...
                        </>
                      </Empty>
                    )
                  }
                >
                  {filteredDataArray &&
                  filteredDataArray.length === 0 &&
                  settingsOverview.allQuery.isSuccess === true ? (
                    <Empty
                      icon={
                        !isDesktop ? (
                          <FontAwesomeIcon
                            icon={faLayerPlus}
                            size="5x"
                            className={styles.emptyIcon}
                          />
                        ) : undefined
                      }
                      title={
                        <Trans
                          i18nKey="noNameFound"
                          values={{ name: t(settingsOverview.name) }}
                        />
                      }
                      button={
                        <components.SidebarNewButton
                          newLink={newLink}
                          name={settingsOverview.name}
                        />
                      }
                    />
                  ) : (
                    <Repeater
                      addButton={
                        <ButtonRouter
                          to={newLink}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                        >
                          <Trans>Add new {settingsOverview.name}</Trans>
                        </ButtonRouter>
                      }
                      hideAddButton
                      className={styles.repeater}
                    >
                      {filteredDataArray.map((e, i) => (
                        <NavLink
                          key={i}
                          to={
                            customDetailLink
                              ? customDetailLink(e)
                              : `/${settingsOverview.organizationId}/${
                                  settingsOverview.duckName ||
                                  settingsOverview.name
                                }/${e.id}/${settingsOverview.search}`
                          }
                        >
                          {item({
                            e,
                            kind: "horizontal",
                            wrapper: "sidebar",
                            active: e.id === params.entry,
                          })}
                        </NavLink>
                      ))}
                    </Repeater>
                  )}
                </Status>
                {afterSidebar && afterSidebar}
              </SidebarScroll>
            </>
          }
        >
          {!settingsOverview.entry &&
          settingsOverview.allQuery.isSuccess &&
          !settingsOverview.allowEmptyDetails ? (
            <>
              <Empty
                icon={
                  <FontAwesomeIcon
                    icon={faLayerPlus}
                    size="5x"
                    className={styles.emptyIcon}
                  />
                }
                title={
                  <Trans
                    i18nKey="noNameFound"
                    values={{ name: t(settingsOverview.name) }}
                  />
                }
                button={
                  <ButtonRouter
                    to={newLink}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                  >
                    <Trans
                      i18nKey="AddNewNameSingular"
                      values={{ name: t(`${settingsOverview.name}-SINGULAR`) }}
                    />{" "}
                  </ButtonRouter>
                }
              />
            </>
          ) : !settingsOverview.entry ? (
            details
          ) : (
            details
          )}
        </Sidebar>
      </div>
    </>
  );
};

export default SettingsWithSidebar;

/* Settings form with no sidebar */
export function SettingsSidebarNoSidebar(props) {
  const params = useParams();

  return (
    <div id="no-sidebar-scroll" className={styles.noSidebar}>
      <SettingsContentWrapper
        showReturnDesktop
        overviewUrl={`/${params.organization}/advanced`}
        {...props}
      />
    </div>
  );
}

export function SettingsPageNoSidebar({ children }) {
  return (
    <div id="no-sidebar-scroll" className={styles.noSidebar}>
      {children}
    </div>
  );
}

/* Settings form with global image */
export function SettingsGlobal(props) {
  const params = useParams();
  return (
    <div className={styles.settingsGlobal}>
      <SettingsContentWrapper
        showMobile
        global
        overviewUrl={`/${params.organization}/advanced`}
        {...props}
      />
    </div>
  );
}
