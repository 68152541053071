import React from "react";
import styles from "./usePunctality.module.scss";
import classNames from "classnames";
import { Trans } from "react-i18next";
import RelativeTime from "components/RelativeTime";
import useQs from "helpers/useQs";
import {
  closestIndexTo,
  differenceInMinutes,
  format,
  formatDistance,
  formatISO,
  getISODay,
  isFuture,
  isPast,
  isToday,
  setHours,
  setMinutes,
} from "date-fns";

import { useActiveUser } from "helpers/useUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { de } from "date-fns/locale";

export const intakeTimePunctalityLookUp = ({
  punctuality,
  difference,
  intakeTime,
  caseDate,
}) => {
  const distanceFormated =
    intakeTime && caseDate
      ? formatDistance(intakeTime, caseDate, {
          addSuffix: true,
          locale: de,
        })
      : undefined;

  return {
    noaction: {
      style: styles.noAction,
      icon: null,
    },
    punctual: {
      style: styles.trayPunctual,
      icon: <FontAwesomeIcon icon={faCheck} className={styles.punctualIcon} />,
      text: (
        <>
          <Trans>punctual</Trans>
        </>
      ),
    },
    slightlyUnpunctual: {
      style: styles.traySlightlyUnpunctual,
      icon: (
        <FontAwesomeIcon
          icon={faCheck}
          className={styles.slightlyUnpunctualIcon}
        />
      ),
      text: distanceFormated,
    },
    unpunctual: {
      style: styles.trayUnpunctual,
      icon: (
        <FontAwesomeIcon icon={faTimes} className={styles.unpunctualIcon} />
      ),
      text: difference ? distanceFormated : <Trans>not taken</Trans>,
    },
  };
};

export default function usePunctality({
  date,
  events,
  className,
  dayTimeIndex,
  liveTray,
  time,
  iotDevicesQueryData,
  trayStyle = true,
}) {
  const { trayDate } = useQs();
  const activeUser = useActiveUser();

  var difference;
  var result;
  var intakeTime;

  const eventsBaked = events.filter((e) => e.bake === true);

  //if (!eventsBaked[0]) return null;

  const caseDate = eventsBaked[0]
    ? eventsBaked[0]?.date
    : date && time
    ? setMinutes(
        setHours(new Date(date), time.split(":")[0]),
        time.split(":")[0]
      )
    : new Date();

  const trayCaseNumber = dayTimeIndex * 7 + getISODay(caseDate);

  const trayCaseStores = iotDevicesQueryData?.message
    ? iotDevicesQueryData.message.filter(
        (s) => s.EventMessage?.off === trayCaseNumber
      )
    : [];

  if (/*differenceToNow > 0 &&*/ events.length > 0) {
    var resultIndex = closestIndexTo(
      caseDate,
      trayCaseStores.map((e) => e.EventTimestamp)
    );

    result = trayCaseStores[resultIndex];

    if (result) {
      difference = differenceInMinutes(
        new Date(result.EventTimestamp),
        caseDate
      );

      intakeTime = format(new Date(result.EventTimestamp), "HH:mm", {
        timeZone: activeUser.timezone,
      });
    }
  }

  let punctualityKey = "noaction";

  if (eventsBaked.length > 0) {
    // Check past
    if (isPast(caseDate)) {
      if (Math.abs(difference) > 60 || difference === undefined) {
        punctualityKey = "unpunctual";
      }
      if (Math.abs(difference) <= 60) {
        punctualityKey = "slightlyUnpunctual";
      }
      if (Math.abs(difference) < 10) {
        punctualityKey = "punctual";
      }
    }
    // Check future
    else {
      if (Math.abs(difference) <= 60) {
        punctualityKey = "slightlyUnpunctual";
      }
      if (Math.abs(difference) < 10) {
        punctualityKey = "punctual";
      }
    }
  }

  const punctuality = intakeTimePunctalityLookUp({
    punctuality: punctualityKey,
    difference,
    intakeTime: result?.EventTimestamp
      ? new Date(result.EventTimestamp)
      : undefined,
    caseDate,
  });

  //const punctuality = intakeTimePunctality[punctualityKey];

  const classes = classNames(
    {
      [styles.isPast]: isPast(caseDate),
      [styles.isFuture]: isFuture(caseDate),
      [styles.liveTrayActive]: liveTray && liveTray.value >= 1,
      [styles.trayStyle]: trayStyle,
      //[styles.dot]: dot,
      [styles.isToday]: isToday,
      [styles.isActive]: trayDate === formatISO(caseDate),
    },
    punctuality.style,
    styles.tray,
    className
  );

  return {
    punctuality,
    caseDate,
    classes,
    punctualityKey,
    intakeTime,
    difference,
    result,
  };
}
