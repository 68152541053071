import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { rootSaga } from "./ducks";
//import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createBlacklistFilter } from "redux-persist-transform-filter";
import { browserHistory } from "react-router";
import {
  configureStore,
  getDefaultMiddleware,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { devicesApi } from "ducks/devices";
import { calendarsApi } from "ducks/calendars";
import { emptySplitApi } from "ducks/emptyApi";
import { organizationsApi } from "ducks/organizationsApi";
import { accountsApi } from "ducks/accounts";
import { medicationsApi } from "ducks/medicationsApi";
import { stocksApi } from "ducks/stocksApi";
import { storesApi } from "ducks/storesApi";
import { abdasApi } from "ducks/abdasApi";
import { iotDevicesApi } from "ducks/iotDevicesApi";
import { notificationsApi } from "ducks/notificationsApi";
import { messagesApi } from "ducks/messagesApi";
const saveSubsetBlacklistFilter = createBlacklistFilter("auth", [
  "error",
  "errorResponse",
]);

const persistConfig = {
  key: "root",
  storage,
  timeout: 500,
  transforms: [saveSubsetBlacklistFilter],
  blacklist: ["router", "auth"],
};

export const history = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

/*const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware )
);*/

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const { meta, ...data } = action;

    store.dispatch({
      type: "globalState/addError",
      payload: data,
    });
  }

  return next(action);
};

const storeEntry = configureStore({
  reducer: rootReducer(history), //persistedReducer,
  middleware: [
    ...getDefaultMiddleware().concat(
      //emptySplitApi.middleware,
      abdasApi.middleware,
      devicesApi.middleware,
      calendarsApi.middleware,
      medicationsApi.middleware,
      messagesApi.middleware,
      notificationsApi.middleware,
      accountsApi.middleware,
      iotDevicesApi.middleware,
      stocksApi.middleware,
      storesApi.middleware,
      emptySplitApi.middleware,
      organizationsApi.middleware,
      rtkQueryErrorLogger
    ),
    //sagaMiddleware,
  ],
});

//sagaMiddleware.run(rootSaga);

export const store = storeEntry;
//export const persistor = persistStore(storeEntry);
