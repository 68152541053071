import React from "react";
import styles from "./event.module.scss";
import Tooltip from "./Tooltip";
import MedicationEntry from "./MedicationEntry";

export default function Event(props) {
  //const [open, setIsOpen] = useState(false);
  return (
    <div
      className={styles.event}
      onClick={() => {
        //setIsOpen(true);
      }}
    >
      <div>
        <MedicationEntry entry={props.event} />
      </div>
    </div>
  );
}
