import React from "react";
import { Input, Select, SelectItem } from "@un/react";
import timezones from "timezones.json";
import styles from "./styles.module.scss";
import ReactSelect, { components } from "react-select";
import ReactJson from "react-json-view";

export default function TimezoneSelect({
  value,
  labelText,
  helperText,
  onChange,
  ...other
}) {
  //const value = "Europe/Berlin";

  const selectedTimezone = timezones.find((t) => t.utc.includes(value));

  const Option = (props) => {
    console.log("propspropspropsprops", props);
    return <components.Option {...props}>{props.data.text}</components.Option>;
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.text}
    </components.SingleValue>
  );

  /* return (
    <Select {...register} {...other} className={styles.timezoneSelect}>
      {timezones.map((t) => {
        return t.utc.map((e) => <SelectItem text={t.text} value={e[0]} />);
      })}
    </Select>*/

  return (
    <Input labelText={labelText} helperText={helperText}>
      {(props) => (
        <ReactSelect
          className={`wfp--react-select-container ${styles.timezoneSelect}`}
          classNamePrefix="wfp--react-select"
          options={timezones}
          components={{ Option /*SingleValue */ }}
          getOptionLabel={(e) => e.text}
          onChange={onChange}
          value={selectedTimezone}
        />
      )}
    </Input>
  );
}
