import React from "react";
import useMedicationById from "helpers/useMedicationById";
import { Button, List, ListItem, ModalWrapper, Story } from "@un/react";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";
import { Trans } from "react-i18next";
import { calendarsApi } from "ducks/calendars";
import ButtonRouter from "components/ButtonRouter";
import { useParams } from "react-router-dom";

export default function IntakeSummary({ entryData }) {
  const medication = useMedicationById(entryData.medication);

  const [unbakeCalendar, unbakeResult] =
    calendarsApi.useUnbakeCalendarMutation();

  //const unbake = useUnbake();

  console.log("entryDataentryDataentryData", entryData);
  const params = useParams();

  /*if (medication)
    return (
      <div>
        {JSON.stringify(medication)}
        {medication.meta?.name}
        {medication.meta?.manufacturer}
        {entryData.amount} {entryData.unit}
        <a href="https://www.apotheken-umschau.de/medikamente/beipackzettel/nitroxolin-forte-weichkapseln-6960250.html">
          Apotheken Umschau
        </a>
      </div>
    );
*/
  const onSubmit = () => {
    unbakeCalendar({
      patient: entryData.patient,
      from: entryData.from,
      to: entryData.to,
    });
  };

  // if (!entryData.medicationData) return null;
  return (
    <Story>
      <ButtonRouter
        isLink
        withOrganization
        to={`/calendar/${params.entry}/edit/${entryData.originalId}`}
      >
        <Trans>Edit</Trans>
      </ButtonRouter>
      {entryData.medicationData && (
        <>
          <h3>{entryData.medicationData.Langname}</h3>
          <p>{entryData.medicationData?.manufacturer?.Firmenname}</p>
          <ButtonRouter
            withOrganziation
            to={`calendar/${entryData.originalId}`}
          >
            <Trans>Edit</Trans>
          </ButtonRouter>
          <ModalWrapper
            shouldCloseAfterSubmit
            modalHeading={<Trans>Reset fill</Trans>}
            primaryButtonText={<Trans>Reset fill</Trans>}
            customButton={
              <Button>
                <Trans>Reset fill</Trans>
              </Button>
            }
            handleSubmit={onSubmit}
          >
            Are you sure you want to reset the fill for the timeframe from{" "}
            <b>{entryData.from}</b> to <b>entryData.to</b>?
          </ModalWrapper>
          <List kind="details">
            <ListItem title="PZN">10520189</ListItem>
            <ListItem title="Packungsgröße">4 Stück</ListItem>
            <ListItem title="Abgabeform">
              {entryData.medicationData?.Rezeptpflicht === "1"
                ? "Rezeptpflichtig"
                : "Nicht rezeptpflicht"}
            </ListItem>
            <ListItem title="Darreichungsform">Filmtabletten</ListItem>
            <ListItem title="Anbieter">
              {entryData.medicationData?.manufacturer?.Firmenname}
              Orifarm GmbH Leverkusen www.orifarm.de
            </ListItem>
          </List>
          <h3>Wirkstoffe</h3>
          <List kind="details">
            <ListItem>
              Sildenafil citrat 140,48 mg pro 1 Tablette = Sildenafil (100 mg
              pro 1 Tablette)
            </ListItem>
          </List>
          <h3>Sonstige Bestandteile</h3>
          <List kind="details">
            <ListItem>Cellulose, mikrokristalline</ListItem>
            <ListItem>Calciumhydrogenphosphat</ListItem>
            <ListItem>Croscarmellose natrium</ListItem>
            <ListItem>Magnesium stearat</ListItem>
            <ListItem>Hypromellose</ListItem>
          </List>
          <br />
          <Button
            href={`https://www.apotheken-umschau.de/medikamente/beipackzettel/${slugify(
              entryData.medicationData.Langname,
              "-"
            )}-${entryData.medicationData.PZN_Original}.html`}
            icon={<FontAwesomeIcon icon={faExternalLink} />}
            target="_blank"
          >
            <Trans>Show in Apotheken Umschau</Trans>
          </Button>
          Quelle: ABDATA Pharma-Daten-Service Bitte beachten Sie: Unsere
          Datenbank gibt nicht die Original-Gebrauchsinformation aus, die Sie
          als Beipackzettel in der Verpackung Ihres Medikaments finden. Die
          Angaben können sich von der jeweiligen Packungsbeilage unterscheiden
          und sind auch anders aufgebaut. Lesen Sie diese daher trotzdem und
          suchen bei Fragen Ihre ärztliche Praxis oder Apotheke auf.
          {/*JSON.stringify(entryData)*/}
        </>
      )}
    </Story>
  );
}
