import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  Button,
  InlineLoading,
  Checkbox,
  Input,
  InputGroup,
  Link,
  NumberInput,
  Select,
  SelectItem,
  TextArea,
} from "@un/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useParams } from "react-router-dom";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import DateInput from "../DateInput";
import styles from "./styles.module.scss";
import moment from "moment";
import useSettingsForm from "helpers/useSettingsFormNew";

import MedicationSelect from "components/MedicationSelect";
import unitList from "helpers/unitList";
import { Trans, useTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { calendarsApi } from "ducks/calendars";
import Repeater, { RepeaterItem } from "components/Repeater";
import MultiCheckbox from "components/MultiCheckbox";
import useDayTimeList from "helpers/useDayTimeList";
import IntakeSummary from "./IntakeSummary";
import useIsDesktop from "helpers/useIsDesktop";
import DeleteModal from "components/DeleteModal";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import DateRangePicker from "components/WeekPicker/DateRangePicker";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { useActiveUser } from "helpers/useUsers";
import useQs from "helpers/useQs";
import { format, isValid, setHours, setMinutes } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import TimeInput from "components/TimeInput";
import { usersApi } from "ducks/usersApi";

export default function CalendarForm({ onRequestClose, useInline }) {
  const params = useParams();
  const selectedEntry = params.calendarEntry;
  //const [medicationData, setMedicationData] = useState();
  const { date, timeCategory } = useQs();

  const activeUser = useActiveUser();
  const dayTimeList = useDayTimeList();
  // New calendar entries
  const initializeNewData = () => {
    var initialDataManipulated = {};
    initialDataManipulated.dateInput = { from: new Date(params.date) };
    initialDataManipulated.emptyStomach = false;
    initialDataManipulated.medicationData = "";
    initialDataManipulated.localMedicationData = "";
    initialDataManipulated.unit = "St";
    const intakeTime = dayTimeList.find(
      (f) => f.key === initialDataManipulated.timeCategory
    );
    initialDataManipulated.timeCategory = intakeTime?.key || timeCategory || "";

    initialDataManipulated.time = dayTimeList.find(
      (f) => f.key === initialDataManipulated.timeCategory
    )?.time;
    initialDataManipulated.amount = 1;
    return initialDataManipulated;
  };

  // Existing calendar entry
  const initializeData = (initialData = { rrule: {} }) => {
    console.log("initialData", initialData);
    // TODO: improve
    if (initialData?.dateReset) return initializeNewData();
    var initialDataManipulated = { rrule: { exclude: [] } };

    initialDataManipulated.dateInput = {
      from: new Date(initialData.date),
      to: initialData.rrule.until
        ? new Date(initialData.rrule.until)
        : undefined,
    };
    initialDataManipulated.time = initialData.date
      ? format(new Date(initialData.date), "HH:mm")
      : undefined; //moment(initialData.date).format("HH:mm");

    // Medication
    initialDataManipulated.medicationData = initialData.medicationData;
    initialDataManipulated.localMedicationData =
      initialData.localMedicationData;
    // Weekdays
    if (initialData.rrule.byweekday)
      initialDataManipulated.rrule.byweekday = initialData.rrule.byweekday.map(
        (e) => (e ? e.toString() : "0")
      );

    // Exclude
    initialDataManipulated.rrule.exclude = initialData?.rrule?.exclude
      ? initialData.rrule.exclude.map((e) => {
          return { data: e };
        })
      : undefined;
    initialDataManipulated.timeCategory = initialData.timeCategory;

    initialDataManipulated.amount = initialData.amount;
    initialDataManipulated.emptyStomach = initialData.emptyStomach;
    initialDataManipulated.instruction = initialData.instruction;
    initialDataManipulated.unit = initialData.unit;
    initialDataManipulated.rrule.freq = initialData.rrule.freq;
    return initialDataManipulated;
  };

  const [updateSingleUser, resultUpdateSingleUser] =
    usersApi.useUpdateSingleUsersMutation();

  const prepareSubmit = (values = {}) => {
    updateSingleUser({
      id: activeUser.id,
      values: { meta: { [`intake-${values.timeCategory}`]: values.time } },
    });
    //const intakeTime = values.time; //dayTimeList.find((f) => f.key === values.timeCategory);

    values.date = zonedTimeToUtc(
      new Date(
        values.dateInput.from.getFullYear(),
        values.dateInput.from.getMonth(),
        values.dateInput.from.getDate(),
        values.time.split(":")[0],
        values.time.split(":")[1]
      ),
      activeUser.timezone
    );

    if (isValid(values.dateInput.to))
      values.rrule.until = zonedTimeToUtc(
        new Date(
          values.dateInput.to.getFullYear(),
          values.dateInput.to.getMonth(),
          values.dateInput.to.getDate(),
          23,
          59
        ),
        activeUser.timezone
      );

    values.patient = activeUser.id;
    values.rrule.exclude = values?.rrule?.exclude
      ? values.rrule.exclude.map((e) => e.data)
      : undefined;

    // Daily doses
    if (values?.rrule?.freq === "DAILY") {
      values.rrule.byweekday = values?.rrule?.byweekday
        ? values.rrule.byweekday.map((e) => parseInt(e))
        : undefined;
    } else {
      delete values.rrule.byweekday;
    }

    values.abdaMedication = medicationData?.PPN
      ? medicationData?.PPN
      : undefined;

    values.medication =
      medicationData?.id && !medicationData?.PPN
        ? medicationData?.id
        : undefined;

    delete values.localMedicationData;
    delete values.medicationData;
    delete values.dateInput;
    delete values.time;
    delete values.timeInput;
    return values;
  };

  const store = useSettingsForm({
    name: "calendars",
    api: calendarsApi,
    id: params.calendar,
    url: `/${params.organization}/calendar/${params.entry}/${params.calendarEntry}`,
    prepareSubmit: prepareSubmit,
    prepareFormEntry: initializeData,
    defaultValues: { amount: 4 },
    newEntryData: { dateReset: params.date },
    entryParam: "calendarEntry",
    //settings: { disableFetchSingle: true },
  });

  const {
    loading,
    entryData,
    urlId,
    resetForm,
    resultUpdateSingle,
    form: { control, setValue, register, reset, watch },
  } = store;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rrule.exclude",
  });

  useEffect(() => {
    resetForm();
  }, [params.date]);

  const freq = watch("rrule.freq");
  const byweekday = watch("rrule.byweekday");

  const medicationData = watch("medicationData");
  const localMedicationData = watch("localMedicationData");

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (freq === "DAILY" && byweekday === undefined)
      setValue("rrule.byweekday", ["0", "1", "2", "3", "4", "5", "6"]);
  }, [freq]);

  const excludeDate = moment(selectedEntry).local().format("DD.MM.YYYY HH:mm");

  const location = useLocation();
  const { t } = useTranslation();

  const timeCategoryWatch = watch("timeCategory");

  /* useEffect(() => {
    const intakeTime = dayTimeList.find((f) => f.key === timeCategoryWatch);
    //setValue("time", intakeTime?.time);
  }, [timeCategoryWatch]);*/

  if (!entryData) return null;

  return (
    <div className={styles.form} id="scroll-container">
      <SettingsContentWrapper
        {...store}
        narrow
        showReturnDesktop
        hideHeaderRight
        hideDelete={entryData.bake === true}
        overviewUrl={`/${params.organization}/calendar/${params.entry}${location.search}`}
        title={
          entryData.bake === true ? (
            <Trans>View medication intake</Trans>
          ) : urlId === "new" ? (
            <Trans>Create medication intake</Trans>
          ) : (
            <Trans>Edit medication intake</Trans>
          )
        }
      >
        {loading ? (
          <InlineLoading />
        ) : entryData.bake === true ? (
          <IntakeSummary entryData={entryData} />
        ) : (
          <>
            {/*<ImportMedicationPlan />*/}

            <div className={styles.medicationWrapper}>
              <MedicationSelect
                medicationData={medicationData}
                localMedicationData={localMedicationData}
                onChange={(e) => {
                  setValue("medicationData", e);
                }}
              />
            </div>
            <div className={styles.dateWrapper}>
              <Controller
                render={({ field }) => {
                  return (
                    <NumberInput
                      step="0.5"
                      {...field}
                      labelText={<Trans>Amount</Trans>}
                    />
                  );
                }}
                name="amount"
                control={control}
              />
              <Select
                className={styles.dayTime}
                labelText={<Trans>Unit</Trans>}
                {...register("unit")}
              >
                {Object.entries(unitList).map((f) => (
                  <SelectItem
                    key={f[1].name}
                    value={f[0]}
                    text={i18next.t(`${f[0]}-PLURAL`)}
                  />
                ))}
              </Select>
            </div>
            <div className={styles.dateWrapper}>
              {/*<Controller
                render={({ field }) => {
                  return (
                    <DateInput
                      labelText={<Trans>Start date</Trans>}
                      {...field}
                    />
                  );
                }}
                control={control}
                name="dateInput"
                min={null}
                max={null}
              />*/}

              <Controller
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      labelText={<Trans>Start and end date</Trans>}
                      /*helperText={
                        <Trans>
                          When is the medication intake starting and when
                          finished?
                        </Trans>
                      }*/
                    >
                      {() => (
                        <DateRangePicker
                          startDate={field.value.from}
                          onChangeStart={(date) =>
                            field.onChange({ from: date, to: field.value.to })
                          }
                          onChangeEnd={(date) =>
                            field.onChange({ from: field.value.from, to: date })
                          }
                          endDate={field.value.to}
                          endDateProps={{
                            placeholderText: t("Indefinite end"),
                          }}
                          fullWidth
                          clearEndDate
                        />
                      )}
                    </Input>
                  );
                }}
                control={control}
                name="dateInput"
              />
            </div>
            <div className={styles.dateWrapper}>
              <Select
                labelText={<Trans>Intake time</Trans>}
                className={styles.dayTime}
                placeholder={i18next.t("Select a time...")}
                {...register("timeCategory")}
                onChange={(e) => {
                  console.log("eeeee", e.target.value);
                  const intakeTime = dayTimeList.find(
                    (f) => f.key === e.target.value
                  );
                  setValue("time", intakeTime?.time);
                }}
              >
                {dayTimeList.map((f, i) => (
                  <SelectItem
                    key={i}
                    value={f.key}
                    text={`${i18next.t(f.name)}`}
                  />
                ))}
              </Select>
              <TimeInput name="time" {...register("time")} />
              {/*<Button>
                <Trans>Change time</Trans>
                </Button>*/}
            </div>
            <Select
              {...register("rrule.freq")}
              labelText={<Trans>Repeat intake</Trans>}
            >
              <SelectItem value="" text={i18next.t("once")} />
              <SelectItem value="DAILY" text={i18next.t("daily")} />
              <SelectItem value="WEEKLY" text={i18next.t("weekly")} />
              <SelectItem value="MONTHLY" text={i18next.t("monthly")} />
            </Select>
            {freq && freq === "DAILY" && (
              <div>
                <InputGroup
                  labelText={<Trans>Weekdays</Trans>}
                  className={styles.weekdayWrapper}
                >
                  {moment.weekdaysShort(true).map((e, i) => (
                    <MultiCheckbox
                      fullWidth
                      labelText={e.replace(".", "")}
                      id={e}
                      value={i.toString()}
                      {...register("rrule.byweekday")}
                    />
                  ))}
                </InputGroup>
              </div>
            )}
            <div className={styles.commentWrapper}>
              <TextArea
                className={styles.instructions}
                labelText={<Trans>Instruction</Trans>}
                {...register("instruction")}
              />

              <Checkbox
                className={styles.emptyStomach}
                {...register("emptyStomach")}
                id="emptyStomach"
                labelText={<Trans>empty stomach</Trans>}
              />
            </div>

            {urlId !== "new" && (
              <div className={styles.excludeWrapper}>
                <InputGroup
                  labelText={<Trans>Exclude individual intake time</Trans>}
                >
                  <Repeater
                    addButton={
                      fields.find(
                        (f) =>
                          f.data &&
                          f.data.toString() ===
                            moment(selectedEntry).valueOf().toString()
                      ) ? (
                        <></>
                      ) : (
                        <Button
                          type="button"
                          small
                          kind="tertiary"
                          onClick={() =>
                            append({
                              data: moment(selectedEntry).valueOf(),
                            })
                          }
                        >
                          <Trans
                            i18nKey="excludeDateE"
                            excludeDate={excludeDate}
                          >
                            Exclude {{ excludeDate }}
                          </Trans>
                        </Button>
                      )
                    }
                    customEmptyContent={
                      <div>
                        {/*<Trans>Currently there is no intake time excluded</Trans>*/}
                        <Button
                          type="button"
                          kind="tertiary"
                          small
                          onClick={() =>
                            append({
                              data: moment(selectedEntry).valueOf(),
                            })
                          }
                        >
                          <Trans
                            i18nKey="excludeDateE"
                            excludeDate={excludeDate}
                          >
                            Exclude {{ excludeDate }}
                          </Trans>
                        </Button>
                      </div>
                    }
                  >
                    {fields.map((item, index) => {
                      return (
                        <RepeaterItem
                          key={item.id}
                          className={styles.exludeDate}
                        >
                          {moment
                            .unix(item.data / 1000)
                            .format("DD.MM.YYYY HH:mm")}
                          <input
                            {...register(`rrule.exclude[${index}].data`)}
                            type="hidden"
                            defaultValue={item.data} // make sure to set up defaultValue
                          />

                          <Button
                            kind="ghost"
                            type="button"
                            onClick={() => remove(index)}
                            icon={<FontAwesomeIcon icon={faTimes} />}
                          ></Button>
                        </RepeaterItem>
                      );
                    })}
                  </Repeater>
                </InputGroup>
              </div>
            )}
            <div className={styles.submitWrapper}>
              <SettingsSubmitButton {...store} />
              {
                urlId !== "new" && isDesktop && (
                  <DeleteModal
                    {...store}
                    customButton={
                      resultUpdateSingle.isLoading ? (
                        <></>
                      ) : (
                        <Link className={styles.deleteLink}>
                          <Trans>Delete intake</Trans>
                        </Link>
                      )
                    }
                  />
                ) /* : (
                    <ScanButton
                      customTrigger={<Link>Scan from eRezept</Link>}
                    />
                  )*/
              }
            </div>
          </>
        )}
      </SettingsContentWrapper>
    </div>
  );
}
