import React from "react";
import styles from "./trayEntry.module.scss";
import NewEntryButton from "./NewEntryButton";
import MedicationEntry from "./MedicationEntry";
import classNames from "classnames";
import { Trans } from "react-i18next";
import RelativeTime from "components/RelativeTime";
import useIsDesktop from "helpers/useIsDesktop";
import qs from "qs";
import { NavLink } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import useQs from "helpers/useQs";
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz";
import {
  //format,
  formatISO,
  isFuture,
  isPast,
  isSameDay,
} from "date-fns";
import { useActiveUser } from "helpers/useUsers";
import usePunctality from "helpers/usePunctality";
export default function TrayEntry({
  dot,
  date,
  events,
  className,
  dayTimeIndex,
  dayTimeEntry,
  liveTray,
  isToday,
  time,
  fill,
  iotDevicesQueryData,
  timeIsBaked,
  trayStyle = true,
}) {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const params = useParams();
  const { trayDate, timeCategory } = useQs();

  const activeUser = useActiveUser();

  const { caseDate, punctuality, punctualityKey, result } = usePunctality({
    /// date: caseDate,
    events,
    className,
    dayTimeIndex,
    liveTray,
    time,
    //isBakedDay,
    iotDevicesQueryData,
    trayStyle: true,
  });

  const classes = classNames(
    {
      [styles.isPast]: isPast(caseDate),
      [styles.isFuture]: isFuture(caseDate),
      [styles.liveTrayActive]: liveTray && liveTray.value >= 1,
      [styles.trayStyle]: trayStyle,
      [styles.dot]: dot,
      [styles[punctualityKey]]: punctuality,
      [styles.isToday]: isToday,
      [styles.isActive]:
        isSameDay(new Date(trayDate), caseDate) &&
        (!timeCategory || timeCategory === dayTimeEntry?.key),
    },
    punctuality.style,
    styles.tray,
    className
  );

  if (events) {
    /*
    const date = new Date();
    const timeZone = "Europe/Berlin";
    const zonedDate = utcToZonedTime(date, timeZone);

    const pattern = "HH:mm:ss 'GMT' XXX (z)";
    const output = format(zonedDate, pattern, { timeZone: "Europe/Berlin" });
    */

    const eventsLimited = isDesktop ? events : events.slice(0, 6);
    const content = (
      <>
        {(isFuture(caseDate) || isDesktop) && (
          <div className={styles.entryList}>
            {eventsLimited.map((r, i) => (
              <MedicationEntry
                dot={dot}
                key={i}
                fill={fill}
                entry={r}
                isPast={isPast(caseDate)}
                time={time}
                timeIsBaked={timeIsBaked}
                isDesktop={isDesktop}
              />
            ))}
            {events.length > 7 && (
              <div className={styles.moreEvents}>+{events.length - 7}</div>
            )}
          </div>
        )}

        {isFuture(caseDate) && !fill && (
          <NewEntryButton
            newDate={formatISO(caseDate)}
            newTimeCategory={dayTimeEntry?.key}
          />
        )}

        {isPast(caseDate) && (
          <div className={styles.intakePunctuality}>
            {punctuality.icon}
            {/*result && format(new Date(result.EventTimestamp), "HH:mm")*/}
            {isPast(caseDate) && isDesktop && (
              <div className={styles.intakePunctualityText}>
                {punctuality.text}{" "}
                {result && (
                  <>
                    {format(new Date(result.EventTimestamp), "HH:mm", {
                      timeZone: activeUser.timezone /*"Europe/Berlin"*/,
                    })}{" "}
                    Uhr
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </>
    );

    const locationSearch = {
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      trayDate: formatISO(caseDate),
      timeCategory: dayTimeEntry?.key,
    };

    const to = `/${params.organization}/calendar/${
      params.entry
    }/?${qs.stringify(locationSearch)}`;

    if (!isDesktop)
      return (
        <NavLink to={to} className={classes}>
          {content}
        </NavLink>
      );

    return <div className={classes}>{content}</div>;
  }
  return null;
}
