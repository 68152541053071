const dayTimeListLookup = {
  morning: {
    name: "morning",
    defaultTime: "08:00",
    memo: true,
    index: 0,
  },
  noon: { name: "noon", defaultTime: "12:00", memo: true, index: 1 },
  afternoon: {
    name: "after noon",
    defaultTime: "14:00",
    memo: true,
    index: 2,
  },
  night: { name: "night", defaultTime: "19:00", memo: true, index: 3 },
};
module.exports = dayTimeListLookup;
