import React from "react";
import { TextInput } from "@un/react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";

export default function DateInput(props) {
  const { min = undefined, max = undefined, time } = props;
  // Format date value
  // const value = moment(props.value).format("YYYY-MM-DD");
  // moment().format("YYYY-MM-DD")

  return (
    <TextInput
      additional={
        <div className={styles.icon}>
          <FontAwesomeIcon icon={time ? faClock : faCalendarAlt} />
        </div>
      }
      type="date"
      {...props}
      formItemClassName={styles.dateInput}
      max={max}
      min={min}
      placeholder=""
    />
  );
}
