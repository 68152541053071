import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useIsDesktop from "helpers/useIsDesktop";
import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./styles.module.scss";

const SidebarBackButton = () => {
  return (
    <div className={styles.backButton}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

export default SidebarBackButton;

export const SidebarBackButtonWithLink = React.forwardRef(
  (props, showOnDesktop = false, ref) => {
    const isDesktop = useIsDesktop();
    if (isDesktop && showOnDesktop === false) return null;
    return (
      <NavLink className={styles.backButton} ref={ref} {...props}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </NavLink>
    );
  }
);
