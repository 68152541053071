import React from "react";
import styles from "./medicationEntryFill.module.scss";

import color from "color";
import { useSelector } from "react-redux";
import medications from "ducks/medications";
import MedicationName from "components/MedicationName";
import { presetColors } from "components/ColorInput";
import { medicationsApi } from "ducks/medicationsApi";
import { Trans } from "react-i18next";
import unitList from "helpers/unitList";
import useIsDesktop from "helpers/useIsDesktop";

export default function MedicationEntryFill({ relatedEvents }) {
  const entry = relatedEvents[0];
  const { data: medicationData } = medicationsApi.useGetSingleMedicationsQuery(
    entry?.medication,
    { skip: entry?.medication === undefined }
  );

  let amount = 0;
  relatedEvents.forEach((r) => {
    amount = amount + r.amount;
  });

  const activeColor = medicationData?.meta?.color
    ? medicationData?.meta?.color
    : entry?.medicationData?.PPN
    ? presetColors[parseInt(entry.medicationData.PPN.slice(-1))]
    : undefined;

  const isDesktop = useIsDesktop();

  return (
    <div
      className={`${styles.trayEntry}  ${styles.trayEntryFill}`}
      style={{
        backgroundColor: activeColor
          ? color(activeColor).darken(0.01).hex()
          : undefined,
        color: activeColor ? color(activeColor).darken(0.7).hex() : undefined,
      }}
    >
      <div className={styles.trayEntryCount}>{amount}</div>
      <div className={styles.trayEntryUnit}>
        {" "}
        {isDesktop ? (
          <Trans>{`${entry.unit}-PLURAL`}</Trans>
        ) : (
          unitList[entry.unit]?.shortName
        )}
      </div>
      <div className={styles.trayEntrySubTitle}>
        <MedicationName entry={entry} medicationData={medicationData} />
      </div>
    </div>
  );
}
