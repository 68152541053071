import React from "react";

import { NavLink, useHistory, useParams } from "react-router-dom";

import LoginWrapper, { LoginWrapperTitle } from "components/AuthWrapper";
import styles from "./success.module.scss";
import hospitalIllustration from "./kite.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { Button, ModalWrapper, TextInput } from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";

import { Trans } from "react-i18next";
import ButtonRouter from "components/ButtonRouter";
import SubmitWrapper from "components/SubmitWrapper";
import SettingsDevicesNew from "components/SettingsDevices/SettingsDevicesNew";
import useQs from "helpers/useQs";

export default function Success() {
  const { skip } = useQs();
  return (
    <LoginWrapper
      rightSide={
        <img
          alt="Illustration of a hospital"
          className={styles.image}
          src={hospitalIllustration}
        />
      }
    >
      <LoginWrapperTitle kind="small">
        <Trans>Setup complete</Trans>
      </LoginWrapperTitle>

      <p className={styles.text}>
        {skip ? (
          <Trans>
            Setup completed. You can setup a patient and a device later.
          </Trans>
        ) : (
          <Trans>
            The medication data will now be synchronized with the Anabox Smart.
          </Trans>
        )}
      </p>

      <SubmitWrapper>
        <ButtonRouter
          withOrganization
          large
          to="/calendar"
          icon={<FontAwesomeIcon icon={faChevronRight} />}
        >
          <Trans>Go to calendar</Trans>
        </ButtonRouter>
      </SubmitWrapper>

      {/*<div className={styles.deviceAdd}>
        <SettingsDevicesNew />
    </div>*/}
    </LoginWrapper>
  );
}
