import React from "react";

import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import { devicesApi } from "ducks/devices";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, InputGroup, TextInput, BlockNotification } from "@un/react";
import styles from "./settingsDevicesDetail.module.scss";
import MultiCheckbox from "components/MultiCheckbox";
import deviceList, { deviceByKind } from "helpers/deviceList";
import DeviceIcon from "components/DeviceIcon";
import UserSelect from "components/UserSelect";
import DeviceIdFormatted from "./DeviceIdFormatted";
import DebugDevice from "./DebugDevice";
import AdvancedDeviceSettings from "./Debug/AdvancedDeviceSettings";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";
import { useDebug } from "helpers/useCurrentUser";
import ButtonRouter from "components/ButtonRouter";

export default function SettingsDevicesDetail() {
  const params = useParams();

  const prepareFormEntry = (values) => {
    if (!values) return {};
    const shadows = values?.shadow?.state?.reported;

    const { iotDevice, shadow, ...filteredValues } = values;

    if (shadows)
      return {
        ...filteredValues,
        name: values.name,
        proxyReport: shadows.proxy_report ? true : false,
        alarmEnable:
          shadows.alarm_enable !== undefined
            ? `alarm-${shadows.alarm_enable.toString()}`
            : "alarm-2",
        takeOffsetTime: shadows.take_offset_time
          ? shadows.take_offset_time.toString()
          : "10800",
      };
    return values;
  };

  const prepareSubmit = (values) => {
    if (values.deviceId) {
      values.deviceId = values.deviceId.replace(/\s/g, "");
    }
    if (values.deviceId) {
      delete values.kind;
    } else {
      delete values.deviceId;
    }

    if (values.patient === "") {
      values.patient = null;
    }
    delete values.manually;
    delete values.shadow;
    delete values.analog;
    delete values.latestEntries;
    delete values.proxyReport;
    delete values.caseStatus;
    delete values.deviceStatus;
    delete values.timezone;
    if (values.alarmEnable)
      values.alarmEnable = parseInt(values.alarmEnable.replace("alarm-", ""));
    if (values.takeOffsetTime)
      values.takeOffsetTime = parseInt(values.takeOffsetTime);
    return values;
  };

  const store = useSettingsForm({
    name: "devices",
    api: devicesApi,
    prepareFormEntry,
    prepareSubmit,
  });

  const {
    entryData,
    urlId,
    form: {
      formState: { errors },
      control,
      register,
    },
  } = store;

  const debug = useDebug();

  const deviceMeta = deviceByKind(entryData?.kind);

  const devicesApiGetAll = devicesApi.useGetAllDevicesQuery(
    params.organization
  );

  const filterData = (data) => {
    if (!data || !devicesApiGetAll.data) return [];
    return data.map((u) => {
      if (
        devicesApiGetAll.data.find(
          (d) =>
            (d.patient === u.id || u.category !== "patient") &&
            d.patient !== entryData?.patient
        )
      ) {
        return { ...u, disabled: true };
      }
      return u;
    });
  };
  return (
    <SettingsContentWrapper
      {...store}
      title={
        urlId === "new" ? (
          <Trans>Add new analoge device</Trans>
        ) : (
          <Trans>Edit device</Trans>
        )
      }
      after={
        urlId !== "new" && debug && deviceMeta?.analog !== true ? (
          <DebugDevice id={entryData?.id} />
        ) : null
      }
    >
      {urlId === "new" ? (
        <>
          <InputGroup
            labelText={<Trans>Which product do you want add?</Trans>}
            className={styles.alarmWrapper}
          >
            {deviceList
              .filter((e) => e.analog)
              .map((e, i) => (
                <MultiCheckbox
                  className={styles.medicationRadio}
                  labelText={
                    <div>
                      <img
                        src={e.image}
                        className={styles.deviceImage}
                        alt={e.name}
                      />
                      <span className={styles.deviceDescription}>{e.name}</span>
                    </div>
                  }
                  type="radio"
                  id={e.id}
                  value={e.id}
                  {...register("kind")}
                />
              ))}
          </InputGroup>

          <SettingsSubmitButton {...store} title={<Trans>Add device</Trans>} />

          <ButtonRouter isPlain to={`./new`}>
            <Trans>Add digital device</Trans>
          </ButtonRouter>
        </>
      ) : (
        <>
          <BlockNotification
            icon={
              <DeviceIcon
                device={entryData?.kind}
                className={styles.deviceIconDetails}
              />
            }
            lowContrast
            kind="info"
            hideCloseButton
            title={<Trans>{deviceMeta?.name}</Trans>}
            subtitle={
              <>
                <DeviceIdFormatted title={"Device ID:"} kind={entryData?.kind}>
                  {entryData?.deviceId}
                </DeviceIdFormatted>
              </>
            }
          />
          <TextInput
            labelText="Name"
            helperText={<Trans>A description for the device</Trans>}
            {...register("meta.name")}
          />

          <UserSelect
            filterData={filterData}
            category="patient"
            register={register("patient")}
            helperText={<Trans>Name of the devices user</Trans>}
          />
          {deviceMeta?.analog !== true && (
            <AdvancedDeviceSettings
              entryData={entryData}
              register={register}
              control={control}
              errors={errors}
            />
          )}
          <SettingsSubmitButton {...store} />
        </>
      )}
    </SettingsContentWrapper>
  );
}
