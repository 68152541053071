import React from "react";
import { Button } from "@un/react";
import styles from "./newEntryButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import Tooltip from "./Tooltip";
import ButtonRouter from "components/ButtonRouter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import useQs from "helpers/useQs";
import classnames from "classnames";
import qs from "qs";

// <Tooltip newDate={newDate}>
export default function NewEntryButton({
  newDate,
  newTimeCategory,
  content = "regular",
  kind = "ghost",
  ...other
}) {
  const { organization, entry } = useParams();
  const location = useLocation();

  const locationSearch = {
    ...qs.parse(location.search, { ignoreQueryPrefix: true }),
    trayDate: newDate,
    timeCategory: newTimeCategory,
  };

  const to = `/${organization}/calendar/${entry}/new/${newDate}?${qs.stringify(
    locationSearch
  )}`;

  const classes = classnames(
    {
      [styles.regular]: kind === "regular",
      [styles.newEntryButton]: kind === "ghost",
    },
    "newEntryButton"
  );

  return (
    <ButtonRouter
      small
      kind={kind}
      iconReverse
      to={to}
      icon={<FontAwesomeIcon icon={faPlusCircle} />}
      className={classes}
      children={
        content === "regular" ? (
          <Trans>New Intake</Trans>
        ) : (
          <span className={styles.text}>
            <Trans>new</Trans>
          </span>
        )
      }
      {...other}
    />
  );
}
