import RRule, { RRuleSet } from "rrule";

export default function calendarFuturePoints({ events = [], from, to, bake }) {
  const eventsWithBake =
    bake !== undefined ? events.filter((e) => e.bake === bake) : events;

  return eventsWithBake.reduce((result, cur) => {
    const currentDate = new Date(cur.date);
    if (!cur.date || !from || !to) {
      console.error("Time needs to be defined");
      return [];
    }
    const rruleSet = new RRuleSet();

    if (cur.rrule?.freq !== "" && cur.rrule?.freq && cur.bake === false) {
      rruleSet.rrule(
        new RRule({
          freq: RRule[cur.rrule?.freq],
          byweekday:
            cur.rrule?.byweekday && cur.rrule?.byweekday.length >= 1
              ? cur.rrule?.byweekday
              : undefined,
          dtstart: currentDate,
          until:
            cur.rrule.until && new Date(cur.rrule.until) < to
              ? new Date(cur.rrule.until)
              : to,
        })
      );
    } else rruleSet.rdate(currentDate);

    if (cur.rrule.exclude)
      cur.rrule.exclude.forEach((f) => {
        rruleSet.exdate(new Date(f));
      });

    var recurings = rruleSet.between(from, to);

    const calendarDataRecuring = recurings.map((date, e) => {
      return {
        ...cur,
        // TODO: Fix id format
        originalId: cur.id || cur._id,
        date: date,
      };
    });

    return [...result, ...calendarDataRecuring];
  }, []);
}
