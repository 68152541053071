import Repeater from "components/Repeater";
import React from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import LoginWrapper, { LoginWrapperTitle } from "components/AuthWrapper";
import styles from "./styles.module.scss";
import {
  BlockNotification,
  NotificationActionButton,
  InlineLoading,
  Item,
} from "@un/react";
import loginBackground from "components/Login/login-background.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClinicMedical, faUsers } from "@fortawesome/pro-regular-svg-icons";
import ButtonRouter from "components/ButtonRouter";
import qs from "qs";
import { Trans } from "react-i18next";
import OrganizationName from "components/OrganizationName";
import {
  faChevronCircleRight,
  faChevronRight,
  faPlusCircle,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { organizationsApi } from "ducks/organizationsApi";
import ReactJson from "react-json-view";
import Status from "components/Status";

export default function SelectOrganization() {
  const history = useHistory();

  const location = useLocation();
  const { show, action } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const allQuery = organizationsApi.useGetAllOrganizationsQuery();
  const { data = [] } = allQuery;

  if (data.length === 1 && show !== "always") {
    history.push(`./${data[0].id}`);
  }

  return (
    <LoginWrapper
      showBackLink
      customBack={(props) => (
        <ButtonRouter
          {...props}
          icon={<FontAwesomeIcon icon={faUser} />}
          iconReverse={false}
          to="/account"
        >
          <Trans>account</Trans>
        </ButtonRouter>
      )}
      rightSide={
        <div className={styles.loginContent}>
          <img src={loginBackground} alt="Login background" />
        </div>
      }
    >
      <Status query={allQuery}>
        {data.length === 0 ? (
          <>
            <BlockNotification
              title={<Trans>Get started</Trans>}
              lowContrast
              kind="info"
              subtitle={
                <Trans>
                  Create a organization or get invited by another user.
                </Trans>
              }
              hideCloseButton
            ></BlockNotification>
            <ButtonRouter
              to="/createOrganization/new/"
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              large
            >
              <Trans>Create organization</Trans>
            </ButtonRouter>
          </>
        ) : (
          <>
            <LoginWrapperTitle id="select-organization-title">
              <Trans>Select an organization</Trans>
            </LoginWrapperTitle>

            {action === "orgDeleted" && (
              <BlockNotification
                title={<Trans>Organization deleted</Trans>}
                lowContrast
                kind="info"
                subtitle={
                  <Trans>The organization was successfully deleted</Trans>
                }
                hideCloseButton
              ></BlockNotification>
            )}
            {data && (
              <Repeater
                addButtonText={<Trans>Create new organization</Trans>}
                addButtonTo="/createOrganization/new"
                addButtonAddition={
                  allQuery.isLoading ? (
                    <InlineLoading description="Loading..." />
                  ) : undefined
                }
              >
                {data.map((e) => (
                  <NavLink to={`${e.id}`}>
                    <Item
                      image={
                        <FontAwesomeIcon
                          icon={
                            e.kind === "private" ? faUsers : faClinicMedical
                          }
                          className={styles.icon}
                        />
                      }
                      title={<OrganizationName organization={e} />}
                      kind="horizontal"
                      wrapper="repeater"
                    >
                      {e.meta?.description ? (
                        e.meta.description
                      ) : e.kind === "private" ? (
                        <Trans>Private Organization</Trans>
                      ) : (
                        <Trans>Professionelle Organization</Trans>
                      )}
                    </Item>
                  </NavLink>
                ))}
              </Repeater>
            )}
          </>
        )}
      </Status>
      <br />
      {/*<InlineNotification
        kind="warning"
        title="Anmed Smart Alpha. 0.7"
        //actions={<NotificationActionButton>Action</NotificationActionButton>}
        iconDescription="describes the close button"
        kind="warning"
        lowContrast
        statusIconDescription="describes the status icon"
        subtitle="nur für Testzwecke"
        hideCloseButton
      >
        <p>nur für Testzwecke</p>
      </InlineNotification>*/}
    </LoginWrapper>
  );
}
