export default function scrollToTop() {
  console.log("Scroll to top");
  if (document.getElementById("no-sidebar-scroll"))
    document.getElementById("no-sidebar-scroll").scrollTo(0, 0);

  if (document.getElementById("root"))
    document.getElementById("root").scrollTo(0, 0);

  if (document.getElementsByClassName("App"))
    document.getElementsByClassName("App")[0].scrollTo(0, 0);

  if (document.getElementById("auth-wrapper-scroll"))
    document.getElementById("auth-wrapper-scroll").scrollTo(0, 0);

  if (document.getElementById("scroll-container"))
    document.getElementById("scroll-container").scrollTo(0, 0);

  window.scrollTo(0, 0);
}
