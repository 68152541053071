import { useState, useEffect, useMemo } from "react";
//import Auth from "@aws-amplify/auth";
import { useSelector } from "react-redux";
import auth from "ducks/auth";

/*
export default function useAuth({ provider, options }) {
  const [state, setState] = useState({
    user: {},
    isSignedIn: false,
  });

  const auth = useMemo(() => {
    Auth.configure(options);
    return Auth;
  }, []);

  useEffect(() => {
    auth
      .currentAuthenticatedUser()
      .then((user) => setState({ user, isSignedIn: true }))
      .catch(() => {
        //auth.federatedSignIn();
      });
  }, []);

  const signIn = () => auth.federatedSignIn({ provider });

  const signOut = () => auth.signOut();

  return {
    ...state,
    signIn,
    signOut,
  };
}*/

export function useAuthStatus() {
  return useSelector(auth.selectors.authState);
}
