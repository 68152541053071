const dayTimeListLookup = require("./dayTimeListLookup");

function withDates({ user, organization, show }) {
  const dayTimeListWithDates = Object.keys(dayTimeListLookup).map((e) => {
    const time = user?.meta?.[`intake-${e}`]
      ? user.meta[`intake-${e}`]
      : organization?.meta?.[`intake-${e}`]
      ? organization.meta[`intake-${e}`]
      : dayTimeListLookup[e].defaultTime;
    return { ...dayTimeListLookup[e], key: e, time };
  });

  const dayTimeListWithDatesFiltered = show
    ? dayTimeListWithDates.filter((e) => e.memo)
    : dayTimeListWithDates;
  return dayTimeListWithDatesFiltered;
}

module.exports = { withDates };
