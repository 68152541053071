import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import ApiPage from "../ApiPage";
import OrganizationPage from "../SettingsOrganization";
import styles from "./styles.module.scss";
import SettingsList from "./SettingsList";
import SettingsMedications from "components/SettingsMedications";
import SettingsDevices from "components/SettingsDevices";
import SettingsStock from "components/SettingsStock";
import SettingsNotifications from "components/SettingsMessages";
import SettingsUsers from "components/SettingsUsers";
import SettingsSecurity from "components/SettingsSecurity";
import PatientPage from "components/PatientPage";
import SettingsAdvanced from "components/SettingsAdvanced";
import SettingsHelp from "components/SettingsHelp";
import SettingsMessages from "components/SettingsMessages";

export default function Settings() {
  const params = useParams();
  const { organization } = params;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  useEffect(() => {
    document.body.className = "fullHeight";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div className={styles.settingsPageWrapper}>
      <div className={styles.settingsPage}>
        <div className={styles.header}></div>
        <div className={styles.wrapper}>
          <div className={styles.navigation}>
            <SettingsList />
          </div>

          <div className={styles.content}>
            <Switch>
              <Route
                path="/:organization/organization"
                component={OrganizationPage}
              />
              <Route path="/:organization/api" component={ApiPage} />
              <Route
                path="/:organization/security"
                component={SettingsSecurity}
              />
              <Route
                path="/:organization/devices/:entry?"
                component={SettingsDevices}
              />
              <Route
                path="/:organization/stocks/:entry?"
                component={SettingsStock}
              />
              <Route
                path="/:organization/notifications/:entry?"
                component={SettingsNotifications}
              />
              <Route
                path="/:organization/messages/:entry?"
                component={SettingsMessages}
              />

              <Route
                path="/:organization/users/:entry?"
                component={SettingsUsers}
              />
              <Route
                path="/:organization/medications/:entry?"
                component={SettingsMedications}
              />
              <Route
                path="/:organization/docs/:entry?"
                component={SettingsHelp}
              />
              <Route
                path="/:organization/advanced"
                component={SettingsAdvanced}
              />
              <Route
                path="/:organization/calendar/:entry?/:page?/:action?"
                component={PatientPage}
              />

              <Route>
                <Redirect
                  from="/:organization/"
                  to={`/${organization}/calendar`}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
