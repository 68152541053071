import React, { useState } from "react";
import { Input, Tooltip } from "@un/react";
import styles from "./styles.module.scss";
import { HexColorPicker } from "react-colorful";
import { Trans } from "react-i18next";

export const presetColors = [
  //"#FF681F",
  //"#FAB71E",
  "#7FDBB6",
  "#1AD086",
  "#91D2FA",
  "#E6EBF3",
  "#D866C5",
  "#1B95E0",
  "#ABB8C1",
  "#E9244F",
  "#F58EA9",
  "#9832EB",
  "#515151",
  "#C184DE",
];

export default function ColorInput(props) {
  /*...props*/

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  /*handleChange = (color) => {
    this.setState({ color: color.rgb })
  };*/

  return (
    <Input {...props} formItemClassName={styles.dateInput}>
      {(props) => {
        return (
          <div className={styles.dateInputWrapper}>
            <Tooltip
              content={
                <div>
                  <HexColorPicker
                    className={styles.colorPicker}
                    color={props.value}
                    onChange={(e) => props.onChange(e)}
                  />
                  <div className={styles.swatches}>
                    {presetColors.map((presetColor) => (
                      <button
                        key={presetColor}
                        type="button"
                        style={{ background: presetColor }}
                        onClick={() => props.onChange(presetColor)}
                      />
                    ))}
                  </div>
                  {/*<HexColorInput
                    color={props.value}
                    onChange={(e) => props.onChange(e)}
                  />*/}
                </div>
              }
              trigger="click"
            >
              <div
                className={`${styles.colorSelect} ${
                  props.value ? styles.colorActive : ""
                }`}
              >
                <div
                  className={styles.colorView}
                  onClick={handleClick}
                  style={{ background: props.value }}
                ></div>
                <div className={styles.colorValue}>
                  {props.value ? props.value : <Trans>no color</Trans>}
                </div>
              </div>
            </Tooltip>
          </div>
        );
      }}
    </Input>
  );
}
