import React from "react";
import { TextInput } from "@un/react";
import UserSelect from "components/UserSelect";
import {
  faAlarmClock,
  faBatteryBolt,
  faBatteryExclamation,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { Trans } from "react-i18next";

export function userTitle(message) {
  return "Hallo";
}

export function batteryTitle(message) {
  return <Trans>Battery low</Trans>;
}

export function batteryDetails(message) {
  return <>Das Gerät {message.original?.meta?.name} muss aufgeladen werden</>;
}

export const messageKinds = {
  user: { icon: faUser, title: userTitle, details: ConditionsUser },
  delay: { icon: faAlarmClock, title: batteryTitle, details: ConditionsDelay },
  battery: {
    icon: faBatteryExclamation,
    title: batteryTitle,
    detail: batteryDetails,
    details: ConditionsBattery,
  },
};
export const messageKindSelect = (m) => {
  const message = messageKinds[m.kind];
  return { ...message, title: message.title(m), detail: message.detail(m) };
};

export const messageKindsList = Object.entries(messageKinds).map(
  ([name, e]) => {
    return { ...e, name };
  }
);

export function ConditionsUser({ register, index, item }) {
  return (
    <UserSelect
      defaultValue={item.value}
      register={register(`actions.${index}.value`)}
    />
  );
}

export function ConditionsDelay({ register, index, item }) {
  return (
    <TextInput
      labelText="E-Mail"
      defaultValue={item.value}
      type="email"
      {...register(`actions.${index}.value`)}
    />
  );
}

export function ConditionsBattery({ register, index, item }) {
  return (
    <TextInput
      labelText="Battery"
      defaultValue={item.value}
      {...register(`actions.${index}.value`)}
    />
  );
}

export default messageKinds;
