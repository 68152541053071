import React from "react";
import * as dates from "date-arithmetic";
import { Navigate } from "react-big-calendar";
import styles from "./trayOverview.module.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useMediaQuery } from "react-responsive";
import mediaQueries from "helpers/mediaQueries";
import useDayTimeList from "helpers/useDayTimeList";
import classNames from "classnames";
import TrayDay from "./TrayDay";
import CalendarDetails from "./CalendarDetails";
import { isSameDay } from "date-fns";
import { iconList } from "helpers/dayTimeList";
import TrayEntry from "./TrayEntry";
import { Trans } from "react-i18next";
import QueryString from "qs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TrayEntryFill from "./TrayEntryFill";

function TrayOverview(props) {
  const isDesktopOrLaptop = useMediaQuery(mediaQueries.mediaMaxXs);
  let {
    dot,
    className,
    currentMedication,
    date,
    events,
    eventsBakedReduced,
    iotDevicesQueryData,
    fill,
  } = props;

  const dayTimeList = useDayTimeList({ show: "memoOnly" });
  let range = TrayOverview.range(date);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const classes = classNames(
    {
      [styles.dot]: !isDesktopOrLaptop,
      [styles.trayTableFill]: fill,
    },

    styles.trayTable,
    className
  );

  const selectDay = (e) => {
    const locationSearch = {
      ...QueryString.parse(location.search, { ignoreQueryPrefix: true }),
      trayDate: e.toISOString(),
      timeCategory: undefined,
    };

    const to = `/${params.organization}/calendar/${
      params.entry
    }/?${QueryString.stringify(locationSearch)}`;

    history.push(to);
  };

  return (
    <>
      <div className={classes}>
        <div></div>
        {range.map((e, index) => {
          const isToday = moment().isSame(e, "day");

          const classes = classNames(
            {
              [styles.isToday]: isToday,
            },
            styles.dayTitle
          );

          return (
            <div className={classes} onClick={() => selectDay(e)}>
              <div className={styles.dayTitleText}>
                {isToday ? (
                  <Trans>Today</Trans>
                ) : isDesktopOrLaptop ? (
                  moment(e).format("dddd")
                ) : (
                  moment(e).format("dd")
                )}

                {/*notRelated.length > 0 && (
                <Tippy
                  interactive
                  content={
                    <div>
                      <TrayEntry
                        trayStyle={false}
                        events={notRelated}
                        date={e}
                      />
                    </div>
                  }
                  trigger="click"
                  {...tooltipStyle}
                >
                  <div className={`${styles.notRelated} newEntryButton`}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </div>
                </Tippy>
                )*/}
              </div>

              <div className={styles.dayTitleSub}>
                {isDesktopOrLaptop
                  ? moment(e).format("DD.MM.YYYY")
                  : moment(e).format("DD.MM")}
              </div>
            </div>
          );
        })}
        {Object.values(dayTimeList).map((f, i) => (
          <>
            <div className={styles.timeTitle}>
              <div className={styles.timeTitleIcon}>
                <FontAwesomeIcon icon={iconList[f.key]?.icon} />
              </div>
              <div className={styles.timeTitleSub}>
                <Trans>{f.name}</Trans>
              </div>
            </div>
            {range.map((e, index) => {
              const isToday = moment().isSame(e, "day");

              if (fill && currentMedication !== undefined) {
                return (
                  <TrayEntryFill
                    events={events.filter(
                      (event) =>
                        isSameDay(event.date, e) && event.timeCategory === f.key
                    )}
                    date={e}
                    dayTimeEntry={f}
                    time={f.time}
                  />
                );
              }

              return (
                <TrayEntry
                  dot={!isDesktopOrLaptop}
                  fill={fill}
                  //liveTray={message && message[`s${index * i}`]}
                  events={events.filter(
                    (event) =>
                      isSameDay(event.date, e) && event.timeCategory === f.key
                  )}
                  date={e}
                  dayTimeEntry={f}
                  dayTimeIndex={i}
                  time={f.time}
                  isToday={isToday}
                  iotDevicesQueryData={iotDevicesQueryData}
                  //timeIsBaked={timeIsBaked}
                />
              );
            })}
          </>
        ))}
      </div>
      {!fill && !isDesktopOrLaptop && (
        <CalendarDetails iotDevicesQueryData={iotDevicesQueryData} />
      )}
    </>
  );
}

TrayOverview.range = (date) => {
  let start = moment(date).startOf("isoWeek").toDate();
  let end = dates.add(start, 6, "day");

  let current = start;
  let range = [];

  while (dates.lte(current, end, "day")) {
    range.push(current);
    current = dates.add(current, 1, "day");
  }

  return range;
};

TrayOverview.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return dates.add(date, -1, "week");

    case Navigate.NEXT:
      return dates.add(date, 1, "week");

    default:
      return date;
  }
};

TrayOverview.title = (date) => {
  return `${moment(date).startOf("isoWeek").format("DD.MM.")} – ${moment(date)
    .endOf("isoWeek")
    .format("DD.MM.YY")}`;
};

export default TrayOverview;
