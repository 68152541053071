import moment from "moment";
import { BlockNotification } from "@un/react";
import React, { useEffect } from "react";
import MessageLog from "./Debug/MessageLog";
import PingDevice from "./Debug/PingDevice";
import TrayView from "./Debug/TrayView";
import DeviceStatus from "./Debug/DeviceStatus";
import SensorLog from "./Debug/SensorLog";

export default function DebugDevice({ id }) {
  return (
    <>
      <BlockNotification
        kind="warning"
        title="Debug section"
        subtitle="Use the debug section to enable new features and test the system."
      />
      <SensorLog id={id} />
      Show alarm shadows
      <PingDevice id={id} />
      <DeviceStatus id={id} />
      <TrayView id={id} />
      <MessageLog id={id} />
      {/*<RealtimeTray />*/}
      {/* Show all trays, click on Tray enables LED, getCaseStatus */}
    </>
  );
}
