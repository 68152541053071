import React from "react";
import styles from "./trayEntry.module.scss";
import MedicationEntryFill from "./MedicationEntryFill";
import useQs from "helpers/useQs";
import { compareMedications } from "@wirewire/helpers";

export default function TrayEntryFill({ date, dayTimeEntry, events, time }) {
  const args = useQs();
  const currentMedication = args.medication;

  const currentEvents = events.filter((e) =>
    compareMedications(e, currentMedication)
  );

  console.log("eveeenetssadds", events, currentEvents);

  return (
    <div className={`${styles.trayFill} ${styles.trayStyle}`}>
      <div className={styles.entryList}>
        {currentEvents.length !== 0 && (
          <MedicationEntryFill relatedEvents={currentEvents} />
        )}
      </div>
    </div>
  );
}
