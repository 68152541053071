import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const calendarsApi = generateCrudApi({
  name: "calendars",
  endpoints: (builder) => ({
    getCalendarById: builder.query({
      query: (request) => ({
        url: `calendars`,
        params: { patient: request.id },
        method: "get",
      }),
      invalidatesTags: [{ type: "calendars", id: "calendars" + "LIST" }],
      transformResponse: (response) => response.results,
    }),
    bakeCalendar: builder.mutation({
      query: (initialPost) => ({
        url: "calendars/bake",
        body: initialPost,
        method: "post",
        invalidatesTags: [
          { type: "calendars", id: "calendars" + "LIST" },
          { type: "calendars", id: "calendars" + "SEARCH" },
        ],
      }),
    }),
    unbakeCalendar: builder.mutation({
      query: (initialPost) => ({
        url: "calendars/bake",
        body: initialPost,
        method: "delete",
      }),
      invalidatesTags: [
        { type: "calendars", id: "calendars" + "LIST" },
        { type: "calendars", id: "calendars" + "SEARCH" },
      ],
    }),
  }),
});
