import medications from "ducks/medications";
import { medicationsApi } from "ducks/medicationsApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function useMedications(search) {
  const { organization } = useParams();
  const medicationsApiGetAll =
    medicationsApi.useGetAllMedicationsQuery(organization);
  return medicationsApiGetAll;
}
