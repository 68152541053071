import { SettingsSidebarNoSidebar } from "components/Settings/SettingsWithSidebar";
import React from "react";
import ReactJson from "react-json-view";
import { Story } from "@un/react";

/* eslint-disable import/no-webpack-loader-syntax */

//import Content from "!!babel-loader!@mdx-js/loader!./content.mdx";

export default function Support() {
  return (
    <SettingsSidebarNoSidebar title="Help & Informations" hideHeaderRight>
      <Story>
        <h2>wirewire GmbH</h2>
        <p>
          Version: {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
          <ReactJson src={process.env} />
        </p>
      </Story>
    </SettingsSidebarNoSidebar>
  );
}
