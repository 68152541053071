import React from "react";

import styles from "./styles.module.scss";

export default function MedicationIcon({ className, color, icon, image }) {
  if (!icon) return null;
  const Icon = icon.icon;
  return (
    <Icon
      style={{ color: color }}
      className={`${styles.medicationIcon} ${
        !color ? styles.medicationIconNoColor : ""
      }
        ${className ? className : ""}`}
    />
  );
}
