import { ReactComponent as circle } from "./pills/round.svg";
import { ReactComponent as long } from "./pills/long.svg";
import { ReactComponent as longSplit } from "./pills/long-split.svg";
import { ReactComponent as rhombusSplit } from "./pills/rhombus-split.svg";
import { ReactComponent as liniment } from "./pills/liniment.svg";

const pillList = {
  circle: { name: "circle", icon: circle },
  longd: { name: "long", icon: long },
  longSplit: { name: "long split", icon: longSplit },
  rhombusSplit: { name: "rhombus split", icon: rhombusSplit },
  linimentb: { name: "liniment", icon: liniment },
};

export default pillList;
