import React from "react";
import { devicesApi } from "ducks/devices";
import useSettingsOverview from "helpers/useSettingsOverviewNew";
import { Trans } from "react-i18next";
import styles from "./styles.module.scss";
import SettingsSidebar from "components/Settings/SettingsWithSidebar";
import { Item } from "@un/react";
import SettingsDevicesDetail from "./SettingsDevicesDetail";
import DeviceIcon from "components/DeviceIcon";
import Battery from "components/Battery";
import UserName from "components/UserName";
import DeviceIdFormatted from "./DeviceIdFormatted";
import { useParams } from "react-router-dom";
import SettingsDevicesNew from "./SettingsDevicesNew";
import useQs from "helpers/useQs";
import useCurrentUser from "helpers/useCurrentUser";

export default function SettingsDevices() {
  const settingsOverview = useSettingsOverview({
    name: "devices",
    api: devicesApi,
  });
  const { entry } = useParams();

  console.log("entrydsadsa", entry);
  const { analog } = useQs();

  return (
    <SettingsSidebar
      settingsOverview={settingsOverview}
      details={
        entry === "new" && !analog ? (
          <SettingsDevicesNew />
        ) : (
          <SettingsDevicesDetail />
        )
      }
      item={({ e, ...other }) => (
        <Item
          title={
            e.meta?.name ? (
              e.meta.name
            ) : (
              <>
                <Trans>Unnamed</Trans> <Trans>{e.kind}</Trans>
              </>
            )
          }
          hint={
            <div className={styles.battery}>
              <Battery device={e} level={e.deviceStatus?.batLevel} />
            </div>
          }
          {...other}
          subContent={
            <DeviceIdFormatted kind={e.kind}>{e.deviceId}</DeviceIdFormatted>
          }
          image={<DeviceIcon device={e.kind} />}
        >
          <UserName id={e.patient} />
        </Item>
      )}
    ></SettingsSidebar>
  );
}
