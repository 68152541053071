import {
  faSunrise,
  faSun,
  faSunset,
  faMoon,
  faPlusOctagon,
} from "@fortawesome/pro-light-svg-icons";

import { dayTimeListLookup } from "@wirewire/helpers";

export const iconList = {
  morning: {
    icon: faSunrise,
  },
  noon: {
    icon: faSun,
  },
  afternoon: {
    icon: faSunset,
  },
  night: {
    icon: faMoon,
  },
};

const dayTimeListWithIconsArray = Object.entries(dayTimeListLookup).map(
  ([i, e]) => {
    return [i, { ...e, icon: iconList[i].icon }];
  }
);

const dayTimeListWithIcons = Object.fromEntries(dayTimeListWithIconsArray);

export default dayTimeListWithIcons;
