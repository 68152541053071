import { generateCrudApi } from "helpers/crudGeneratorExtend";

export const usersApi = generateCrudApi({
  name: "users",
  endpoints: (builder) => ({
    getCalendarById: builder.query({
      query: (request) => ({
        url: `users/updatetimes/${request.id}`,
        method: "post",
        body: request.data,
      }),
    }),
    getCurrentUser: builder.query({
      query: (request) => ({
        url: `users/current`,
        method: "get",
        params: { organization: request },
      }),
      providesTags: (result, error, id) => [
        { type: "users", id: result?.id },
        { type: "organizations", id },
      ],
    }),
  }),
});
