import { useHistory, useLocation, useParams } from "react-router-dom";

import qs from "qs";
import capitalizeFirstLetter from "./capitalizeFirstLetter";

function useSettingsOverview({
  getEntryById,
  name,
  getStatus,
  titleKey = "name",
  api,
}) {
  const { organization, entry } = useParams();
  const history = useHistory();
  const organizationId = organization;

  const location = useLocation();
  const search = qs.parse(location.search, { ignoreQueryPrefix: true });

  /*const medicationSearch = useSelector((state) =>
    duck.selectors.byId(state, search.new ? search.new : search.updated)
  );*/

  const allQuery =
    api[`useGetAll${capitalizeFirstLetter(name)}Query`](organizationId);
  const { data = [] } = allQuery;

  const updateSearch = (value) => {
    history.push({ search: qs.stringify({ search: value }) });
  };

  const filteredDataArray = data;

  const url = `/${organizationId}/${name}/`;

  return {
    dataArray: data,
    filteredDataArray,
    organizationId,
    entry,
    data,
    allQuery,
    updateSearch,
    search: location.search,
    searchObject: search,
    name,
    url,
  };
}
export default useSettingsOverview;
