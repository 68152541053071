import React from "react";
import styles from "./styles.module.scss";
import { Switch, Route } from "react-router-dom";

import PhotoTray from "./PhotoTray";
import Fill from "./Fill";

export default function FillProcess() {
  //    <div className={styles.fillProcess}>
  return (
    <Switch>
      <Route
        path="/:organization/:entry?/fill/fillTray/:medication?"
        component={Fill}
      />
      <Route
        path="/:organization/:entry?/fill/photoTray"
        component={PhotoTray}
      />
    </Switch>
  );
}
