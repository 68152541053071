import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

const PwaShadow = () => {
  return <div className={styles.pwaShadow} />;
};

export default PwaShadow;
