import { organizationsApi } from "ducks/organizationsApi";
import { useParams } from "react-router-dom";

export function useActiveOrganzation() {
  const params = useParams();

  const { data } = organizationsApi.useGetSingleOrganizationsQuery(
    params.organization
  );

  return data;
}
