import React, { useEffect, useState } from "react";

export default function useTouch() {
  const [touch, setTouch] = useState(false);

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }
  useEffect(() => {
    setTouch(isTouchDevice);
  }, []);

  return touch;
}
