import React from "react";
import { Button, InlineLoading } from "@un/react";
import { Redirect, useLocation } from "react-router-dom";
import styles from "./login.module.scss";

import useAccount from "helpers/useAccount";
import Empty from "components/Empty";
import LoginWrapper, { LoginWrapperTitle } from "components/AuthWrapper";
import loginBackground from "./login-background.svg";
import { ReactComponent as WireWireLogo } from "./wirewire-logo.svg";
import { Trans } from "react-i18next";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

const Login = (props) => {
  const location = useLocation();

  /*const { isAuthenticated, isLoading, loginWithRedirect, logout, user } =
    useAuth0();*/

  const { isAuthenticated, isLoading, loginWithRedirect, logout, user } =
    useAccount();

  const { buildAuthorizeUrl } = useAccount();

  const login = async (args) => {
    if (Capacitor.isNativePlatform()) {
      const url = await buildAuthorizeUrl(args);
      await Browser.open({ url });
    } else {
      loginWithRedirect(args);
    }
  };

  if (!isLoading && isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  if (location.search.includes("code=")) {
    return (
      <Empty
        kind="large"
        icon={
          <div>
            <InlineLoading />
          </div>
        }
        title={<Trans>Processing login</Trans>}
      >
        <Trans>You will be redirected</Trans>
      </Empty>
    );
  }

  return (
    <LoginWrapper
      logo={<WireWireLogo className={styles.logo} />}
      rightSide={
        <div className={styles.loginContent}>
          <img alt="Login Background" src={loginBackground} />
        </div>
      }
    >
      <div className={styles.form}>
        <LoginWrapperTitle>
          <span>ANABOX</span>
          <br />
          <span>smart</span>
        </LoginWrapperTitle>
        <p className={styles.description}>
          Memo unterstützt Sie bei der pünktlichen Medikamenteneinnahme.
        </p>
        {!isLoading && !user ? (
          <div className={styles.buttonWrapper}>
            <Button
              id="signupButton"
              onClick={() =>
                login({
                  screen_hint: "signUp",
                  ui_locales: "de", //i18n.language.split("-")[0],
                })
              }
            >
              <Trans>Sign Up</Trans>
            </Button>
            <Button
              id="loginButton"
              kind="tertiary"
              onClick={() =>
                login({
                  ui_locales: "de", //i18n.language.split("-")[0],
                })
              }
            >
              <Trans>Login</Trans>
            </Button>
          </div>
        ) : (
          <InlineLoading />
        )}
        {!isLoading && user && (
          <>
            <div>
              <div>Already logged in as {user.name}</div>
              <Button
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Sign Out
              </Button>
            </div>
          </>
        )}
        {/*!window.IS_APP && (
          <a
            href="com.wirewire.memo://com.wirewire.memo"
            className={styles.appLink}
          >
            Open App now
          </a>
        )*/}
        {/*<a href="com.wirewire.memo://com.wirewire.memo/error">Open debug now</a>*/}
        {/*<div className={styles.localLoginWrapper}>
         
          <p className={styles.localLoginText}>
            Using Memo offline enables you to make all your changes on the
            device itself without uploading any data to the internet.
          </p>
        </div>*/}
      </div>
    </LoginWrapper>
  );
};

export default Login;
