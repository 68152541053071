import React from "react";
import SettingsTitle from "components/SettingsTitle";
import useIsDesktop from "helpers/useIsDesktop";
import { Trans, useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { InlineLoading } from "@un/react";
import DeleteModal from "components/DeleteModal";
import ReturnButton from "./ReturnButton";

export const SettingsContentHeader = (props) => {
  const {
    additionalHeader,
    customDelete,
    name,
    urlId,
    overviewUrl,
    params,
    hideTitle,
    title,
    narrow,
    hideHeaderRight,
    showReturnDesktop,
    singleQuery,
    showMobile,
  } = props;
  const isDesktop = useIsDesktop();

  const { t } = useTranslation();

  return (
    <>
      {(showMobile || isDesktop) && (
        <>
          {!narrow && overviewUrl && showReturnDesktop && (
            <ReturnButton overviewUrl={overviewUrl} />
          )}
          <div className={styles.buttonWrapper}>
            {narrow && overviewUrl && showReturnDesktop && (
              <ReturnButton overviewUrl={overviewUrl} narrow />
            )}
            {!hideTitle && (
              <SettingsTitle narrow={narrow}>
                {title ? (
                  title
                ) : (
                  <>
                    {params?.entry === "new" || params?.entry === "first" ? (
                      <Trans i18nKey="newName" values={{ name: t(name) }} />
                    ) : (
                      <Trans i18nKey="detailsName" values={{ name: t(name) }} />
                    )}
                  </>
                )}
              </SettingsTitle>
            )}
            {(singleQuery?.isLoading || singleQuery?.isRejected) && (
              <InlineLoading />
            )}

            {!hideHeaderRight && (
              <div className={styles.headerRight}>
                {additionalHeader}
                {customDelete ? (
                  customDelete
                ) : urlId !== "new" ? (
                  <div className={styles.deleteTitle}>
                    <DeleteModal {...props} />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SettingsContentHeader;
