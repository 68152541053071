import React from "react";
import { ReactComponent as circle } from "./pills/round.svg";
import { ReactComponent as long } from "./pills/long.svg";
import { ReactComponent as longSplit } from "./pills/long-split.svg";
import { ReactComponent as rhombusSplit } from "./pills/rhombus-split.svg";
import { ReactComponent as liniment } from "./pills/liniment.svg";
import eventTraysToDate from "./trays";
import moment from "moment";
import { DayTime } from "./useDayTimeList";

const eventList = {
  CaseTrigger: {
    name: "Case triggered",
    icon: circle,
    component: ({ event }) => {
      const tray = eventTraysToDate(event.CaseNumber);
      return (
        <>
          {moment(tray.weekday).format("dddd")}{" "}
          <DayTime timeNumber={tray.timeNumber} />{" "}
          {event.CaseStatus === 1 ? "opened" : "closed"}
        </>
      );
    },
  },
  shadowdata: {
    name: "Device status updated",
    icon: long,
    component: ({ event }) => (
      <>
        {/*Object.entries(JSON.parse(event.EventMessage)).map(([e, i]) => (
          <div>
            {e}: {i}
          </div>
        ))*/}
      </>
    ),
  },
  alarm: {
    name: "Alarm Status",
    icon: longSplit,
    component: ({ event }) => <>{JSON.stringify(event.EventMessage)}</>,
  },
  env: {
    name: "Environment Sensor",
    icon: longSplit,
    component: ({ event }) => <>{JSON.stringify(event.EventMessage)}</>,
  },
  settings: {
    name: "Settings updated",
    icon: longSplit,
    component: ({ event }) => (
      <>
        {`Settings update to version: ${event.EventMessage.v}, alarm version:  ${event.EventMessage.va}`}
      </>
    ),
  },
  init: {
    name: "Init",
    icon: rhombusSplit,
    component: ({ event }) => <>Device initialized</>,
  },
  tray: {
    name: "Tray changed",
    icon: liniment,
    component: ({ event }) => (
      <>
        {event.EventMessage === 0
          ? "Tray outside"
          : event.EventMessage === 1
          ? "Tray inside with out UUID"
          : `Tray inside with UUID ${event.EventMessage}`}
      </>
    ),
  },
};

export default eventList;

export function EventListContent({ event }) {
  if (!eventList[event.EventType] || !eventList[event.EventType].component)
    return <div>EventType not found ({event.EventType})</div>;
  const Component = eventList[event.EventType].component;
  return <Component event={event} />;
}
