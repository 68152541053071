import React from "react";
import useSettingsOverview from "helpers/useSettingsOverviewNew";
import SettingsSidebar from "components/Settings/SettingsWithSidebar";
import { BlockNotification, Item } from "@un/react";
import SettingsMedicationsDetail from "./SettingsMedicationsDetail";
import MedicationIcon from "components/MedicationIcon";
import pillList from "helpers/pillList";
import { Trans } from "react-i18next";
import { medicationsApi } from "ducks/medicationsApi";

import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

export default function SettingsMedications() {
  const settingsOverview = useSettingsOverview({
    name: "medications",
    titleKey: "lastName",
    api: medicationsApi,
  });

  const { organization } = useParams();
  return (
    <SettingsSidebar
      settingsOverview={settingsOverview}
      details={<SettingsMedicationsDetail />}
      backLink={`/${organization}/advanced`}
      intro={
        <BlockNotification
          className={styles.medicationInfo}
          title={<Trans>Create your own medications</Trans>}
          kind="info"
          subtitle={
            <Trans i18nKey="ABDAEXPLAIN">
              The app is using the ABDA database. On this page you can create
              additional medications.
            </Trans>
          }
        />
      }
      item={({ e, ...other }) => (
        <Item
          title={e.meta?.name}
          {...other}
          subContent={<>{e.meta?.manufacturer}</>}
          image={
            <MedicationIcon
              icon={pillList[e.meta.dosageForm]}
              color={e.meta?.color}
            />
          }
        >
          <>{e.meta?.name}</>
        </Item>
      )}
    ></SettingsSidebar>
  );
}
