import React from "react";
import { useParams } from "react-router-dom";
import { withDates } from "@wirewire/helpers";
import useCurrentUser from "./useCurrentUser";
import useCurrentOrganization from "./organizations/useCurrentOrganization";
import { useActiveUser } from "./useUsers";

export default function useDayTimeList({ show } = {}) {
  const user = useActiveUser();
  const organization = useCurrentOrganization();

  return withDates({ user, organization, show });
}

export function DayTime({ timeNumber }) {
  const dayTimes = useDayTimeList();
  return (
    <>
      {dayTimes[timeNumber]?.name} ({dayTimes[timeNumber]?.time})
    </>
  );
}
