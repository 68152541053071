import React from "react";
import { deviceByKind } from "helpers/deviceList";

export default function DeviceIcon({ device = "unknown", ...other }) {
  const deviceKind = deviceByKind(device);
  return (
    <div {...other}>
      <img src={deviceKind?.image} />
      {/*<FontAwesomeIcon
        icon={devices[device]}
        className={styles.deviceIconImage}
      />*/}
    </div>
  );
}
