import { createSlice } from "@reduxjs/toolkit";

const initialState = { localLogin: false, active: false, dates: {} };

const auth = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => action.payload,
    logout: (state, action) => initialState,
    localLogin: (state, action) => {
      return { localLogin: true };
    },
    updateStatus: (state, action) => {
      return { ...state, status: action.payload };
    },
    updateFilterDates: (state, action) => {
      return { ...state, dates: action.payload };
    },
    setTokenSync: (state, action) => {
      return { ...state, token: action.payload };
    },
    updateFilterStatus: (state, action) => {
      return { ...state, active: action.payload };
    },
  },
});

auth.selectors = {};
auth.selectors.authState = (state) => state.auth.status;
export const getAuthToken = (state) => state.auth.token;

export default auth;
