import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  TextInput,
  InputGroup,
  InlineLoading,
} from "@un/react";
import MultiCheckbox from "components/MultiCheckbox";
import { accountsApi } from "ducks/accounts";
import { Trans, useTranslation } from "react-i18next";
import useQs from "helpers/useQs";
import useSettingsForm from "helpers/useSettingsFormNew";
import { SettingsGlobal } from "components/Settings/SettingsWithSidebar";
import styles from "./styles.module.scss";
import { useHistory } from "react-router";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "contexts/auth0-context";
import { useSelector } from "react-redux";
import account from "./account.svg";
import LoginWrapper from "components/AuthWrapper";
import { faSignOut } from "@fortawesome/pro-solid-svg-icons";
import FormRow from "components/FormRow";
import { Col } from "react-flexbox-grid";
import ReactJson from "react-json-view";
import GenderPicker from "components/GenderPicker";
import { notificationsApi } from "ducks/notificationsApi";
import Status from "components/Status";

export default function AccountPage() {
  const history = useHistory();
  const { updateMetadata, user, logout } = useAuth0();

  const store = useSettingsForm({
    name: "accounts",
    api: accountsApi,
    id: user.sub,
    preventReset: true,
    prepareFormEntry: (values) => {
      return {
        ...values?.app_metadata,
        family_name:
          values?.app_metadata?.last_name ||
          values?.family_name ||
          values?.last_name,
        given_name:
          values?.app_metadata?.first_name ||
          values?.given_name ||
          values?.first_name,
        email: values?.email,
      };
    },
    idElement: (entry) => entry?.user_id,
    prepareSubmit: (values, entryData) => {
      return {
        gender: values.gender,
        language: values.language,
        family_name: values.family_name,
        given_name: values.given_name,
        debug: values.debug,
        id: entryData.user_id,
      };
    },
    customDeleteRedirect: () => {
      history.push("/?show=always&action=userDeleted");
    },
    customOverviewUrl: `/`,
  });

  const {
    singleQuery,
    form: {
      register,
      watch,
      formState: { errors },
    },
  } = store;

  useEffect(() => {
    updateMetadata();
  }, [singleQuery.data?.app_metadata?.language]);

  const provider = singleQuery.data?.identities?.[0]?.provider;

  console.log("eeroror", history);
  const { organization } = useQs();
  const debug = watch("debug");

  console.log("notificationsApi", notificationsApi);
  const [sendDefaultNotification, sendDefaultNotificationResult] =
    notificationsApi.useSendDefaultNotificationMutation();

  return (
    <LoginWrapper
      showBackLink
      backLink={organization ? `${organization}/advanced` : "/"}
      backLinkText={<Trans>{organization ? `Back` : "Overview"}</Trans>}
      backLinkIconReverse={true}
      rightSide={
        <div className={styles.account}>
          <img
            alt="Account illustration"
            className={styles.accountIllustration}
            src={account}
          />
        </div>
      }
    >
      {/*<ReactJson src={errors} />*/}

      <SettingsGlobal
        {...store}
        title={<Trans>Account Settings</Trans>}
        customDelete={
          <div>
            <Button
              kind="tertiary"
              icon={<FontAwesomeIcon icon={faSignOut} />}
              onClick={() => logout()}
            >
              <Trans>Logout</Trans>
            </Button>
          </div>
        }
      >
        <>
          <FormRow>
            <Col xs={12} md={6}>
              <TextInput
                labelText={<Trans>First name</Trans>}
                {...register("given_name", {
                  required: "Please enter a name",
                })}
              />
            </Col>
            <Col xs={12} md={6}>
              <TextInput
                labelText={<Trans>Last name</Trans>}
                {...register("family_name", {
                  required: "Please enter a name",
                })}
              />
            </Col>
          </FormRow>
          {provider === "apple" ||
            (provider === "google" ? (
              <></>
            ) : (
              <TextInput
                formItemClassName={styles.name}
                labelText={<Trans>E-Mail</Trans>}
                {...register("email")}
              />
            ))}

          <GenderPicker
            register={register}
            options={{
              required: "Please enter a name",
            }}
          />

          <InputGroup
            labelText={<Trans>Language (Sprache)</Trans>}
            /*helperText={
              <Trans>Select the language you want to use memo.</Trans>
            }*/
          >
            <MultiCheckbox
              labelText={<Trans>German</Trans>}
              value="de"
              type="radio"
              {...register("language", {
                required: "Please enter a language",
              })}
            />
            <MultiCheckbox
              labelText={<Trans>English</Trans>}
              value="en"
              type="radio"
              {...register("language", {
                required: "Please enter a language",
              })}
            />
          </InputGroup>

          <Checkbox
            labelText={<Trans>Enable debug and developer settings</Trans>}
            type="checkbox"
            id="debug"
            placeholder="debug"
            {...register("debug", {})}
          />
          {debug && (
            <div className={styles.developer}>
              <h2>
                <Trans>Developer Settings</Trans>
              </h2>
              <InputGroup
                labelText={<Trans>API version</Trans>}
                helperText={<Trans>Override the API endpoint</Trans>}
              >
                <MultiCheckbox
                  labelText={<Trans>Standard</Trans>}
                  value="default"
                  type="radio"
                  {...register("env")}
                />
                <MultiCheckbox
                  labelText={<Trans>Development</Trans>}
                  value="dev"
                  type="radio"
                  {...register("env")}
                />
                <MultiCheckbox
                  labelText={<Trans>Production</Trans>}
                  value="prod"
                  type="radio"
                  {...register("env")}
                />
              </InputGroup>
              <Button onClick={sendDefaultNotification}>
                <Trans>Send push notification</Trans>
              </Button>
              <Status
                fetching={
                  <div className={styles.loading}>
                    <Trans>Loading...</Trans>
                  </div>
                }
                query={sendDefaultNotificationResult}
                showContent
              ></Status>
              <br />
              <h4>Version: {process.env.REACT_APP_NAME}</h4>
              {process.env.REACT_APP_VERSION}
              <ReactJson src={process.env} />
            </div>
          )}

          <SettingsSubmitButton
            {...store}
            title={<Trans>Update Account</Trans>}
          />
        </>
      </SettingsGlobal>
    </LoginWrapper>
  );
}
