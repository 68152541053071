import React from "react";

import { TextInput, Select, SelectItem } from "@un/react";
import useSettingsForm from "helpers/useSettingsFormNew";
import SettingsContentWrapper from "components/SettingsContent/SettingsContentWrapper";
import { Trans } from "react-i18next";

import ScanButton from "components/Scanner/ScanButton";
import DateInput from "components/DateInput";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import users from "ducks/users";
import { Grid, Row, Col } from "react-flexbox-grid";
import FormRow from "components/FormRow";
import medications from "ducks/medications";
import { stocksApi } from "ducks/stocksApi";
import SettingsSubmitButton from "components/SettingsContent/SettingsSubmitButton";

export default function SettingsPatientsDetail() {
  const params = useParams();
  const store = useSettingsForm({
    name: "stocks",

    api: stocksApi,
    url: `/${params.organization}/stocks`,
  });

  const {
    form: { setValue, register },
  } = store;

  const updateScan = (content) => {
    Object.keys(content).forEach((key) => {
      setValue(key, content[key]);
    });

    return true;
  };

  return (
    <SettingsContentWrapper {...store}>
      <ScanButton
        onSubmit={updateScan}
        buttonText={<Trans>Scan from code</Trans>}
      />

      {/*<Select labelText="Patient" {...register("patient")}>
        {patientsArray.map((e) => (
          <SelectItem
            text={`${e.meta?.firstName} ${e.meta?.lastName}`}
            value={e.id}
          />
        ))}
      </Select>

      <Select labelText="Medication" {...register("medication")}>
        {medicationsArray.map((e) => (
          <SelectItem
            text={`${e.meta?.name} ${e.meta?.manufacturer}`}
            value={e.id}
          />
        ))}
        </Select>*/}

      <FormRow>
        <Col xs={12} md={6}>
          <TextInput
            labelText="Product code"
            {...register("meta.productCode")}
          />
        </Col>
        <Col xs={12} md={6}>
          <TextInput
            labelText="Serial number"
            {...register("meta.serialNumber")}
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col xs={12} md={6}>
          <TextInput
            labelText="Batch number"
            {...register("meta.batchNumber")}
          />
        </Col>
        <Col xs={12} md={6}>
          <DateInput
            name="expDate"
            labelText="Expiration date"
            {...register("meta.expDate")}
          />
        </Col>
      </FormRow>

      <SettingsSubmitButton {...store} />
    </SettingsContentWrapper>
  );
}
