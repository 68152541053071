import React from "react";
import { Button, NotificationActionButton } from "@un/react";
import { useParams, useHistory } from "react-router-dom";
import classnames from "classnames";
export default function ButtonRouter({
  to,
  isPlain,
  className,
  isLink,
  isHtmlLink,
  withOrganization,
  notification,
  ...other
}) {
  const history = useHistory();
  const { organization } = useParams();
  const toCalc = withOrganization ? `/${organization}${to}` : to;

  const ButtonComponent = notification ? NotificationActionButton : Button;

  if (isHtmlLink) {
    const linkClasses = classnames("wfp--link", className);

    return <a type="link" className={linkClasses} href={toCalc} {...other} />;
  }

  if (isPlain) {
    const linkClasses = classnames("wfp--link", className);

    return (
      <a
        type="link"
        className={linkClasses}
        href={toCalc}
        onTouchStart={(e) => {
          e.preventDefault();
          history.push(toCalc);
        }}
        onClick={(e) => {
          e.preventDefault();
          history.push(toCalc);
        }}
        {...other}
      />
    );
  }
  if (isLink) {
    return (
      <ButtonComponent
        type="link"
        href={toCalc}
        /*onTouchStart={(e) => {
          e.preventDefault();
          history.push(toCalc);
        }}*/
        onClick={(e) => {
          e.preventDefault();
          history.push(toCalc);
        }}
        className={className}
        {...other}
      />
    );
  }
  return (
    <ButtonComponent
      type="button"
      onClick={() => history.push(toCalc)}
      className={className}
      {...other}
    />
  );
}
