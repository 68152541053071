import React from "react";
import Select, { components } from "react-select";
import styles from "./styles.module.scss";

import { useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { faUsersCog } from "@fortawesome/pro-solid-svg-icons";
import Avatar from "components/Avatar";
import {
  useActiveUser,
  useUsersWithCurrentOrganization,
} from "helpers/useUsers";
import { UserNameNew } from "components/UserName";
import useQs from "helpers/useQs";
import qs from "qs";
import classnames from "classnames";

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className={styles.valueWrapper}>
        <div className={styles.avatar}>
          <Avatar user={props.data?.data} />
        </div>
        <div className={styles.valueContent}>
          <h2 className={styles.title}>
            <UserNameNew user={props.data?.data} />
          </h2>
          <p className={styles.subTitle}>
            {moment(props.data?.data?.meta?.birthdate).format("DD.MM.YYYY")}
          </p>
        </div>
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  const { data } = props;
  if (data.value === "all") {
    return (
      <components.Option {...props}>
        <h2 className={styles.title}>Patient overview</h2>
      </components.Option>
    );
  }
  if (data.value === "manage") {
    return (
      <components.Option {...props}>
        <h2 className={styles.title}>
          <FontAwesomeIcon icon={faUsersCog} /> Manage patients
        </h2>
      </components.Option>
    );
  }

  return (
    <components.Option {...props}>
      <div
        className={
          props.isSelected
            ? `${styles.optionWrapper} ${styles.optionWrapperSelected}`
            : styles.optionWrapper
        }
      >
        <div>
          <h2 className={styles.title}>
            <UserNameNew user={data?.data} />
          </h2>
          <p className={styles.subTitle}>
            {moment(data.data?.meta?.birthdate).format("DD.MM.YYYY")}
          </p>
        </div>
      </div>
    </components.Option>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    //height: 68,
    //minHeight: 68,
  }),
};

export default function SelectCase() {
  const { organization } = useParams();
  const usersArray = useUsersWithCurrentOrganization({ category: "patient" });
  const currentPatient = useActiveUser();
  const history = useHistory();

  const options = Object.values(usersArray).map((e) => {
    return {
      data: e,
      value: e.id,
      label: `${e.meta?.firstName} ${e.meta?.lastName}`,
    };
  });

  const formatedcurrentPatient = currentPatient
    ? {
        data: currentPatient,
        value: currentPatient.id,
        label: `${currentPatient.meta?.firstName} ${currentPatient.meta?.lastName}`,
      }
    : undefined;

  const { date, trayDate } = useQs();
  const trayDateSelected = trayDate || new Date().toISOString();

  const queryString = qs.stringify({ trayDate: trayDateSelected, date });

  //options.push({ value: "manage", label: "Manage users" });

  const classes = classnames(styles.select, {
    [styles.oneOption]: options.length <= 1,
  });

  return (
    <Select
      options={options}
      className={classes}
      classNamePrefix="react-select"
      maxMenuHeight={490}
      styles={customStyles}
      value={formatedcurrentPatient}
      /* menuIsOpen={true} */
      isDisabled={options.length <= 1 ? true : false}
      components={{ SingleValue, Option }}
      onChange={(e) => {
        if (e.value === "manage") {
          history.push(`/${organization}/users`);
        } else {
          history.push(`/${organization}/calendar/${e.value}?${queryString}`);
        }
      }}
    />
  );
}
