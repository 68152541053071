import { devicesApi } from "ducks/devices";
import React from "react";
import ReactJson from "react-json-view";
import { Item } from "@un/react";
import { Trans } from "react-i18next";
import Battery from "components/Battery";
import styles from "./styles.module.scss";
import DeviceIcon from "components/DeviceIcon";
import DeviceIdFormatted from "components/SettingsDevices/DeviceIdFormatted";
import UserName from "components/UserName";
import { NavLink } from "react-router-dom";

export default function DevicesList() {
  const getAllDevicesQuery = devicesApi.useGetAllDevicesAdminQuery();

  const { data } = getAllDevicesQuery;

  if (!data) return <>Loading</>;
  return (
    <div>
      {data.length} devices
      {data.map((e) => (
        <Item
          title={
            e.meta?.name ? (
              e.meta.name
            ) : (
              <>
                <Trans>Unnamed</Trans> <Trans>{e.kind}</Trans>
              </>
            )
          }
          kind="horizontal"
          wrapper="repeater"
          hint={
            <div className={styles.battery}>
              <Battery device={e} level={e.deviceStatus?.batLevel} />
            </div>
          }
          subContent={
            <DeviceIdFormatted kind={e.kind}>{e.deviceId}</DeviceIdFormatted>
          }
          image={<DeviceIcon device={e.kind} />}
        >
          <UserName id={e.patient} />{" "}
          <NavLink to={`${e.organization}/devices/${e.id}`}>Visit</NavLink>
          <ReactJson src={e} collapsed />
        </Item>
      ))}
      <ReactJson src={getAllDevicesQuery} collapsed />
    </div>
  );
}
