import React, { useEffect, useRef } from "react";
import Sidebar from "../Sidebar";
import Wrapper from "../Wrapper";
import { useParams, useHistory, Route, Switch } from "react-router-dom";
import CalendarPage from "components/Calendar";
import PatientsEmpty from "components/PatientsEmpty";
import CalendarForm from "components/CalendarForm";
import useUsers, {
  useActiveUser,
  useLatestUser,
  useUsersRequest,
} from "helpers/useUsers";
import { useDispatch, useSelector } from "react-redux";
import globalState from "ducks/globalState";
import { Trans } from "react-i18next";
import InlineLoadingLarge from "components/InlineLoadingLarge";

export default function PatientPage() {
  const params = useParams();
  const componentRef = useRef();
  const activeUser = useActiveUser();
  const dispatch = useDispatch();

  const { isLoading } = useUsersRequest();

  const getLatestPatient = useLatestUser({ category: "patient" });
  const getPatients = useUsers({ category: "patient" });

  const latestUserId = useSelector((state) => state.globalState.lastUser);

  const history = useHistory();

  useEffect(() => {
    document.body.className = "fullHeight";
    return () => {
      document.body.className = "";
    };
  }, []);

  // Jump to latest patient
  useEffect(() => {
    const now = new Date().toISOString();
    console.log(
      "PPUUWHHS",
      latestUserId,
      getPatients,
      // getPatients.find((e) => e.id === activeUser && e.category === "patient"),
      activeUser
    );
    if (
      latestUserId &&
      getPatients &&
      activeUser === undefined &&
      getPatients.find((e) => e.id === latestUserId && e.category === "patient")
    ) {
      console.log("PPUUWHHddddS");
      history.push(
        `/${params.organization}/calendar/${latestUserId}?trayDate=${now}`
      );
    } else if (
      getLatestPatient &&
      (activeUser === undefined ||
        (getPatients &&
          getPatients.find(
            (e) => e.id === activeUser?.id && e.category === "patient"
          )) === undefined)
    ) {
      console.log("PPUUWHHddddSaaaa", getPatients, activeUser);
      history.push(
        `/${params.organization}/calendar/${getLatestPatient.id}?trayDate=${now}`
      );
    }
  }, [activeUser, getLatestPatient, latestUserId, history]);

  useEffect(() => {
    if (params.entry) dispatch(globalState.actions.setLastUser(params.entry));
  }, [params.entry]);

  if (activeUser === undefined && (isLoading || getLatestPatient)) {
    return (
      <InlineLoadingLarge description={<Trans>Calendar is loading...</Trans>} />
    );
  } else if (activeUser === undefined) {
    return <PatientsEmpty />;
  }

  return (
    <Wrapper
      sidebar={
        <Route path="/:organization/calendar/:entry?" component={Sidebar} />
      }
    >
      <Switch>
        <Route
          path="/:organization/calendar/:entry/new/:date"
          component={CalendarForm}
        />
        <Route
          path="/:organization/calendar/:entry/edit/:calendar/:calendarEntry?"
          component={CalendarForm}
        />
      </Switch>
      {activeUser && <CalendarPage innerRef={componentRef} />}
    </Wrapper>
  );
}
