import React from "react";
import { messageKinds } from "components/SettingsMessages/messageList";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import classnames from "classnames";

export default function NotificationIcon({ condition, status, ...other }) {
  const deviceKind = messageKinds[condition];

  const classes = classnames(styles.deviceIcon, {
    [styles[status]]: true,
  });

  return (
    <div {...other} className={classes}>
      {status && <FontAwesomeIcon icon={faCircle} className={styles.status} />}
      <FontAwesomeIcon
        icon={deviceKind?.icon}
        className={styles.deviceIconImage}
      />
    </div>
  );
}
